export const ROOT_URL = '/'

export const HOME = '/home'
export const OFFERS = '/offers'
export const PURCHASE_REPORTS = '/purchase-reports'


// auth
export const LOGIN = '/login'

export const DELETE_INFLUIO_APP_USER = '/delete/app/user'

export const APP_USER_LOGIN = '/app/user/login'
export const SIGN_UP = '/signUp'
export const LOGIN_USER = '/user/login';
export const GET_SESSION = '/user/get/session';
export const LOGOUT = '/user/logout';

//advertisers
export const GET_ALL_ADVERTISERS = "/partners/get";

// offers
export const GET_ALL_OFFERS = "/offers/get";


//signup
export const CREATE_NEW_USER = '/user/create';

//product performance
export const SAVE_NEW_EXCEL = '/excel/save/';
export const GET_ALL_CONVERSIONS = "/conversions/get";

export const DOWNLOAD_OFFER_PRODUCTS_EXCEL_DATA = '/conversions/download/excel';
export const GET_EXCEL_DATA = "/excel/get";


// affiliate performance
export const AFFILIATE_PERFORMANCE = '/affiliate-performance'
export const USERS_ADMIN = '/users-admin'
export const GET_ALL_AFFILIATES = '/affiliates/get'
export const GET_AFFILIATE_PERFORMANCE = '/affiliate/performance/get'
export const LCW_NEW_PARTNERS = '/lcw/partners'

//has-offer-users
export const GET_ALL_HAS_OFFER_USERS = '/has-offer-users/get/all'
export const GET_SELECTED_HAS_OFFER_USER = '/has-offer-users/get/selected'
export const UPDATE_SELECTED_HAS_OFFER_USER_ADDITIONAL_DATA = '/has-offer-users/additional_data/update'
export const ADD_NEW_LCW_USER = "/lcw/add/new";

export const DELETE_APP_USER = "/user/delete/account";

export const LOGIN_APP_USER = "/login/app/user";

export const ADD_DASHBOARD_USER = "/dashboard/has-offer-users/add/new";
export const UPDATE_SELECTED_HAS_OFFER_USER_DATA = '/has-offer-users/settings/update'

export const MYSQL_USER_SETTINGS_UPDATE = '/users/update/general'
export const GET_SELECTED_HAS_OFFER_USER_BANK_DETAILS = '/has-offer-users/bank-details/selected'
export const UPDATE_SELECTED_HAS_OFFER_USER_BANK_DATA = '/has-offer-users/bank-details/update'
export const DOWNLOAD_HAS_OFFERS_USERS_CSV = '/has-offer-users/download/csv';
export const DOWNLOAD_LCW_USERS_CSV = '/lcw-users/download/csv';

export const DOWNLOAD_PAID_PROJECT_INFLUENCERS = '/paid-projects/download/influencers';

//forms
export const USER_SETTINGS_UPDATE = '/forms/user-settings-update'
export const USER_BANK_SETTINGS_UPDATE = '/forms/user-bank-settings-update'
export const LCW_USER_NEW_REGISTRATION = '/forms/lcw-new-partner'
export const LCW_LANDING_PAGE_URL = '/lcw/lcwbiziminfluencerimizsensinsss'
export const ABOUT_INFLUIO = '/influiohakkinda'


//lcw users
export const GET_ALL_LCW_USERS = '/lcw/get/all'
export const UPDATE_LCW_USER_REVIEW_STATE = '/lcw/update/review-state'

//developer integration helper
export const INTEGRATIONS_HELPER = '/integrations-helper'


//advertiser management
export const ADVERTISER_MANAGEMENT = '/advertiser-management'
export const CREATE_CAMPAIGN = '/create-campaign'
export const INSERT_NEW_CAMPAIGN = "/campaigns/insert"
export const CREATE_PROMO_CODE = '/create-promo-code'

export const CREATE_PAID_PROJECT = '/create-paid-project'
export const UPLOAD_CAMPAIGN_IMAGE = '/campaigns/upload/image'
export const STATUS_CENTER = '/status-center'
export const INSERT_NEW_PROMO_CODE = "/promo-codes/insert"
export const GET_ALL_CATEGORIES = "/categories/get";
export const GET_ADVERTISER_PENDING_ASSETS = "/advertiser/pending-assets/get";

export const INSERT_NEW_PAID_PROJECTS = "/paid-projects/insertPaidProject";

export const INSERT_NEW_INFLUENCER_RELATION = "/paid-projects/insertInfluencerRelation";

export const UPDATE_PAID_PROJECT = "/paid-projects/updatePaidProject";
export const DELETE_PAID_PROJECT = "/paid-projects/delete";
export const UPDATE_PAID_PROJECT_INFLUENCER = "/paid-projects/update/influencer";

export const APPROVE_ALL_OR_REJECT_ALL_INFLUENCERS = "/paid-projects/approve/reject/all";


//integration helper
export const GET_TRACKER_POSTBACKS = "/adjust/get/postbacks";

export const ADMIN_PAID_PROJECTS = '/admin-paid-projects';

export const USER_PAYMENTS = '/user/payments';

export const EDIT_PROJECT = '/edit';

export const AI_CHAT_ASSISTANT = '/ai-chat-assistant';

export const INFLUENCER_CHATS = '/influencer-chats';

export const INSERT_INFLUENCERS = '/influencers/insert';

export const ASSIGN_INFLUENCERS = '/assign-influencers';

export const GET_ALL_PROJECTS = '/projects/get/all';

export const GET_PAID_PROJECT_INFLUENCERS = '/paid-projects/get/influencers';


//payments service

export const GET_USER_PAYMENTS = '/payments/get/user';

export const GET_ALL_PAYMENTS = '/payments/get/all';

export const UPDATE_USER_PAYMENT = '/payment/update/user';

export const DELETE_USER_PAYMENT = '/payment/delete/user';

export const GET_IMAGE_URL = '/payments/user/get-invoice-signal-url';

export const SEND_FIRST_EXCEL_NEW_RECORDS = '/excel/send/first';

export const SEND_SECOND_EXCEL_UPDATE_RECORDS = '/excel/send/second';

export const DOWNLOAD_ALL_PAYMENTS_EXCEL_DATA = '/payments/download/all/excel';


//radar ai service

export const GET_ALL_POTENTIAL_INFLUENCERS = '/ai-assistant/get/all';

export const GET_ALL_POTENTIAL_INFLUENCERS_DATA_WITH_CHATS = '/ai-assistant/get/all-with-chats';

export const INSERT_POTENTIAL_INFLUENCERS = '/ai-assistant/insert';

export const DELETE_POTENTIAL_INFLUENCER = '/ai-assistant/delete';

export const START_CRAWLING_CHAT_IDS = '/influencer-chats/start-crawling-chat-ids'
export const GET_CHATS_AND_INFLUENCER_DATA = '/influencer-chats/get-influencers';



import React from 'react';
import styles from "../ProductResults/ProductList.module.scss";
import GenericTable from "../../../common/table/GenericTable";
import {formatTurkishLira} from "../../../common/utils";
import {t} from "i18next";

const AffiliateResults = ({affiliates}) => {

    const affiliateConversionsOutputColumns = {};
    affiliateConversionsOutputColumns.visibility = {}
    affiliateConversionsOutputColumns.data = [
        {
            accessorKey: 'ad_id',
            header: t('common.ad-id'),
            enableClickToCopy: true
        },
        {
            accessorKey: 'device_brand',
            header: t('common.device-brand'),
            enableClickToCopy: true,
        },
        {
            accessorKey: 'date',
            header: t('common.date'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{new Date(cell.row.original.date.value).toDateString()}-{new Date(cell.row.original.date.value).toLocaleTimeString()}</div>
            )
        },
        {
            accessorKey: 'net_sale_amount',
            header: t('common.sale-amount'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.net_sale_amount)}</div>
            )
        },
        {
            accessorKey: 'adv_sub5',
            header: t('affiliates-performances.postback-msg'),
            enableClickToCopy: true
        }
    ]

    const affiliateColumns = {};
    affiliateColumns.visibility = {}
    affiliateColumns.data = [
        {
            accessorKey: 'id',
            header: "Affiliate Id",
        },
        {
            accessorKey: 'name',
            header: "Affiliate Name",
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'totalAmount',
            header: "Total amount",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.totalAmount)}</div>
            )
        },
    ];

    const expandAffiliate = ({row}) => (
        <GenericTable
            outputColumns={affiliateConversionsOutputColumns}
            tableData={row.original.conversions}
        />
    )

    return (
        <div className={styles.products}>{!!affiliates &&
            <GenericTable outputColumns={affiliateColumns} tableData={affiliates} expand={expandAffiliate}/>}</div>
    );
};

export default AffiliateResults;

import React from 'react';
import styles from './IphoneUI.module.scss';
const IphoneUI = ({children}) => {
    return (
        <div className={styles.iphoneX}>
            {children}
        </div>
    );
};

export default IphoneUI;

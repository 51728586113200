import React, {useEffect, useState} from 'react';
import BreadcrumbsBar from "../../../layouts/breadcrumb/BreadcrumbsBar";
import styles from "./AssignInfluencers.module.scss";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {t} from "i18next";
import Card from "../../../common/card/Card";
import {Autocomplete, Button, TextField} from "@mui/material";
import {getAllAffiliates} from "../../../services/affiliatesService";
import {AddCircleOutlined, LocalOfferOutlined, SettingsOutlined} from "@mui/icons-material";
import {addNewInfluencerPaidProject} from "../../../services/paidProjectsService";
import ExistPaidProjectInfluencers from "./ExistPaidProjectInfluencers/ExistPaidProjectInfluencers";
import LinksMultipleChoices from "./LinksMultipleChoices/LinksMultipleChoices";
import {formatTwoDatesRange} from "../../../utils/common";
import {CalendarIcon} from "@mui/x-date-pickers";
import Modal from "../../../common/modal/Modal";
import useModal from "../../../common/hooks/useModal";

const initialAffiliateLinkData = {
    affId: -1,
    affName: "",
    links: [],
    projectId: ""
}
const AssignInfluencers = () => {
    const [searchParams] = useSearchParams();
    const projectsData = useSelector((state) => state.projectsList.projects || []);
    const projectId = searchParams.get("pid");
    const [affiliates, setAffiliates] = useState([]);
    const selectedProject = projectsData.find(project => project.id === Number(projectId));
    const [selectedAffiliateLinkData, setSelectedAffiliateLinkData] = useState(initialAffiliateLinkData);
    const [resultsReady, setResultsReady] = useState(true);
    const {isModalOpen, toggleModal, modalContent, setModalContent} = useModal();
    const [projectInfleuncers, setProjectInfleuncers] = useState([]); // [
    const getAllAffiliatesForDropdown = async () => {
        const response = await getAllAffiliates();
        if (!!response.data && response.data.affiliates.length > 0) {
            setAffiliates(response.data.affiliates);
        }
    }

    useEffect(() => {
        if (!projectId) {
            return;
        }
        if (!selectedProject) {
            return;
        }
        getAllAffiliatesForDropdown();
        setSelectedAffiliateLinkData(prevState => ({
            ...prevState,
            affId: -1,
            affName: "",
            links: JSON.parse(selectedProject.links) || [],
            projectId: projectId
        }));

    }, [projectId, selectedProject]);

    const handleAffiliateDropdownChange = async (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        const affId = Number(textContent.split("-")[0].trim());
        const isInfluencerExist = projectInfleuncers.find(aff => aff.affiliate_id === affId);
        const affName = textContent.split("-")[1].trim();
        const newAffiliate = {
            affId,
            affName,
            links: selectedAffiliateLinkData.links
        };
        if (!!isInfluencerExist) {
            newAffiliate.affId = -1;
            newAffiliate.affName = "";
            setModalContent({
                title: t('common.failed'),
                content: t('paid-project.add-influencer-exist-msg')
            })
            toggleModal();
        }

        setSelectedAffiliateLinkData(prevState => ({
            ...prevState,
            ...newAffiliate
        }));
    }

    const handleLinksDropdownChange = async (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        const link = textContent.trim();
        if (!selectedAffiliateLinkData.links.includes(link)) {
            const copyArr = [...selectedAffiliateLinkData.links];
            copyArr.push(link);
            const newAffiliate = {
                ...selectedAffiliateLinkData,
                links: copyArr
            };
            setSelectedAffiliateLinkData(newAffiliate);
        }
    }
    const removeLink = (link) => {
        if (selectedAffiliateLinkData.links.length === 1) {
            return;
        }
        const copyArr = [...selectedAffiliateLinkData.links];
        const index = copyArr.indexOf(link);
        if (index > -1) {
            copyArr.splice(index, 1);
        }
        const newAffiliate = {
            ...selectedAffiliateLinkData,
            links: copyArr
        };
        setSelectedAffiliateLinkData(newAffiliate);
    }
    const addNewInfluencerRelation = async () => {
        setResultsReady(false);
        const result = await addNewInfluencerPaidProject(selectedAffiliateLinkData);
        if (!result.data) {
            return;
        }
        setResultsReady(true);
    }


    const isAddInfluencerButtonDisabled =
        selectedAffiliateLinkData.affId === -1 ||

        selectedAffiliateLinkData.links.length === 0;

    const setProjectExistInfluencers = (influencers) => {
        setProjectInfleuncers(influencers);
    }


    return (
        <div>
            <BreadcrumbsBar/>
            {selectedProject &&
                <Card title={`${t('affiliates-performances.add-affiliate')}`}>
                    <div className={styles.container}>
                        <div className={styles.mainTitle}>
                            {/*<img src={offersList.find(offer => offer.id === selectedProject.offer).logo} alt={selectedProject?.offer?.title}
                                 width={100}/>*/}
                            <div className={styles.titleElement}>
                                <LocalOfferOutlined/>
                                <div className={styles.projectTitle}>{selectedProject?.title}</div>
                            </div>
                            <div className={styles.titleElement}>
                                <CalendarIcon/>
                                {new Date(selectedProject.startDate).toDateString()}
                                <div>{formatTwoDatesRange(0, selectedProject.startDate, selectedProject.endDate)}</div>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <Button
                                variant="contained"
                                color="info"
                                size="small"
                                startIcon={<SettingsOutlined/>}
                                sx={{
                                    height: "30px",
                                    mb:1
                                }}
                                href={`/admin-paid-projects/edit?pid=${selectedProject.id}`}
                            >
                                Edit Project
                            </Button>
                            <div className={styles.dropdowns}>
                                <Autocomplete
                                    name="links"
                                    freeSolo
                                    required
                                    options={JSON.parse(selectedProject.links)}
                                    onChange={handleLinksDropdownChange}
                                    sx={{width: 260}}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={t('paid-project.select-links')}/>}
                                />
                                <Autocomplete
                                    name="Affiliates"
                                    freeSolo
                                    required
                                    options={affiliates.map(aff => aff.affiliate_id + " - " + aff.affiliate_name)}
                                    onChange={handleAffiliateDropdownChange}
                                    sx={{width: 260}}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={t('affiliates-performances.select-affiliate')}/>}
                                />
                            </div>
                            <div className={styles.linkResults}>
                                <LinksMultipleChoices startDate={selectedProject.startDate}
                                                      endDate={selectedProject.endDate}
                                                      links={selectedAffiliateLinkData.links} removeLink={removeLink}/>
                            </div>
                            <Button variant="contained"
                                    color="info"
                                    sx={{
                                        width: "fit-content",
                                        mt: 1
                                    }}
                                    startIcon={<AddCircleOutlined/>}
                                    disabled={isAddInfluencerButtonDisabled}
                                    onClick={addNewInfluencerRelation}>{t("paid-projects.add-influencer")}</Button>
                        </div>
                        {!!affiliates && affiliates.length > 0 && resultsReady && <div className={styles.results}>
                            <ExistPaidProjectInfluencers projectId={projectId}
                                                         setParentInfluencers={setProjectExistInfluencers}></ExistPaidProjectInfluencers>
                        </div>}
                    </div>
                </Card>}
            <Modal
                isOpen={isModalOpen}
                onCancelation={toggleModal}
                title={modalContent.title}
                content={modalContent.content}
            />
        </div>
    );
};

export default AssignInfluencers;

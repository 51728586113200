import React from 'react';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {ALLOWED_IMAGES_EXTENSIONS} from "../../../../common/constants/general";
import {Button} from "@mui/material";
import {t} from "i18next";
import styles from "./UploadStrapiImage.module.scss";



//todo - change for prod
const strapiUrl = 'https://api.influio.net';
const UploadStrapiImage = ({imageUrl, setImageUrl,imageCb}) => {

    const handleUploadImage = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        formData.append('ref', 'campaigns');
        formData.append('field', 'image');
        fetch(`${strapiUrl}/upload`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(result => {
                console.log('Success:', result);
                imageCb(result[0]);
                setImageUrl(`${strapiUrl}/files/${file.name}`);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    return (
        <div className={styles.container}>
            <div>
                <Button
                    variant="contained"
                    component="label"
                    sx={{width: 'fit-content'}}
                >
                    {t("common.upload-campaign-image")}
                    <CloudUploadIcon sx={{ml: 1}}/>
                    <input
                        type="file"
                        hidden
                        accept={ALLOWED_IMAGES_EXTENSIONS}
                        onChange={handleUploadImage}
                    />
                </Button>
            </div>
            <div>
                <div className={styles.fileName}>{imageUrl}</div>
                {imageUrl && imageUrl.includes(strapiUrl) &&
                    <div className={styles.imageHolder}>
                        <img src={imageUrl} alt=""/>
                    </div>
                }
            </div>
        </div>
    );
};

export default UploadStrapiImage;

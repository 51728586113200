import React, {useEffect, useState} from 'react'
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import styles from "./ProductPerformance.module.scss";
//xml file reader
import {Autocomplete, Button, TextField} from "@mui/material";
import {useSelector} from "react-redux";
import {t} from "i18next";
import useToast from "../../common/hooks/useToast";
import Toast from "../../common/toast/Toast";
import Card from "../../common/card/Card";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {getConversionReport} from "../../services/productPerformanceService";
import moment from "moment";
import ProductDevicePerformance from "./ProductDevicePerformance/ProductDevicePerformance";
import ProductList from "./ProductResults/ProductList";
import AffiliateResults from "./AffiliateResults/AffiliateResults";
import dayjs from 'dayjs';
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {FIRST_REPORT_DATE} from "../../common/constants/constService";
import BrandResults from "./BrandResults/BrandResults";
import {DATE_TYPES, startEndDateInitial} from "../../common/utils";
import {dateFormatWithHours} from "../../utils/common";
import {DownloadOutlined} from "@mui/icons-material";
import {DOWNLOAD_OFFER_PRODUCTS_EXCEL_DATA} from "../../common/constants/routes";


const offersHasBrands = [123, 132];
let reportTypes = [
    {
        id: 0,
        value: "Product Performance"
    },
    {
        id: 1,
        value: "Affiliate Performance"
    }
]
const ProductPerformance = () => {

    const offersList = useSelector((state) => state.offersList.offers);
    const userData = useSelector((state) => state.auth.user);
    const [startEndDateValue, setStartEndDateValue] = React.useState(startEndDateInitial);
    const [selectedOfferId, setSelectedOfferId] = useState("");
    const [selectedReportTypeId, setSelectedReportTypeId] = useState(0);
    const [offers, setOffers] = useState([]);
    const {isToastOpen, toggleToast, toastContent} = useToast();
    const [conversionResult, setConversionResult] = useState(null);
    const [dropdownReportTypes, setDropdownReportTypes] = useState(reportTypes);
    useEffect(() => {
        if (!!offersList && offersList.length > 0) {
            offersHasBrands.forEach(offerId => {
                const validIdForBrands = offersList.find(offer => offer.offerId === offerId);
                if (!!validIdForBrands && !reportTypes.find(reportType => reportType.id === 2)) {
                    reportTypes.push(
                        {
                            id: 2,
                            value: "Brands Performance"
                        }
                    )
                    setDropdownReportTypes(reportTypes)
                }
            })

            if (userData.isMasterPassword) {
                setOffers(offersList);
            } else {
                const selectedOffers = userData.user.offer_id_list;
                let offerObjects = [];

                if (selectedOffers) {
                    if (selectedOffers.includes(",")) {
                        const offerList = selectedOffers.split(",");
                        offerList.forEach(id => {
                            const offer = offersList.find(offer => offer.offerId === Number(id));
                            if (!!offer) offerObjects.push(offer);
                        })
                    } else {
                        const offer = offersList.find(offer => offer.offerId === Number(selectedOffers));
                        offerObjects.push(offer);
                    }
                    setOffers(offerObjects)
                }
            }
        }
    }, [offersList]);// eslint-disable-line react-hooks/exhaustive-deps


    const handleStartDateChange = (newValue, type) => {
        if (!newValue) return;
        if (type === DATE_TYPES.START_DATE) {
            setStartEndDateValue(prevState => ({
                ...prevState,
                startDate: newValue
            }));
        } else {
            setStartEndDateValue(prevState => ({
                ...prevState,
                endDate: newValue
            }));
        }
    };

    const handleOfferDropdownChange = async (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        setSelectedOfferId(Number(textContent.split(" - ")[0]));
    }


    const handleGetReport = async () => {
        setConversionResult([]);

        if (!startEndDateValue || !startEndDateValue.startDate || !startEndDateValue.endDate) return;
        const startDate = moment(startEndDateValue.startDate.$d)
            .set({hour: 0, minute: 0, second: 0}).format(dateFormatWithHours);
        const endDate = moment(startEndDateValue.endDate.$d)
            .set({hour: 23, minute: 59, second: 59}).format(dateFormatWithHours);
        const payload = {
            reportTypeId: selectedReportTypeId,
            offerId: selectedOfferId,
            startDate: startDate,
            endDate: endDate
        }
        const response = await getConversionReport(payload);
        if (!!response.data) {
            setConversionResult(response.data.results);
        }
    };


    const isButtonDisabled = () => (!startEndDateValue.endDate || !startEndDateValue.startDate || !selectedOfferId);
    const getOfferValue = () => {
        const selected = selectedOfferId ? offers.find(offer => offer.offerId === selectedOfferId) : null;
        return (!!selected) ? selected.id + ' - ' + selected.name : "Select Offer";

    };

    const handleReportTypeDropdownChange = (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        setSelectedReportTypeId(Number(dropdownReportTypes.find(reportType => reportType.value === textContent).id))
    };

    const handleDownloadCSV = async (e) => {
        if (!startEndDateValue || !startEndDateValue.startDate || !startEndDateValue.endDate) return;
        const startDate = moment(startEndDateValue.startDate.$d)
            .set({hour: 0, minute: 0, second: 0}).format(dateFormatWithHours);
        const endDate = moment(startEndDateValue.endDate.$d)
            .set({hour: 23, minute: 59, second: 59}).format(dateFormatWithHours);
        window.open(`${(process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_OFFER_PRODUCTS_EXCEL_DATA}?offerId=${selectedOfferId}&startDate=${startDate}&endDate=${endDate}&reportTypeId=${selectedReportTypeId}`, "_blank");
    };



    return (
        <>
            <div className={styles.container}>
                <BreadcrumbsBar/>
                <Card title={t("menu-item.purchase_reports")}>
                    <div className={styles.uploaderContainer}>
                        {!!offers && offers.length > 0 && <div className={styles.filterBlock}>
                            <Autocomplete
                                freeSolo
                                name="Report Types"
                                required
                                value={dropdownReportTypes.find(reportType => reportType.id === selectedReportTypeId)?.value}
                                options={dropdownReportTypes.map(reportType => reportType.value)}
                                onChange={e => handleReportTypeDropdownChange(e)}
                                sx={{width: 220}}
                                renderInput={(params) => <TextField {...params}
                                                                    label={t('create-new-user.select-report-type')}/>}
                            />

                            <Autocomplete
                                name="Offers"
                                freeSolo
                                required
                                value={getOfferValue()}
                                options={offers.map(offer => offer.offerId + " - " + offer.name)}
                                onChange={handleOfferDropdownChange}
                                sx={{width: 220}}
                                renderInput={(params) => <TextField {...params}
                                                                    label={t('create-new-user.select-offers')}/>}
                            /></div>}
                        <div className={`${styles.filterBlock} ${styles.datePickers}`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Start Date"
                                                minDate={dayjs(FIRST_REPORT_DATE)}
                                                defaultValue={dayjs(new Date())}
                                                onChange={(newValue) =>
                                                    handleStartDateChange(newValue, DATE_TYPES.START_DATE)}/>
                                    <DatePicker label="End Date"
                                                disableFuture
                                                minDate={dayjs(startEndDateValue.startDate)}
                                                onChange={(newValue) =>
                                                    handleStartDateChange(newValue, DATE_TYPES.END_DATE)}/>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <Button
                            disabled={isButtonDisabled()}
                            variant="contained"
                            color="confirmButton"
                            sx={{
                                fontWeight: 700,
                                fontSize: 14
                            }}
                            size="large"
                            onClick={handleGetReport}>GO!</Button>
                    </div>
                </Card>
                {
                    !!conversionResult && <div>

                        {(conversionResult.conversions || conversionResult.affiliates || conversionResult.brands) &&

                            <ProductDevicePerformance results={conversionResult}/>

                        }
                        <div className={styles.buttonHolder}>
                            <Button onClick={handleDownloadCSV}
                                    startIcon={<DownloadOutlined/>}
                                    color="info"
                                    variant="contained">{t("common-en.export")}</Button>
                        </div>
                        {conversionResult.conversions &&
                            <ProductList products={conversionResult.conversions}/>}
                        {conversionResult.affiliates &&
                            <AffiliateResults affiliates={conversionResult.affiliates}/>
                        }
                        {conversionResult.brands &&
                            <BrandResults brands={conversionResult.brands}/>
                        }
                    </div>
                }
            </div>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
            />
        </>
    )
}

export default ProductPerformance

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllCategories} from "../../services/categories";

const initialState = {categories: []};

export const fetchCategories = createAsyncThunk(
    'get/categories',
    async () => {
        const response = await getAllCategories();
        return response.data;
    }
)

export const categorySlice = createSlice({
    name: "categories",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
        })
    }
})

export const categoryReducer = categorySlice.reducer
import React from 'react';
import landingImage from "../../images/lcw-landing.webp"
import styles from "./LcwLangingPage.module.scss";
const LcwLandingPage = () => {
    return (
        <div className={styles.container}>
            <img src={landingImage} alt="Influencer"/>
            <div className={styles.contentHolder}>
                <h2>Sıkça Sorulan Sorular</h2>
                <div className={styles.eachField}>
                    <div className={styles.title}>1) Paylaşımlarıma hangi mecralarda yer verebilirim?</div>
                    <div className={styles.desc}>Instagram, Youtube, Facebook, Tik Tok, Twitch, Telegram ve Twitter hesaplarında paylaşımlarına ve LC Waikiki linklerine yer verebilirsin.</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>2) Paylaşımlarımda yer vereceğim linkleri kim hazırlayacak?</div>
                    <div className={styles.desc}>Linklerinizi programımıza başvurunuz sırasında telefonuna indirdiğiniz Influio uygulamasından kendiniz oluşturabilirsiniz. Lc Waikiki’den paylaşmak istediğiniz linklerinizi İnfluio uygulamasından dönüştererek, linklerinizin performansını takip edebilirsiniz. Başvurunuzun onaylanmasının ardından daha detaylı bilgi sağlanacaktır.</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>3) Paylaşımlarda yer vereceğim ürünleri kendim seçebilir miyim?</div>
                    <div className={styles.desc}>Paylaşımlarınızın özgün olması ve seçimleriniz sizin içerik yönetiminizle ilgilidir. Dilediğiniz LCW ürününe sosyal medya hesaplarınızda yer verebilirsiniz.</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>4) Linklerimin performansını nasıl takip ederim?</div>
                    <div className={styles.desc}>Influio uygulamanızdan linkleriniz üzerinden oluşan tıklama, satış gibi tüm performans rakamlarını takip edebilirsiniz.</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>5) Paylaşım yapmadan önce içeriklerimin onaylanması gerekiyor mu?</div>
                    <div className={styles.desc}>İçeriklerinizin sorumluluğu tamamen size aittir, topluluk kurallarına uyumlu olmak koşuluyla takipçilerinizin seveceği içerikleri oluşturarak onaya sunmaksızın paylaşım yapabilirsiniz.</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>6) Linklerimden nasıl kazanç sağlarım?</div>
                    <div className={styles.desc}>Linkleriniz üzerinden satış oluştukça, yapılan satışın belirli oranında LCW tarafından hediye çeki düzenlenir.</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>7) Hediye çekleri tüm influencerlar için aynı mıdır?</div>
                    <div className={styles.desc}>Hediye çeki kazancınız performansınıza bağlı olarak değişiklik gösterebilir. Kazanç tutarınızın üzerinden hediye çekleriniz 100TL, 200TL olmak üzere 100TL katları ile hazırlanmaktadır. (Örneğin 117TL kazancınız oluştu, 100TL hediye çeki kazanırsınız ve 17tl kazancınız bir sonraki aya devreder.)</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>8) Onay gelmeden paylaşıma başlayabilir miyim?</div>
                    <div className={styles.desc}>Hayır, linklerinizin oluşması için başvurunuzun onaylanması gerekmektedir. Başvurunuzun onaylanmasının ardından dilediğiniz ürünü içeriğinizi oluşturarak paylaşabilirsiniz. </div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>9) Hediye çekini nasıl ve ne zaman alabilirim?</div>
                    <div className={styles.desc}>Her ayın sonunda satışlarınızın Lc Waikiki tarafından kontrol edilmesinin ardından hediye çekleriniz yine LCW tarafından hazırlanarak size Influio aracılığıyla teslim edilecektir.  (Örneğin, Ocak ayında yaptığınız satışlar 1-5 Mart tarihleri arasında kontrol edilir, hediye çekleriniz 5-9 Mart tarihleri arasında hazırlanır ve size iletilir.)</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>10) Influio uygulamasının bu program ile ilgisi nedir?</div>
                    <div className={styles.desc}><b>LCW Bizim influencer’ımız Sensin Programı</b>'nın influencer çalışmalarının düzenleyici ve link üreticisi İnfluio platformudur. Linklerinizi güvenle İnfluio uygulamasından oluşturabilir, sorularınızı İnfluio’ya iletebilirsiniz.</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>11) Influio’dan başka markaların da linklerini oluşturabilir miyim?</div>
                    <div className={styles.desc}>Influio’daki anlaşmalı diğer markaların çalışmaları bu programın tamamen kapsamı dışıdır. Influio’nun size onayladığı tüm çalışmalara katılım gösterebilirsiniz. LC Waikiki dışında paylaştığınız linklerinizden kazancınız için Influio'nun ödeme koşullarına uyum sağlamanız gerekmektedir.</div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>12) Influio uygulamasını indirerek LC Waikiki Birim İnfluencer'ımız Sensin Programına katılabilir miyim?</div>
                    <div className={styles.desc}> Programa katılmak için öncelikle LC Waikiki mail adresinden onaylandığınıza ilişkin mail almanız gerekmektedir. Bu mail ardından İnfluio uygulamasını indirerek doldurduğunuz form 48 saat içinde onaylanacaktır. LC Waikiki'ye başvurduğunuz aynı mail adresi ile  influio'ya kayıt olmanız gerekmektedir. Aksi durumda başvurunuz reddedilecektir. </div>
                </div>
                <div className={styles.eachField}>
                    <div className={styles.title}>13) Influio uygulamasına giriş yapamıyorum, ekranda uyarı veriyor ne yapmalıyım? </div>
                    <div className={styles.desc}> Uygulamaya giriş yapamıyorsanız, henüz başvurunuz onaylanmamış yada reddedilmiş olabilir.Lc Waikiki'den onay maili almayan 10 bin takipçi altındaki hesaplar onaylanmamaktadır. Başvurunuzu LC Waikiki başvurusunda kullandığınız mail adresiniz ile yapmanız gerekmektedir. </div>
                </div>
            </div>
        </div>
);
};

export default LcwLandingPage;

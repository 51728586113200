import React from 'react';

import {t} from "i18next";
import {formatTurkishLira} from "../../common/utils";
import GenericTable from "../../common/table/GenericTable";

const AffiliatePerformanceTable = ({tableData}) => {
    const affiliateOutputColumns = {};
    affiliateOutputColumns.visibility = {}
    affiliateOutputColumns.data = [
        {
            accessorKey: 'offerName',
            header: t('common.offer_name'),
            enableClickToCopy: true
        },
        {
            accessorKey: 'Quantity',
            header: t('common.quantity'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{cell.row.original.conversions.length}</div>
            )
        },
        {
            accessorKey: 'totalAmount',
            header: t('common.total-amount'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.totalAmount)}</div>
            )
        },
    ]

    const affiliateConversionsOutputColumns = {};
    affiliateConversionsOutputColumns.visibility = {}
    affiliateConversionsOutputColumns.data = [
        {
            accessorKey: 'ad_id',
            header: t('common.ad-id'),
            enableClickToCopy: true
        },
        {
            accessorKey: 'device_brand',
            header: t('common.device-brand'),
            enableClickToCopy: true,
        },
        {
            accessorKey: 'date',
            header: t('common.date'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{new Date(cell.row.original.date).toDateString()}-{new Date(cell.row.original.date).toLocaleTimeString()}</div>
            )
        },
        {
            accessorKey: 'net_sale_amount',
            header: t('common.sale-amount'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.net_sale_amount)}</div>
            )
        },
        {
            accessorKey: 'net_revenue',
            header: t('common.revenue'),
            enableClickToCopy: true,
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.net_revenue)}</div>
            )
        },
        {
            accessorKey: 'status',
            header: t('common.status'),
            enableClickToCopy: true
        },
        {
            accessorKey: 'adv_sub',
            header: t('affiliates-performances.postback-msg'),
            enableClickToCopy: true
        }
    ]


    const expandAffiliate = ({row}) => (
        <GenericTable
            outputColumns={affiliateConversionsOutputColumns}
            tableData={row.original.conversions}
        />
    )

    const allRowsAreExpanded = (tableData) ? (tableData?.length === 1) : true;

    return (
        <>
            <GenericTable
                outputColumns={affiliateOutputColumns}
                tableData={tableData}
                allRowsAreExpanded={allRowsAreExpanded}
                expand={expandAffiliate}
            />
        </>
    );
};

export default AffiliatePerformanceTable;

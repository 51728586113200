import React from 'react';
import styles from "./AdjustSettingForm.module.scss";
import Card from "../../../../common/card/Card";
import {Button, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AdsClickOutlined} from "@mui/icons-material";
import {getAdjustClickUrl} from "../../../../services/adjustHelperService";

const initialObject = {
    clickUrl: "",
    purchaseToken: "",
    fallbackUrl: ""
}



const AdjustSettingForm = ({getClickUrlCb}) => {
    const {t} = useTranslation();
    const [formData, setFormData] = React.useState(initialObject);

    const handleTextChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    };
    const handleGenerateClickUrl = (e) => {
        const clickUrl = getAdjustClickUrl(formData);
        getClickUrlCb(clickUrl);
    };
    return (
        <div className={styles.container}>
            <Card title={t("integration-helper.adjust-card-title")}>
                <TextField
                    name="clickUrl"
                    label={t("integration-helper.adjust-click-url")}
                    value={formData.clickUrl}
                    placeholder={`https://app.adjust.com/[trackerId]?campaign={offer_name}&adgroup={aff_id}&creative={file_name}&click_id={transaction_id}&ip_address={ip}&campaign_id={offer_id}&creative_id={file_id}&affiliate_id={affiliate_id}&publisher_id={advertiser_id}&impression_id={transaction_id}`}
                    rows={4}
                    multiline
                    required
                    helperText="Adjust Click Url for Influio tracker in network trackers (adjust dashboard)"
                    variant="outlined"
                    sx={{width: '100%'}}
                    onChange={handleTextChange}
                />


                <div className={styles.doubleElement}>
                    <TextField
                        name="purchaseToken"
                        label={t("integration-helper.adjust-purchase-token")}
                        value={formData.purchaseToken}
                        variant="outlined"
                        placeholder={`csd34c`}
                        helperText="Adjust purchase event token (6 digits)"
                        required
                        sx={{width: '30%'}}
                        onChange={handleTextChange}
                    />
                    <TextField
                        name="fallbackUrl"
                        label={t("integration-helper.adjust-fallback-url")}
                        value={formData.fallbackUrl}
                        variant="outlined"
                        placeholder={'https://advertiser.com/product/xxx'}
                        sx={{width: '60%'}}
                        helperText="Web fallback url for incase of device is desktop"
                        onChange={handleTextChange}
                    />
                </div>
                <Button
                    variant="contained"
                    color={"success"}
                    disabled={
                        formData.clickUrl.length === 0 || formData.purchaseToken.length === 0
                    }
                    startIcon={<AdsClickOutlined/>}
                    onClick={handleGenerateClickUrl}>{t("integration-helper.generate-click-url")}
                </Button>
            </Card>
        </div>
    );
};

export default AdjustSettingForm;

import {HOME, INTEGRATIONS_HELPER, LOGOUT} from "./constants/routes";
import {Home, IntegrationInstructionsSharp, LogoutOutlined} from "@mui/icons-material";
import dayjs from "dayjs";
import React from "react";


export const isBeforeToday = (date) => {
    let timeDiff = new Date().getTime() - date.getTime();
    return Math.floor(timeDiff / (1000 * 3600 * 24)) > 0;
}
export const isToday = (dateToCheck) => {
    // Get today's date
    const today = new Date();

    // Compare the components of the dateToCheck with today's date
    const isSameDate =
        dateToCheck.getDate() === today.getDate() &&
        dateToCheck.getMonth() === today.getMonth() &&
        dateToCheck.getFullYear() === today.getFullYear();

    // Return true if the dateToCheck is today, otherwise return false
    return isSameDate;
};
export const formatTurkishLira = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
});

export const getInstagramLink = (name) => {
    let modifiedName = name.split("?")[0].trim();
    if (modifiedName.indexOf("instagram.com") > -1 && modifiedName.indexOf("/reels") < 0) {
        modifiedName = modifiedName.split("instagram.com")[1];
    }
    if (modifiedName.indexOf("instagram") > -1 && modifiedName.indexOf("/reels") < 0) {
        modifiedName = modifiedName.split("instagram")[1];
    }
    if (modifiedName.indexOf("ınstagram.com") > -1 && modifiedName.indexOf("reels") < 0) {
        modifiedName = modifiedName.split("ınstagram.com")[1];
    }
    if (modifiedName.indexOf("ınstagram") > -1 && modifiedName.indexOf("reels") < 0) {
        modifiedName = modifiedName.split("ınstagram")[1];
    }
    if (modifiedName.indexOf("@gmail.com") > -1 && modifiedName.indexOf("reels") < 0) {
        modifiedName = modifiedName.split("@gmail.com")[0];
    }
    modifiedName = (modifiedName[0] === "/") ?
        modifiedName.substring(1) :
        modifiedName;

    modifiedName = (modifiedName[0] === "@") ?
        modifiedName.substring(1) :
        modifiedName;

    return modifiedName.replace(/\s/g, "");

}

export const dataURItoBlob = (dataURI) => {
    if (!dataURI) return null;
    let binary = atob(dataURI.split(',')[1]);
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
        type: mimeString
    });
}

export const capitalizeFirstChar = s => s && s[0].toUpperCase() + s.slice(1)

export const removeFirstChar = s => s.substring(1);

export const getTodayDateYYYY_MM_DD = () => new Date().toISOString().slice(0, 10); //2023-01-09

export let navbarItems = [
    {
        name: 'menu-item.home-text',
        path: HOME,
        icon: <Home/>
    },
    {
        name: 'menu-item.integration_helper',
        path: INTEGRATIONS_HELPER,
        icon: <IntegrationInstructionsSharp/>
    },
    {
        name: 'menu-item-logout',
        path: LOGOUT,
        icon: <LogoutOutlined/>
    }
]

export const DATE_TYPES = {
    START_DATE: "START_DATE",
    END_DATE: "END_DATE"
}

export const startEndDateInitial = {
    startDate: dayjs(new Date()),
    endDate: null
}

export const getKeyByValue = (map,val) =>
    Object.keys(map).find(key => map[key].tr === val);

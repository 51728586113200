import React from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import AdjustSettingForm from "./Adjust/AdjustSetttingsForm/AdjustSettingForm";
import AdjustClickUrlResult from "./Adjust/AdjustClickUrlResult/AdjustClickUrlResult";
import AdjustTrackerPostbacks from "./Adjust/AdjustTrackerPostbacks/AdjustTrackerPostbacks";
import {getAdjustTracker} from "../../services/adjustHelperService";
import styles from './IntegrationHelper.module.scss';
const IntegrationHelper = () => {
    const [clickUrl, setClickUrl] = React.useState("");
    const getClickUrl = (url) => {
        setClickUrl("");
        setTimeout(() => {
            if (!!url) setClickUrl(url);
        })
    }
    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            <AdjustSettingForm getClickUrlCb={getClickUrl}/>
            {!!clickUrl && clickUrl.length > 0 &&
                <div className={styles.results}>
                    <AdjustClickUrlResult clickUrl={clickUrl}/>
                    <AdjustTrackerPostbacks tracker={getAdjustTracker(clickUrl).adjustNetworkTracker}/>
                </div>}
        </div>
    );
};

export default IntegrationHelper;

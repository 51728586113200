import React, {useEffect, useState} from 'react';
import BreadcrumbsBar from "../../../layouts/breadcrumb/BreadcrumbsBar";
import Card from "../../../common/card/Card";
import {
    Alert,
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {domainRegex, FIRST_REPORT_DATE} from "../../../common/constants/constService";
import {DATE_TYPES, startEndDateInitial} from "../../../common/utils";
import {useSelector} from "react-redux";
import styles from "./CreateCampaign.module.scss";
import moment from "moment/moment";
import Toast from "../../../common/toast/Toast";
import useToast from "../../../common/hooks/useToast";
import ListItemText from "@mui/material/ListItemText";
import {MenuProps} from "../../../common/dropdownSetup/DropdownSetup";
import UploadStrapiImage from "./UploadStrapiImage/UploadStrapiImage";
import {createCampaign} from "../../../services/createCampaignService";
import {DROPDOWN_SELECT_ALL_VALUE} from "../../../common/constants/general";
import {BrushOutlined} from "@mui/icons-material";
import {dateFormatWithHours} from "../../../utils/common";

const initialFormData = {
    titleTr: "",
    titleEn: "",
    textTr: "",
    textEn: "",
    startDate: "",
    endDate: "",
    image: "",
    promoCode: "",
    status: "Pending",
    isHotDeal: false,
    categories: [],
    offerUrl: "",
    offer: ""
}

const TEXT_FIELD_WIDTH = 500;

const CreateCampaign = () => {
    const [formData, setFormData] = React.useState(initialFormData);
    const {t} = useTranslation();
    const [startEndDateValue, setStartEndDateValue] = React.useState(startEndDateInitial);
    const offersList = useSelector((state) => state.offersList.offers);
    const userData = useSelector((state) => state.auth.user || state.auth.user.user);
    let categoriesList = useSelector((state) => state.categoriesList.categories);
    const [offers, setOffers] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [selectedOfferId, setSelectedOfferId] = useState("");
    const [isLinkValid, setIsLinkValid] = useState(true);
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [imageUrl, setImageUrl] = React.useState("");

    useEffect(() => {
        if (userData.user.offer_id_list === DROPDOWN_SELECT_ALL_VALUE) {
            setOffers(offersList)
        } else {
            const selectedOffers = offersList.filter((offer) => userData.user.offer_id_list.split(",").includes(String(offer.offerId)));
            setOffers(selectedOffers);
        }
        setCategories(categoriesList);
    }, [offersList, categoriesList]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        checkValidations(e.target);
        setFormData({...formData, [name]: value});
    }

    const handleStartDateChange = (newValue, type) => {
        if (!newValue) return;
        if (type === DATE_TYPES.START_DATE) {
            setStartEndDateValue(prevState => ({
                ...prevState,
                startDate: newValue
            }));
        } else {
            setStartEndDateValue(prevState => ({
                ...prevState,
                endDate: newValue
            }));
        }
    };
    const getOfferValue = () => {
        const selected = selectedOfferId ? offers.find(offer => offer.offerId === selectedOfferId) : null;
        return (!!selected) ? selected.offerId + ' - ' + selected.name : "Select Offer";
    };

    const checkValidations = ({name, value}) => {
        switch (name) {
            case 'offerUrl':
                setIsLinkValid(value.length !== 0 ? domainRegex.test(value) : true);
                break;
            default:
                break;
        }
    }

    const handleOfferDropdownChange = async (e) => {
        const {textContent} = e.target;
        setSelectedOfferId(Number(textContent.split(" - ")[0]));
    }

    const updateHandler = async () => {

        formData.startDate = moment(startEndDateValue.startDate.$d).utc(false).format(dateFormatWithHours);
        formData.endDate = moment(startEndDateValue.endDate.$d).utc(false).format(dateFormatWithHours);
        formData.categories = selectedCategories
            .map(category => categoriesList.find(c => c.name === category).id);
        formData.offer = offersList.find(offer => offer.offerId === Number(selectedOfferId)).id;
        const response = await createCampaign(formData);
        if (!!response.data) {
            setToastContent(t('advertiser-management.campaign-created-successfully'));
            setFormData(initialFormData);
            setImageUrl(null);
            setSelectedCategories([]);
            setStartEndDateValue(startEndDateInitial);
            setSelectedOfferId(null);
        } else {
            setToastContent(t("common-server-err-msg"));
        }
        toggleToast();
        console.log(formData);
    };

    const isButtonDisabled = () => {
        return !startEndDateValue.startDate ||
            !startEndDateValue.endDate ||
            !formData.titleEn ||
            !formData.titleTr ||
            !formData.textEn ||
            !formData.textTr ||
            selectedCategories.length === 0 ||
            !formData.offerUrl ||
            (!imageUrl || imageUrl.length === 0) ||
            !selectedOfferId ||
            !isLinkValid;
    };
    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setSelectedCategories(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const imageUploadCb = (image) => {
        if (!!image._id) {
            formData.image = image;
        }
    }


    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            <Card title={t("menu-item.create-campaign")}
                  customIcon={<BrushOutlined/>}>
                <div>

                    <div className={styles.formContainer}>
                        <div className={styles.formElements}>
                            <div className={styles.filters}>
                                <div>
                                    {offers && offers.length > 0 &&
                                        <Autocomplete
                                            name="Offers"
                                            freeSolo
                                            required
                                            value={getOfferValue()}
                                            options={offers.map(offer => offer.offerId + " - " + offer.name)}
                                            onChange={handleOfferDropdownChange}
                                            sx={{
                                                width: 215
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                                                label={t('create-new-user.select-offer')}/>}/>
                                    }

                                </div>
                                <div>
                                    {categories && categories.length > 0 &&
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="demo-multiple-name-label">{t("advertiser-management.select-category-msg")}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                sx={{width: 340}}
                                                value={selectedCategories}
                                                onChange={handleChange}
                                                input={<OutlinedInput label={t("advertiser-management.select-category-msg")} />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {categories.map((category) => (
                                                    <MenuItem key={category.id} value={category.name}>
                                                        <Checkbox
                                                            checked={selectedCategories.indexOf(category.name) > -1}/>
                                                        <ListItemText primary={category.name}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                            </div>
                            <div className={styles.datePickers}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DateTimePicker label={t("common.startDate")}
                                                    minDate={dayjs(FIRST_REPORT_DATE)}
                                                    defaultValue={dayjs(new Date())}
                                                    onChange={(newValue) =>
                                                        handleStartDateChange(newValue, DATE_TYPES.START_DATE)}/>
                                        <DateTimePicker label={t("common.endDate")}
                                                    minDate={dayjs(startEndDateValue.startDate)}
                                                    onChange={(newValue) =>
                                                        handleStartDateChange(newValue, DATE_TYPES.END_DATE)}/>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className={styles.titleHolder}>
                                <TextField
                                    id="outlined-basic"
                                    name="titleEn"
                                    label={t("common.title-en")}
                                    value={formData.titleEn}
                                    sx={{width: TEXT_FIELD_WIDTH}}
                                    variant="outlined"
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="titleTr"
                                    label={t("common.title-tr")}
                                    value={formData.titleTr}
                                    sx={{width: TEXT_FIELD_WIDTH}}
                                    variant="outlined"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className={styles.titleHolder}>
                                <TextField
                                    id="outlined-basic"
                                    name="textEn"
                                    label={t("common.text-en")}
                                    value={formData.textEn}
                                    variant="outlined"
                                    rows={4}
                                    sx={{width: TEXT_FIELD_WIDTH}}
                                    multiline
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    id="outlined-basic"
                                    name="textTr"
                                    label={t("common.text-tr")}
                                    value={formData.textTr}
                                    variant="outlined"
                                    sx={{width: TEXT_FIELD_WIDTH}}
                                    rows={4}
                                    multiline
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <div className={styles.uploadPromoCodeContainer}>
                                    <UploadStrapiImage imageUrl={imageUrl}
                                                       setImageUrl={setImageUrl}
                                                       imageCb={imageUploadCb}/>
                                </div>
                                <div className={styles.offerHolder}>
                                    <TextField
                                        id="outlined-basic"
                                        name="promoCode"
                                        label={t("common.promo-code")}
                                        sx={{width: TEXT_FIELD_WIDTH - 135}}
                                        value={formData.promoCode}
                                        variant="outlined"
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        name="offerUrl"
                                        label={t("common.offer-url")}
                                        value={formData.offerUrl}
                                        variant="outlined"
                                        sx={{width: 2 * TEXT_FIELD_WIDTH + 20}}
                                        onChange={handleInputChange}
                                    />
                                    {!isLinkValid &&
                                        <Alert variant="outlined" sx={{justifyContent: "center", mt: 1}}
                                               severity="error"> {t('common.link-is-not-valid')}</Alert>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button
                        sx={{
                            width: 'fit-content',
                            mt: 2
                        }}
                        color="success"
                        variant='contained'
                        disabled={isButtonDisabled()}
                        onClick={updateHandler}
                    >
                        {t("menu-item.create-campaign")}
                    </Button>
                </div>
            </Card>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    )
};

export default CreateCampaign;

import React from 'react'
import styles from './Header.module.scss'
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {NAVIGATION_HEADER_TEST_ID, NAVIGATION_MENU_OPENER_ICON_TEST_ID} from "../../../utils/testUtils";
import {useSelector} from "react-redux";
import useLogout from "../../../common/hooks/useLogout";
import {useTranslation} from "react-i18next";
import {Logout} from "@mui/icons-material";
import {Avatar} from "@mui/material";

const Header = ({open, handleDrawerOpen, AppBar}) => {
    const username = useSelector(state => state.auth?.user?.user.username);
    const {logout} = useLogout()
    const {t} = useTranslation();
    return (
        <AppBar className={`${styles.container} ${open ? 'opened' : 'closed'}`}
                position="fixed" open={open}
                data-testid={NAVIGATION_HEADER_TEST_ID}>
            <Toolbar>
                <IconButton
                    data-testid={NAVIGATION_MENU_OPENER_ICON_TEST_ID}
                    color="black"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && {display: 'none'}),
                    }}
                >
                    <MenuIcon/>
                </IconButton>
                <div className={styles.innerContainer}>
                    <div className={styles.usernameAndAvatarContainer}>
                        {
                            <Avatar sx={{bgcolor: '#FF511E'}}>{username.substring(0, 2).toUpperCase()}</Avatar>
                        }
                        {username && <div className={styles.username}>{`${t("common.hello")} ${username}`}</div>}
                    </div>
                    <button data-testid="logoutBtn" className={styles.logout} onClick={logout}>
                        <Logout/>
                    </button>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default Header
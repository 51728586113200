import React from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import styles from './Home.module.scss';
import {AutoAwesomeOutlined} from "@mui/icons-material";

const Home = () => {
    return (
        <div>
            <BreadcrumbsBar/>
            <div className={styles.header}>
                <div className={styles.content}>
                    <AutoAwesomeOutlined/>
                    <div>Welcome to Influio Backoffice</div>
                </div>
            </div>
        </div>
    );
};

export default Home;

import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import {BrowserRouter} from 'react-router-dom';
import './api/i18n';
import {createTheme, ThemeProvider} from "@mui/material";
import {
    BLACK_LISTED_COLOR,
    CONFIRM_BUTTON_COLOR,
    PRIMARY_COLOR,
    WHITE_LISTED_COLOR
} from "./common/constants/styleConstants";


const container = document.getElementById('root');
const root = createRoot(container);
const {palette} = createTheme();
const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat'
        ].join(','),
        fontSize: 12
    },
    palette: {
        primary: {
            main: PRIMARY_COLOR,
        },
        whitelisted: palette.augmentColor({
            color: {
                main: WHITE_LISTED_COLOR
            }
        }),
        blacklisted: palette.augmentColor({
            color: {
                main: BLACK_LISTED_COLOR
            }
        }),
        confirmButton: {
            main: CONFIRM_BUTTON_COLOR,
            contrastText: "white"
        },
    },
});


root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <React.Suspense fallback="">
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </React.Suspense>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

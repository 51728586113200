import React, {useEffect} from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import styles from './InfluencerChats.module.scss';
import {useSearchParams} from "react-router-dom";
import {getInfluencerData} from "../../services/influencerChatsService";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";
import Card from "../../common/card/Card";
import Chat, {Bubble, useMessages} from '@chatui/core';
import '@chatui/core/dist/index.css';
import {Button} from "@mui/material";
import {Instagram} from "@mui/icons-material";


const initialChatData = {
    chats: [],
    id: null,
    social: null
}

const InfluencerChats = () => {
    const {messages, appendMsg} = useMessages([]);
    const [searchParams] = useSearchParams();
    const pid = searchParams.get('pid');
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const [chatData, setChatData] = React.useState(initialChatData);
    const getInfluencerDataAndChats = async () => {
        const payload = {
            social: pid
        }
        const response = await getInfluencerData(payload);
        if (response && response.data) {
            setToastContent("Influencer data fetched successfully");
            console.log(response.data);
            setChatData(response.data);
            appendMessages(response.data);
        }

        // if (!!response && response.data) setInfluencers(response.data);
    }

    const appendMessages = (fullData) => {
        const messages = fullData.chats;
        const social = fullData.social;

        messages.forEach((msg, index) => {
            //const isLastMessage = index === messages.length - 1;
            const isMessageEmpty = msg.message === "" || msg.message.lenght === 0;
            if (!isMessageEmpty) {
                appendMsg({
                    type: 'text',
                    user: {name: social},
                    content: {text: msg.message},
                    position: 'left',
                });
            }
            const isResponseEmpty = msg.response === "" || msg.response.lenght === 0;
            if (!isResponseEmpty) {
                appendMsg({
                    type: 'text',
                    user: {name: 'Influio'},
                    content: {text: msg.response},
                    position: 'right',
                });
            }
        });
    }

    useEffect(() => {
        console.log('InfluencerChats mounted');
        getInfluencerDataAndChats().then();
        return () => {
            console.log('InfluencerChats unmounted');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /*function handleSend(type, val) {
        if (type === 'text' && val.trim()) {
            appendMsg({
                type: 'text',
                content: {text: val},
                position: 'right',
            });

            setTyping(true);

            setTimeout(() => {
                appendMsg({
                    type: 'text',
                    content: {text: 'Bala bala'},
                });
            }, 1000);
        }
    }*/

    function renderMessageContent(msg) {
        const {content} = msg;
        return <Bubble content={content.text}/>;
    }


    return (
        <div>
            <BreadcrumbsBar/>
            <div className={styles.container}>
                {!!chatData && !!chatData.id && <Card title={"Influencer Chats"}>
                    <div>
                        <div className={styles.generationContainer}>
                            <div>
                                <div className={styles.conversationTitle}>Conversation between</div>
                                <div className={styles.topBar}>
                                    <Button variant="contained" color="secondary" startIcon={<Instagram/>} onClick={
                                        (e) => {
                                            window.open(`https://www.instagram.com/influio`, '_blank');
                                        }
                                    }>Influio</Button>
                                    <div>and</div>
                                    <Button variant="contained" color="secondary" startIcon={<Instagram/>} onClick={
                                        (e) => {
                                            window.open(`https://www.instagram.com/${chatData.social}`, '_blank');
                                        }
                                    }>{chatData.social}</Button>
                                </div>
                            </div>
                        </div>
                        <Chat
                            navbar={{title: 'Influio Smart Assistant'}}
                            messages={messages}
                            renderMessageContent={renderMessageContent}
                            placeholder={'Type a message...'}
                            onSend={() => {
                                setToastContent("Sent message is not supported");
                                toggleToast();
                            }}
                        ></Chat>
                    </div>
                </Card>}
            </div>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default InfluencerChats;

import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, TextField} from "@mui/material";
import Card from "../../common/card/Card";
import styles from "./UserBankDetailsUpdate.module.scss";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {getHasOfferUserBankSettings, updateHasOfferUserBankSettings} from "../../services/usersService";
import {AppCategories} from "../../utils/hasOfferCategories";

const initState = {
    user_id: 0,
    firstname: '',
    lastname: '',
    phone: '',
    city: '',
    address: '',
    bank_name: '',
    company_title: '',
    country: '',
    hasoffersid: 0,
    iban: '',
    is_tax_payer: false,
    national_id: '',
    tax_number: '',
    tax_office: '',
    categories: []
}


const UserBankDetailsUpdate = () => {
    const {t} = useTranslation();
    const [formData, setFormData] = useState(initState);
    const [searchParams] = useSearchParams();
    const [hideForm,setHideForm] = useState(false);
    const [categoriesMap, setCategoriesMap] = useState({});
    const [showResult, setShowResult] = useState(false);
    const isCategorySelected = (key) => {
        return !!categoriesMap[key];
    };
    const getHasofferUserBankDetails = async (email) => {
        const response = await getHasOfferUserBankSettings(email);
        if (!!response.data && !!response.data.user) {
            let map = {};
            const responseFormData = response.data.user;
            const categoriesResponse = JSON.parse(responseFormData.categories);
            const resultFormData = {
                user_id: responseFormData.hasoffersid,
                firstname: responseFormData.firstname,
                lastname: responseFormData.lastname,
                phone: responseFormData.phone,
                city: responseFormData.city,
                address: responseFormData.address,
                bank_name: responseFormData.bank_name,
                company_title: responseFormData.company_title,
                country: responseFormData.country,
                hasoffersid: responseFormData.hasoffersid,
                iban: responseFormData.iban,
                is_tax_payer: responseFormData.is_tax_payer,
                national_id: responseFormData.national_id,
                tax_number: responseFormData.tax_number,
                tax_office: responseFormData.tax_office,
                categories: categoriesResponse
            }
            Object.keys(AppCategories).forEach(key => {
                const value = AppCategories[key].en;
                const isExist = !!categoriesResponse.find(category => category.trim() === value);
                map[key] = isExist;
            });
            setCategoriesMap(map);
            setFormData(resultFormData);
        }else{
            setHideForm(true);
        }
    }

    useEffect(() => {
        const email = searchParams.get("email");
        if (!!email) getHasofferUserBankDetails(email);
    }, [searchParams])

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleCategoryChange = (key) => {
        let currentState = categoriesMap[key];
        setCategoriesMap((prevState) => ({
            ...prevState,
            [key]: !currentState
        }));
        const value = AppCategories[key].en;
        if (!!currentState) {
            let copyArr = formData.categories.map(category => category.trim());
            const index = copyArr.indexOf(value);
            if (index > -1) {
                copyArr.splice(index, 1);
            }
            setFormData((prevState) => ({
                ...prevState,
                categories: copyArr
            }))
        } else {
            let addArr = [...formData.categories];
            addArr.push(value);
            setFormData((prevState) => ({
                ...prevState,
                categories: addArr
            }))
        }
    }

    const updateHandler = async (e) => {
        console.log(formData);
        const response = await updateHasOfferUserBankSettings(formData);
        if(response.data.isUpdated){
            setFormData(initState);
            setShowResult(true);
        }
    }
    return (
        <div className={styles.container}>

            {!hideForm && !showResult && <Card title={t("common-tr.update-user-bank-settings-msg")}>
                <div className={styles.formItself}>
                    <TextField
                        id="outlined-basic"
                        name="firstname"
                        label={t("common-tr.name")}
                        value={formData.firstname}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="lastname"
                        label={t("common-tr.surname")}
                        value={formData.lastname}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="phone"
                        label={t("common-tr.phone")}
                        value={formData.phone}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="city"
                        label={t("common-tr.city")}
                        value={formData.city}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="country"
                        label={t("common-tr.country")}
                        value={formData.country}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="address"
                        rows={3}
                        multiline
                        label={t("common-tr.address")}
                        value={formData.address}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="national_id"
                        label={t("common-tr.tc-no")}
                        value={formData.national_id}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <div className={styles.checkBoxHolder}>
                        <Checkbox
                            checked={!!formData.is_tax_payer}
                            onChange={e => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    is_tax_payer: !prevState.is_tax_payer
                                }))
                            }}
                        />
                        <div>{t("common-tr.is-tax-payer-question")}</div>
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            name="company_title"
                            label={t("common-tr.company-title")}
                            value={formData.company_title}
                            variant="outlined"
                            sx={{width: 330}}
                            onChange={handleInputChange}
                        />
                        <div className={styles.fieldInfo}>{t("common-tr.is-tax-payer-msg")}</div>
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            name="tax_number"
                            label={t("common-tr.tax-no")}
                            value={formData.tax_number}
                            variant="outlined"
                            sx={{width: 330}}
                            onChange={handleInputChange}
                        />
                        <div className={styles.fieldInfo}>{t("common-tr.is-tax-payer-msg")}</div>
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            name="tax_office"
                            label={t("common-tr.tax-office")}
                            value={formData.tax_office}
                            variant="outlined"
                            sx={{width: 330}}
                            onChange={handleInputChange}
                        />
                        <div className={styles.fieldInfo}>{t("common-tr.is-tax-payer-msg")}</div>
                    </div>
                    <TextField
                        id="outlined-basic"
                        name="iban"
                        label={t("common-tr.iban")}
                        value={formData.iban}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="bank_name"
                        label={t("common-tr.bank")}
                        value={formData.bank_name}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <div className={styles.categories}>
                        <div className={styles.title}>{t("common-tr.categories")}</div>
                        <div className={styles.subTitle}>{t("common-tr.categories-msg")}</div>
                        {!!formData.categories && Object.keys(categoriesMap).length > 0 && Object.keys(AppCategories).map(key => (
                            <div key={key} className={styles.checkBoxHolder}>
                                <Checkbox
                                    name={AppCategories[key].tr}
                                    checked={isCategorySelected(key)}
                                    onChange={e => handleCategoryChange(key)}
                                />
                                <div>{AppCategories[key].tr}</div>
                            </div>
                        ))}
                    </div>
                    <Button
                        variant='contained'
                        /*disabled={!password || !email}*/
                        onClick={updateHandler}
                    >
                        {t("common-tr.update")}
                    </Button>
                </div>
            </Card>}
            {hideForm && <div>Kullanıcı bulunamadı</div>}
            {showResult && <div>
                <Alert
                    severity={"info"}
                    sx={{width: '100%'}}>
                    {t("user-admin.user_details_updated_success_msg_tr")}
                </Alert>
            </div>}
        </div>
    );
};

export default UserBankDetailsUpdate;

import React, {useEffect} from 'react';
import Card from "../../../common/card/Card";
import {Button} from "@mui/material";
import GenericTable from "../../../common/table/GenericTable";
import Modal from "../../../common/modal/Modal";
import Toast from "../../../common/toast/Toast";
import {useTranslation} from "react-i18next";
import useToast from "../../../common/hooks/useToast";
import useModal from "../../../common/hooks/useModal";

import {deletePotentialInfluencer, getAllInfluencersWithChats} from "../../../services/radarAiService";
import {AI_CHAT_ASSISTANT, INFLUENCER_CHATS} from "../../../common/constants/routes";

const PotentialInfluencers = () => {
    const {t} = useTranslation();
    const [influencers, setInfluencers] = React.useState([]);
    //const [formData, setFormData] = React.useState(null);
    const [influencerToDelete, setInfluencerToDelete] = React.useState(null);
    const {toastContent, isToastOpen, toggleToast, setToastContent} = useToast();
    const {isModalOpen, toggleModal, modalContent} = useModal({
        title: "Delete Influencer",
        content: "Are you sure you want to delete this influencer?"
    });
    const influencerColumns = {};
    influencerColumns.visibility = {}
    influencerColumns.data = [
        {
            accessorKey: 'id',
            header: "Chats",
            Cell: (cell) => (
                <div>
                    <Button
                        variant="contained"
                        color="info" onClick={
                        (e) => {
                            const slicedSocial = cell.row.original.social;
                            window.open(`${AI_CHAT_ASSISTANT}${INFLUENCER_CHATS}?pid=${slicedSocial}`, "_blank");
                        }
                    }>{`INFLUENCER CHAT DETAILS`}</Button>
                </div>
            )
        },
        {
            accessorKey: 'chats',
            header: "Chat Message Count",
            Cell: (cell) => (
                <div>{cell.row.original.chats.length}</div>
            )
        },
        {
            accessorKey: 'name',
            header: "Name",
        },
        {
            accessorKey: 'social',
            header: "Instagram Account"
        },
        /*  {
              accessorKey: 'follower_count',
              header: "Followers Count",
              Cell: (cell) => (
                  <div>{formatNumberWithNumberFormatter(cell.row.original.follower_count)}</div>
              )
          },*/
        /*     {
                 accessorKey: 'engagements',
                 header: "Engagements",
                 Cell: (cell) => (
                     <div>{formatNumberWithNumberFormatter(cell.row.original.engagements)}</div>
                 )
             },
             {
                 accessorKey: 'engagement_rate',
                 header: "Engagement Rate",
                 Cell: (cell) => (
                     <div>{formatPercentage(cell.row.original.engagement_rate)}</div>
                 )
             },*/
        /*   {
               accessorKey: 'area_of_interest',
               header: "Area Of Interest"
           },*/
        /*        {
                    accessorKey: 'is_msg_sent',
                    header: "Is Msg Sent",
                    Cell: (cell) => (
                        <Button
                            variant="contained"
                            color="info"
                            sx={{mb: 1}}
                            disabled={cell.row.original.is_msg_sent}
                            startIcon={<LinkOutlined/>}
                            onClick={e => sendFirstMsg(cell.row.original)}>
                            {cell.row.original.is_msg_sent ? "ALREADY SENT" : "SENT"}
                        </Button>
                    )
                },
                {
                    accessorKey: 'actions',
                    header: "Actions",
                    Cell: (cell) => (
                        <Button
                            variant="contained"
                            color="error"
                            sx={{mb: 1}}
                            startIcon={<LinkOutlined/>}
                            onClick={e => openDeleteModal(cell.row.original)}>
                            DELETE
                        </Button>
                    )
                }*/
    ];

   /* const openDeleteModal = (influencer) => {
        setInfluencerToDelete(influencer);
        toggleModal();
        console.log("openDeleteModal: ", influencer);
    }

    const sendFirstMsg = (influencer) => {
        console.log(influencer)
    }*/

    const getPotentialInfluencers = async () => {
        const response = await getAllInfluencersWithChats();
        if (response && response.data) {
            setInfluencers(response.data);
        } else {
            //console.error(response);
        }

    }
    useEffect(() => {
        getPotentialInfluencers();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*  const excelFileChanged = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        setFormData(formData);
    }

    const sendUploadNewInfluencersRequest = async () => {
        if (!formData) {
            setToastContent("Please select a file first");
            toggleToast();
            return;
        }
        const response = await sendPotentialInfluencersExcelFileForInsert(formData);
        if (!!response.data && !!response.data.isAdded) {
            setToastContent(t("ai-radar.uploaded_influencers_toast_msg", {
                updatedCount: response.data.updatedCount,
                existCount: response.data.totalUpdateCount - response.data.updatedCount
            }));
        } else {
            setToastContent("Data update failed");
        }
        setFormData(null);
        toggleToast();
        getPotentialInfluencers();
    }*/

    const deleteInfluencerConfirmed = async () => {
        if (influencerToDelete) {
            const payload = {
                id: influencerToDelete.id
            }
            const response = await deletePotentialInfluencer(payload);
            if (!!response.data && !!response.data.isRemoved) {
                setToastContent("Influencer deleted successfully");
            } else {
                setToastContent("Influencer delete failed");
            }
            toggleToast();
            toggleModal();
            getPotentialInfluencers();
        }
    }
    return (
        <div>
            {/* <Card title={t("ai-radar.upload_new_potential_influencers-msg")}>
                <div className={styles.uploadExcelPart}>
                    <TextField
                        variant="outlined"
                        type="text"
                        disabled={true}
                        value={"Choose excel file(xlsx)"}
                        contentEditable={false}
                        InputProps={{
                            endAdornment: (
                                <IconButton component="label">
                                    <FileUploadOutlined/>
                                    <input
                                        styles={{display: "none"}}
                                        type="file"
                                        accept=".xlsx"
                                        hidden
                                        onChange={excelFileChanged}
                                        name="[licenseFile]"
                                    />
                                </IconButton>
                            ),
                        }}
                    />
                    {formData && formData.get("file") && <Alert icon={<CheckOutlined fontSize="inherit"/>}
                                                                severity="success">{formData.get("file").name}</Alert>}
                    <Button
                        variant="contained"
                        color="success"
                        onClick={sendUploadNewInfluencersRequest}
                        disabled={!formData}
                        startIcon={<UploadFileOutlined/>}>Upload</Button>
                </div>
            </Card>*/}
            {influencers && <Card title={t("ai-radar.exist_influencers")}>
                <GenericTable
                    outputColumns={influencerColumns}
                    tableData={influencers}
                    allRowsAreExpanded={influencers.length === 1}
                    rowCount={influencers.length}
                />
            </Card>}
            <Modal
                isOpen={isModalOpen}
                onCancelation={e => {
                    setInfluencerToDelete(null);
                    toggleModal()
                }}
                title={modalContent.title}
                content={modalContent.content}
                onConfirmation={deleteInfluencerConfirmed}
            />
            <Toast
                isOpen={isToastOpen}
                content={toastContent}
                onClose={toggleToast}
                severity="info"
            />
        </div>
    );
};

export default PotentialInfluencers;

import {clientGetWrapper, clientPostWrapper} from "../api/Api";
import {
    APPROVE_ALL_OR_REJECT_ALL_INFLUENCERS,
    DELETE_PAID_PROJECT,
    GET_ALL_PROJECTS, GET_PAID_PROJECT_INFLUENCERS, INSERT_NEW_INFLUENCER_RELATION,
    INSERT_NEW_PAID_PROJECTS,
    UPDATE_PAID_PROJECT, UPDATE_PAID_PROJECT_INFLUENCER
} from "../common/constants/routes";


export const getAllPaidProjects = () => {
    return clientGetWrapper(GET_ALL_PROJECTS)
}

export const getSelectedProjectInfluencers = (projectId) => {
    return clientGetWrapper(GET_PAID_PROJECT_INFLUENCERS+ `?projectId=${projectId}`);
}
export const createPaidProject = (payload) => {
    return clientPostWrapper(INSERT_NEW_PAID_PROJECTS, payload)
}

export const addNewInfluencerPaidProject = (payload) => {
    return clientPostWrapper(INSERT_NEW_INFLUENCER_RELATION, payload)
}
export const updatePaidProject = (payload) => {
    return clientPostWrapper(UPDATE_PAID_PROJECT, payload)
}

export const changePaidProjectDisplayStatus = (payload) => {
    return clientPostWrapper(DELETE_PAID_PROJECT, payload)
}

export const updatePaidProjectInfuencer = (payload) => {
    return clientPostWrapper(UPDATE_PAID_PROJECT_INFLUENCER, payload)
}

export const approveOrRejectInfluencers = (payload) => {
    return clientPostWrapper(APPROVE_ALL_OR_REJECT_ALL_INFLUENCERS, payload)
}





import thunk from "redux-thunk";
import configureMockStore from "redux-mock-store";

export const LOGGED_OUT_USERDATA = {auth: {}};
export const LOGGED_IN_USERDATA = {
    auth: {

    }
};

export const NAVIGATION_MENU_OPENER_ICON_TEST_ID = 'menuOpenerIcon'
export const NAVIGATION_AVATAR_IMAGE_TEST_ID = 'avatarImage'
export const NAVIGATION_NETWORK_LOGO_URL_TEST_ID = "networkLogoURL"
export const NAVIGATION_SIDEMENU_ITEMS_TEST_ID = "navbarItems"
export const NAVIGATION_HEADER_TEST_ID = "header"
export const EDIT_CREATE_SALES_PERSON_NAME_TEST_ID = "salesPersonName"
export const EDIT_CREATE_SALES_PERSON_EMAIL_TEST_ID = "salesPersonEmail"
export const MENU_HEADER_TEST_ID = "menuHeader"
export const MENU_CLOSER_ICON_TEST_ID = "menuCloserIcon"
export const CURRENCIES_DROPDOWN = "currenciesDropdown"
export const LANGUAGES_DROPDOWN = "languagesDropdown"
export const CURRENCIES_FETCH_BUTTON = "fetchDailyButton"
export const CURRENCIES_UPDATE_BUTTON = "updateBtn"
export const CURRENCIES_ADD_NEW_BUTTON = "addNewCurrencyBtn"
export const ADVERTISER_GLOBAL_PIXEL_SEARCH = "advSearch";
export const TABLE_TEXT_FIELD_INPUT = "tableTextField";
export const ADVERTISER_GLOBAL_PIXEL_UPDATE_GLOBAL_PIXEL = "updateGlobalPixelBtn";

export const CREATE_NEW_USER_USERNAME = "CREATE_NEW_USER_USERNAME"
export const CREATE_NEW_USER_EMAIL = "CREATE_NEW_USER_EMAIL"
export const CREATE_NEW_USER_WEBSITE_DOMAIN_INPUT = "CREATE_NEW_USER_WEBSITE_DOMAIN_INPUT"
export const CREATE_NEW_USER_WEBSITE_DOMAIN_DROPDOWN = "CREATE_NEW_USER_WEBSITE_DOMAIN_DROPDOWN"

export const EDIT_WHITE_LABEL_CREATE_FAVICON_BUTTON = 'EDIT_WHITE_LABEL_CREATE_FAVICON_BUTTON'
export const EDIT_WHITE_LABEL_FAVICON_CODE_ELEMENT = 'EDIT_WHITE_LABEL_FAVICON_CODE_ELEMENT'
export const EDIT_WHITE_LABEL_BRAND_DROPDOWN = 'EDIT_WHITE_LABEL_BRAND_DROPDOWN'
export const EDIT_WHITE_LABEL_WIDGET_CODE_DROPDOWN = 'EDIT_WHITE_LABEL_WIDGET_CODE_DROPDOWN'
export const EDIT_WHITE_LABEL_WEBSITE_DOMAIN_INPUT = 'EDIT_WHITE_LABEL_WEBSITE_DOMAIN_INPUT'
export const EDIT_WHITE_LABEL_TERMS_URL_INPUT = 'EDIT_WHITE_LABEL_TERMS_URL_INPUT'
export const EDIT_WHITE_LABEL_ABOUT_US_INPUT = 'EDIT_WHITE_LABEL_ABOUT_US_INPUT'
export const EDIT_WHITE_LABEL_CONTACT_US_URL_INPUT = 'EDIT_WHITE_LABEL_CONTACT_US_URL_INPUT'
export const EDIT_WHITE_LABEL_NETWORK_ABBREVIATION_INPUT = 'EDIT_WHITE_LABEL_NETWORK_ABBREVIATION_INPUT'
export const EDIT_WHITE_LABEL_NETWORK_BIG_LOGO_BUTTON = 'EDIT_WHITE_LABEL_NETWORK_BIG_LOGO_BUTTON'
export const EDIT_WHITE_LABEL_NETWORK_BIG_LOGO_INPUT = 'EDIT_WHITE_LABEL_NETWORK_BIG_LOGO_INPUT'
export const EDIT_WHITE_LABEL_NETWORK_MINI_LOGO_BUTTON = 'EDIT_WHITE_LABEL_NETWORK_MINI_LOGO_BUTTON'
export const EDIT_WHITE_LABEL_NETWORK_MINI_LOGO_INPUT = 'EDIT_WHITE_LABEL_NETWORK_MINI_LOGO_INPUT'
export const EDIT_WHITE_LABEL_NETWORK_SUPPORT_SENDER_NAME_INPUT = 'EDIT_WHITE_LABEL_NETWORK_SUPPORT_SENDER_NAME_INPUT'
export const EDIT_WHITE_LABEL_NETWORK_SUPPORT_SENDER_EMAIL_INPUT = 'EDIT_WHITE_LABEL_NETWORK_SUPPORT_SENDER_EMAIL_INPUT'
export const EDIT_WHITE_LABEL_NOTIFICATION_USER_REGISTRATION_DROPDOWN_SELECT_ALL_OPTION = 'EDIT_WHITE_LABEL_NOTIFICATION_USER_REGISTRATION_DROPDOWN_SELECT_ALL_OPTION'
export const EDIT_WHITE_LABEL_NOTIFICATION_WIDGET_CREATION_DROPDOWN_SELECT_ALL_OPTION = 'EDIT_WHITE_LABEL_NOTIFICATION_WIDGET_CREATION_DROPDOWN_SELECT_ALL_OPTION'
export const EDIT_WHITE_LABEL_NOTIFICATION_ADVANCED_BLOCKING_DROPDOWN_SELECT_ALL_OPTION = 'EDIT_WHITE_LABEL_NOTIFICATION_ADVANCED_BLOCKING_DROPDOWN_SELECT_ALL_OPTION'
export const EDIT_WHITE_LABEL_SAVE_BUTTON = 'EDIT_WHITE_LABEL_SAVE_BUTTON'

export const PREMIUM_WEBSITES_SEARCH = "PREMIUM_WEBSITES_SEARCH"
export const PREMIUM_WEBSITES_SWITCHER_CONTAINER = "PREMIUM_WEBSITES_SWITCHER_CONTAINER"
export const PREMIUM_WEBSITES_WEBSITE_INFO = "PREMIUM_WEBSITES_WEBSITE_INFO"
export const PREMIUM_WEBSITES_EDIT_WHITE_BLACKLIST_CARD = "PREMIUM_WEBSITES_EDIT_WHITE_BLACKLIST_CARD"
export const PREMIUM_WEBSITES_SAVE_BUTTON = "PREMIUM_WEBSITES_SAVE_BUTTON"
export const PREMIUM_WEBSITES_SEARCH_INPUT_ADVERTISER = "PREMIUM_WEBSITES_SEARCH_INPUT_ADVERTISER"
export const PREMIUM_WEBSITES_SEARCH_ADVERTISER_BUTTON = "PREMIUM_WEBSITES_SEARCH_ADVERTISER_BUTTON"
export const PREMIUM_WEBSITES_SEARCH_ADVERTISER_DROPDOWN = "PREMIUM_WEBSITES_SEARCH_ADVERTISER_DROPDOWN"
export const PREMIUM_WEBSITES_SEARCH_INPUT_CAMPAIGN = "PREMIUM_WEBSITES_SEARCH_INPUT_CAMPAIGN"
export const PREMIUM_WEBSITES_SEARCH_CAMPAIGN_BUTTON = "PREMIUM_WEBSITES_SEARCH_CAMPAIGN_BUTTON"
export const PREMIUM_WEBSITES_SEARCH_CAMPAIGN_DROPDOWN = "PREMIUM_WEBSITES_SEARCH_CAMPAIGN_DROPDOWN"
export const PREMIUM_WEBSITES_ADV_DROPDOWN_OPTION_NAME_ = "PREMIUM_WEBSITES_ADV_DROPDOWN_OPTION_NAME_"
export const PREMIUM_WEBSITES_CAMPAIGN_DROPDOWN_OPTION_NAME_ = "PREMIUM_WEBSITES_CAMPAIGN_DROPDOWN_OPTION_NAME_"
export const PREMIUM_WEBSITES_SWITCHER = "PREMIUM_WEBSITES_SWITCHER"
export const EDIT_USER_USERNAME_TEXT_FIELD = 'EDIT_USER_USERNAME_TEXT_FIELD'
export const EDIT_USER_EMAIL = 'EDIT_USER_EMAIL'
export const EDIT_USER_PAYPAL = 'EDIT_USER_PAYPAL'
export const EDIT_USER_ROLE_NAME = 'EDIT_USER_ROLE_NAME'
export const EDIT_USER_SAVE_BUTTON = 'EDIT_USER_SAVE_BUTTON'
export const EDIT_USER_SALES_PERSON_DROPDOWN = "EDIT_USER_SALES_PERSON_DROPDOWN"
export const GENERIC_SELECT_ALL_BUTTON = 'GENERIC_SELECT_ALL_BUTTON'
export const GENERIC_SELECT_NONE_BUTTON = 'GENERIC_SELECT_NONE_BUTTON'

export const FAST_FINDER_CAMPAIGN_DROPDOWNS_LANGUAGE = "FAST_FINDER_CAMPAIGN_DROPDOWNS_LANGUAGE"
export const FAST_FINDER_CAMPAIGN_DROPDOWNS_SALES_PERSON = "FAST_FINDER_CAMPAIGN_DROPDOWNS_SALES_PERSON"
export const EDIT_MAIL_CONTACT_PARTNER_CONTACT_NAME_INPUT = "EDIT_MAIL_CONTACT_PARTNER_CONTACT_NAME_INPUT"
export const EDIT_MAIL_CONTACT_PARTNER_CONTACT_EMAIL_INPUT = "EDIT_MAIL_CONTACT_PARTNER_CONTACT_EMAIL_INPUT"
export const EDIT_MAIL_CONTACT_CREATE_UPDATE_BUTTON = "EDIT_MAIL_CONTACT_CREATE_UPDATE_BUTTON"
export const CLEAN_CACHE_INPUT = "CLEAN_CACHE_INPUT"
export const EDIT_USER_SET_UNDER_GECKO = "EDIT_USER_SET_UNDER_GECKO"
export const CONVERT_TO_WHITE_LABEL = "CONVERT_TO_WHITE_LABEL"
export const EDIT_USER_ADS_REVIEW_SWITCHER = "EDIT_USER_ADS_REVIEW_SWITCHER";
export const GENERIC_SELECT_COUNTRY_NAME = 'country_name';
export const EDIT_USER_CONVERT_BUTTON_TOOLTIP = 'EDIT_USER_CONVERT_BUTTON_TOOLTIP';
export const EDIT_USER_ADS_REVIEW_SWITCHER_TOOLTIP = 'EDIT_USER_ADS_REVIEW_SWITCHER_TOOLTIP';
export const VIDEO_WIDGET_WIDGET_IDS_TEXT_FIELD = "VIDEO_WIDGET_WIDGET_IDS_TEXT_FIELD";
export const VIDEO_WIDGET_ADDITIONAL_DATA_TEXT_FIELD = "VIDEO_WIDGET_ADDITIONAL_DATA_TEXT_FIELD";
export const NAVBAR_ITEM_TOOLTIP = "NAVBAR_ITEM_TOOLTIP"



export const generateMockStore = (auth) => {
    const middlewares = [thunk]
    const mockStore = configureMockStore(middlewares);
    return mockStore(auth);
}


import {v4 as uuidv4} from 'uuid';
import {clientGetWrapper} from "../api/Api";
import {GET_TRACKER_POSTBACKS} from "../common/constants/routes";


const ADJUST_LINK_ERRORS = {
    NOT_A_VALID_ADJUST_DOMAIN: "NOT_A_VALID_ADJUST_DOMAIN",
    MISSING_PARAMETERS: "MISSING_PARAMETERS"
}

export const getAdjustTracker = (clickUrl) => {
    const clearBaseUrl = clickUrl.split("?")[0];
    const adjustNetworkTracker = clearBaseUrl.split("https://app.adjust.com/")[1];
    return {clearBaseUrl, adjustNetworkTracker};
}

function getTrackerAndBaseURL(clickUrl) {
    const {clearBaseUrl, adjustNetworkTracker} = getAdjustTracker(clickUrl);
    if (!adjustNetworkTracker) {
        return ADJUST_LINK_ERRORS.NOT_A_VALID_ADJUST_DOMAIN;
    }
    return {clearBaseUrl, adjustNetworkTracker};
}

function generateParams(adjustNetworkTracker, purchaseToken) {
    const tid = uuidv4();
    const campaign = `influio_cps`;
    const adgroup = `1011`;
    const network_id = `clicktr`;
    const purchasePostbackUrl = encodeURIComponent(`https://dashboard-api.influio.net/adjust/handleServerPostback?goal_name=purchase&tid=${tid}&amount={reporting_revenue}&pp={partner_parameters}&os={os_name}&tracker=${adjustNetworkTracker}&purchase_token=${purchaseToken}`)
    const installPostbackUrl = encodeURIComponent(`https://dashboard-api.influio.net/adjust/handleServerPostback?goal_name=install&tid=${tid}&os={os_name}&tracker=${adjustNetworkTracker}`);
    return {tid, campaign, adgroup, network_id, purchasePostbackUrl, installPostbackUrl};
}

export const getAdjustClickUrl = (params) => {
    const {clickUrl, purchaseToken, fallbackUrl} = params;
    if (!clickUrl || !purchaseToken) return ADJUST_LINK_ERRORS.MISSING_PARAMETERS;
    const {clearBaseUrl, adjustNetworkTracker} = getTrackerAndBaseURL(clickUrl);

    const {
        tid,
        campaign,
        adgroup,
        network_id,
        purchasePostbackUrl,
        installPostbackUrl
    } = generateParams(adjustNetworkTracker, purchaseToken);


    let finalUrl = clearBaseUrl;
    finalUrl += `?network_id=${network_id}` +
        `&campaign=${campaign}` +
        `&adgroup=${adgroup}` +
        `&creative=${tid}` +
        `&event_callback_${purchaseToken}=${purchasePostbackUrl}` +
        `&install_callback=${installPostbackUrl}`;
    if (!!fallbackUrl) {
        const redirects = encodeURIComponent(fallbackUrl+`?tid=${tid}&utm_source=influio&utm_medium=cps&utm_term=${tid}&utm_campaign=&network_id=clicktr&tracking=${Math.round((Math.random() * 1000000))}`);
        finalUrl += `&fallback=${redirects}`;
        finalUrl += `&redirect_macos=${redirects}`;
    }
    finalUrl += `&tracker_limit=250000`;
    return finalUrl;
}

export const getPostbacksByTracker = (tracker) => {
    return clientGetWrapper(GET_TRACKER_POSTBACKS, {tracker});
}
import React, {useEffect} from 'react';
import Card from "../../../../common/card/Card";
import GenericTable from "../../../../common/table/GenericTable";
import {getPostbacksByTracker} from "../../../../services/adjustHelperService";
import {Alert, Button} from "@mui/material";
import {t} from "i18next";
import {Android, Apple, RefreshOutlined} from "@mui/icons-material";
import styles from './AdjustTrackerPostbacks.module.scss';
import {formatTurkishLira} from "../../../../common/utils";
import dayjs from "dayjs";

const AdjustTrackerPostbacks = ({tracker}) => {

    const getPostbacks = async () => {
        const response = await getPostbacksByTracker(tracker);
        const data = response.data;
        if (!!data)
            setPostbacks(data);
    }
    useEffect(() => {
        getPostbacks();
    }, [tracker]);// eslint-disable-line react-hooks/exhaustive-deps


    const [postbacks, setPostbacks] = React.useState([]);
    const postbackColumns = {};
    postbackColumns.visibility = {}
    postbackColumns.data = [
        {
            accessorKey: 'id',
            header: "Id",
            size: 50
        },
        {
            accessorKey: 'tracker',
            header: "Tracker",
            size: 50
        },
        {
            accessorKey: 'goal_name',
            header: "Goal Name",
            size: 50
        },
        {
            accessorKey: 'tid',
            header: "Transaction Id",
        },
        {
            accessorKey: 'pp',
            header: "Partner Params",
        },
        {
            accessorKey: 'purchase_token',
            header: "Purchase Token",
        },
        {
            accessorKey: 'amount',
            header: "Sale Amount",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.amount)}</div>
            )
        },
        {
            accessorKey: 'os',
            header: "OS",
            Cell: (cell) => (
                <div>
                    <div>{cell.row.original.os === "android" && <Android sx={{width: 20, height: 20}}/>}</div>
                    <div>{cell.row.original.os === "ios" && <Apple sx={{width: 20, height: 20}}/>}</div>
                    <div>{cell.row.original.os !== "ios" && cell.row.original.os !== "android" && "Unknown"}</div>
                </div>
            )
        },
        {
            accessorKey: 'created_at',
            header: "Created At",
            Cell: (cell) => (
                <div>
                    {dayjs(cell.row.original.created_at, "YYYY-MM-DD+h:mm").format("YYYY-MM-DD h:mm")}
                </div>
            )
        }
    ];


    return (
        <div className={styles.container}>
            <Card title={t("integration-helper.adjust-postback-table-title", {
                tracker: tracker
            })}>
                <Button size="small"
                        color="secondary"
                        variant="outlined"
                        sx={{mb: 1.5}}
                        onClick={(e) => {
                            getPostbacks();
                        }}
                        startIcon={<RefreshOutlined/>}>{t("common-en.refresh")}</Button>
                {postbacks.length > 0 &&
                    <GenericTable
                        outputColumns={postbackColumns}
                        tableData={postbacks}
                        rowCount={postbacks.length}
                        enablePagination={true}
                    />
                }
                {postbacks.length === 0 &&
                    <Alert variant="outlined" sx={{mt: 1, justifyContent: "center"}}
                           severity="info"> {t('integration-helper.postback-no-result-msg')}</Alert>
                }
            </Card>
        </div>
    );
};

export default AdjustTrackerPostbacks;

import React, {useMemo, useState} from 'react'
import MaterialReactTable from "material-react-table";
import {useTranslation} from "react-i18next";

const GenericTable = (props) => {
    const {t} = useTranslation();
    const {
        outputColumns,
        tableData = [],
        initialState = {},
        enableColumnActions = true,
        enableTopToolbar = true,
        expand = false,
        enablePagination = tableData.length > 10,
        rowCount = tableData.length  !== 0 ? tableData.length : 10,
        state,
        enableStickyHeader = true,
        allRowsAreExpanded = false,
        enableColumnResizing = false
    } = props

    const DEFAULT_PAGE_SIZE = 10;

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const columns = useMemo(() => outputColumns.data, [outputColumns.data]);

    return (
        <MaterialReactTable
            columns={columns}
            data={tableData}
            state={{
                ...state,
                pagination
            }}
            enableStickyHeader={enableStickyHeader}
            enableStickyFooter
            enableColumnActions={enableColumnActions}
            enableTopToolbar={enableTopToolbar}
            renderDetailPanel={expand}
            initialState={{
                ...initialState,
                columnVisibility: outputColumns.visibility,
                expanded: allRowsAreExpanded,
                showGlobalFilter: true
            }}
            muiSearchTextFieldProps={{
                placeholder: tableData.length > 0 ?
                    t("generic-table.table-search-placeholder", {rowCount: tableData.length}):
                    t("generic-table.no-records-to-search"),
                sx: {minWidth: '300px'},
                variant: 'standard',
            }}
            enablePagination={enablePagination}
            onPaginationChange={setPagination}
            rowCount={rowCount}
            enableGlobalFilterModes
            positionGlobalFilter="left"
            enableGlobalFilter={true}
            enableDensityToggle={false}
            enablePinning={true}
            enableColumnResizing={enableColumnResizing}
        />
    )
}

export default GenericTable
import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import ProductPerformance from './features/ProductPerformance/ProductPerformance';
import NavbarLayout from './layouts/navbar/NavbarLayout';
import {
    ABOUT_INFLUIO,
    ADMIN_PAID_PROJECTS,
    ADVERTISER_MANAGEMENT,
    AFFILIATE_PERFORMANCE,
    AI_CHAT_ASSISTANT,
    APP_USER_LOGIN,
    ASSIGN_INFLUENCERS,
    CREATE_CAMPAIGN,
    CREATE_PAID_PROJECT,
    CREATE_PROMO_CODE,
    EDIT_PROJECT,
    HOME, INFLUENCER_CHATS, INSERT_INFLUENCERS,
    INTEGRATIONS_HELPER,
    LCW_LANDING_PAGE_URL,
    LCW_NEW_PARTNERS,
    LCW_USER_NEW_REGISTRATION,
    LOGIN,
    PURCHASE_REPORTS,
    SIGN_UP,
    STATUS_CENTER,
    USER_BANK_SETTINGS_UPDATE,
    USER_PAYMENTS,
    USER_SETTINGS_UPDATE,
    USERS_ADMIN
} from './common/constants/routes';
import Spinner from "./common/spinner/Spinner";
import {usePromiseTracker} from "react-promise-tracker";
import './common/styles/global.scss'
import Home from "./features/Home/Home";

import PrivateRoutes from "./features/login/PrivateRoutes";
import Login from "./features/login/Login";
import {CssBaseline} from "@mui/material";
import SignUp from "./features/signUp/SignUp";
import {useDispatch} from "react-redux";
import {getSession} from "./store/auth/authActions";
import AffiliatePerformances from "./features/AffiliatePerformances/AffiliatePerformances";
import UsersAdmin from "./features/UsersAdmin/UsersAdmin";
import UserSettingsUpdate from "./forms/UserSettingsUpdate/UserSettingsUpdate";
import UserBankDetailsUpdate from "./forms/UserBankDetailsUpdate/UserBankDetailsUpdate";
import LcwNewPartners from "./features/LcwNewPartners/LcwNewPartners";
import LcwLandingPage from "./features/LcwLandingPage/LcwLangingPage";
import AboutInfluio from "./features/AboutInfluio/AboutInfluio";
import LCWUserRegistration from "./forms/LCWUserRegistration/LCWUserRegistration";
import IntegrationHelper from "./features/IntegrationHelper/IntegrationHelper";
import CreatePromoCode from "./features/AdvertiserManagement/CreatePromoCode/CreatePromoCode";
import CreateCampaign from "./features/AdvertiserManagement/CreateCampaign/CreateCampaign";
import StatusCenter from "./features/AdvertiserManagement/StatusCenter/StatusCenter";
import CreateEditPaidProject from "./features/AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";
import PaidProjects from "./features/PaidProjects/PaidProjects";
import AssignInfluencers from "./features/PaidProjects/AssignInfluencers/AssignInfluencers";
import EditProject from "./features/PaidProjects/EditProject/EditProject";
import AppUserLogin from "./features/AppUserLogin/AppUserLogin";
import UserPayments from "./features/UserPayments/UserPayments";
import AiRadar from "./features/AiRadar/AiRadar";
import InfluencerChats from "./features/InfluencerChats/InfluencerChats";
import InsertInfluencers from "./features/InsertInfluencers/InsertInfluencers";

function App() {

    const {promiseInProgress} = usePromiseTracker();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSession());
    }, [dispatch])

    return (
        <>
            <CssBaseline/>
            <Routes>
                <Route element={<PrivateRoutes/>}>
                    <Route path='/' element={<NavbarLayout/>}>
                        <Route path={HOME} element={<Home/>}/>
                        <Route path={PURCHASE_REPORTS} element={<ProductPerformance/>}/>
                        <Route path={SIGN_UP} element={<SignUp/>}/>
                        <Route path={AFFILIATE_PERFORMANCE} element={<AffiliatePerformances/>}/>
                        <Route path={INTEGRATIONS_HELPER} element={<IntegrationHelper/>}/>
                        <Route path={LCW_NEW_PARTNERS} element={<LcwNewPartners/>}/>
                        <Route path={USERS_ADMIN} element={<UsersAdmin/>}/>
                        <Route path={USER_PAYMENTS} element={<UserPayments/>}/>
                        <Route path={AI_CHAT_ASSISTANT} element={<AiRadar/>}/>
                        <Route path={INSERT_INFLUENCERS} element={<InsertInfluencers/>}/>
                        <Route path={AI_CHAT_ASSISTANT+INFLUENCER_CHATS} element={<InfluencerChats/>}/>
                        <Route path={ADMIN_PAID_PROJECTS} element={<PaidProjects/>}/>
                        <Route path={ADMIN_PAID_PROJECTS + ASSIGN_INFLUENCERS} element={<AssignInfluencers/>}/>
                        <Route path={ADMIN_PAID_PROJECTS + EDIT_PROJECT} element={<EditProject/>}/>
                        <Route path={ADVERTISER_MANAGEMENT + CREATE_CAMPAIGN}
                               element={<CreateCampaign/>}/>
                        <Route path={ADVERTISER_MANAGEMENT + CREATE_PROMO_CODE}
                               element={<CreatePromoCode/>}/>
                        <Route path={ADVERTISER_MANAGEMENT + CREATE_PAID_PROJECT}
                               element={<CreateEditPaidProject/>}/>
                        <Route path={ADVERTISER_MANAGEMENT + STATUS_CENTER}
                               element={<StatusCenter/>}/>
                    </Route>
                </Route>
                <Route path={LOGIN} element={<Login/>}/>
                <Route path={APP_USER_LOGIN} element={<AppUserLogin/>}/>
                <Route path={USER_SETTINGS_UPDATE} element={<UserSettingsUpdate/>}/>
                <Route path={USER_BANK_SETTINGS_UPDATE} element={<UserBankDetailsUpdate/>}/>
                <Route path={LCW_USER_NEW_REGISTRATION} element={<LCWUserRegistration/>}/>
                <Route path={LCW_LANDING_PAGE_URL} element={<LcwLandingPage/>}/>
                <Route path={ABOUT_INFLUIO} element={<AboutInfluio/>}/>

            </Routes>
            {!!promiseInProgress && <Spinner/>}
        </>
    );
}

export default App;

import React from 'react';
import styles from './Card.module.scss';
import classNames from 'classnames';
import {useLocation} from "react-router-dom";
import {navbarItems} from "../utils";


const getIconFromUrl = (url) => {
    const menuItem = navbarItems.find(item => {
        return url.includes(item.path);
    });
    return menuItem ? menuItem.icon : null;
}

const Card = ({
                  title,
                  children,
                  customStyles = {},
                  customIcon = false,
                  hideIcon = false
              }) => {
    const location = useLocation();

    return (
        <div
            className={classNames(styles.cardContainer, {
                [customStyles.cardContainer]: customStyles.cardContainer
            })}
        >
            <div
                className={classNames(styles.topBar, {
                    [customStyles.topBar]: customStyles.topBar
                })}
            >
                <div className={styles.title}>
                    <div>{(getIconFromUrl(location.pathname) && !customIcon) && getIconFromUrl(location.pathname)}</div>
                    <div>{(!hideIcon && customIcon) && customIcon}</div>
                    {title}
                </div>
            </div>
            <div
                className={classNames(styles.cardBody, {
                    [customStyles.cardBody]: customStyles.cardBody
                })}
            >
                {children}
            </div>
        </div>
    )
}

export default Card
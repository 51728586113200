import axios from "axios";
import {trackPromise} from 'react-promise-tracker';
import {GET_TYPE, POST_TYPE} from "../common/constants/general";


export const SESSION_TOKEN = "SESSION_TOKEN";


const axiosInstance = ({
                           contentType = 'application/json',
                           isFormData = false,
                           method = GET_TYPE,
                           path,
                           params = null
                       },
) => {
    const headers = {}
    headers['Content-Type'] = !isFormData ? contentType : 'multipart/form-data';

    const fullPath = process.env.REACT_APP_DASHBOARD_API + path;

    switch (method) {
        case GET_TYPE:
            return axios.get(fullPath,
                {
                    headers: headers,
                    params: params,
                    withCredentials:true
                })
        case POST_TYPE:
            return axios.post(fullPath,
                params,
                {
                    headers: headers,
                    withCredentials:true
                })
        default:
            return null;
    }
}

const getRequestConfig = (path,
                          requestType,
                          params,
                          isFormData) => {
    const contentType = typeof params === 'string' ?
        'text/plain' :
        isFormData ?
            undefined :
            'application/json';
    return {
        contentType: contentType,
        method: requestType,
        path: path,
        params: params,
        isFormData: isFormData
    };
}

export const clientGetWrapper = (path,
                                 params = {},
                                 isFormData = false) => {
    const config = getRequestConfig(
        path,
        GET_TYPE,
        params,
        isFormData);
    return trackPromise(
        axiosInstance(config)
    )
}

export const clientPostWrapper = (path,
                                  params = {},
                                  isFormData = false) => {
    const config = getRequestConfig(
        path,
        POST_TYPE,
        params,
        isFormData);
    return trackPromise(
        axiosInstance(config)
    )
}






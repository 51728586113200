import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { LOGIN } from '../../common/constants/routes'

const PrivateRoutes = () => {

    const auth = useSelector((state) => state.auth)
    const location = useLocation()

    return auth.user ? <Outlet /> : <Navigate to={LOGIN} replace state={{ from: location.pathname + location.search }}/>
}

export default PrivateRoutes
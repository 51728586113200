import React from 'react'
import {Snackbar} from '@mui/material'
import MuiAlert from '@mui/material/Alert';

const Toast = (props) => {

    const {
        isOpen,
        onClose,
        content,
        vertical = 'top',
        horizontal = 'center',
        hideTime = 5 * 1000,
        severity = 'success'
    } = props

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <Snackbar
            anchorOrigin={{vertical, horizontal}}
            open={isOpen}
            sx={{zIndex: 595959}}
            autoHideDuration={hideTime}
            onClose={() => onClose()}>
            <Alert
                onClose={() => onClose()}
                severity={severity}
                sx={{width: '100%'}}>
                {content}
            </Alert>
        </Snackbar>
    )
}

export default Toast
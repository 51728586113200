import React from 'react';
import styles from "./PaidProjects.module.scss";
import Card from "../../common/card/Card";
import {t} from "i18next";
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import GenericTable from "../../common/table/GenericTable";
import {formatTurkishLira} from "../../common/utils";
import {Alert, Button} from "@mui/material";
import {useSelector} from "react-redux";
import {AddReactionOutlined, SettingsOutlined} from "@mui/icons-material";
import cashIcon from "../../images/ic-cash-bonus.svg";
import productGiftIcon from "../../images/ic-gift.svg";
import giftCouponIcon from "../../images/ic-coupon.svg";
import {PROJECT_TYPES} from "../AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";
import moment from "moment/moment";
import {dateFormatWithHours} from "../../utils/common";

const PaidProjects = () => {
    const projectsData = useSelector((state) => state.projectsList.projects);
    const offersList = useSelector((state) => state.offersList.offers);
    const projectsColumns = {};
    projectsColumns.visibility = {};
    projectsColumns.data = [
        {
            accessorKey: 'id',
            header: "Settings",
            Cell: (cell) => (
                <div className={styles.settingsContainer}>
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        startIcon={<SettingsOutlined/>}
                        sx={{
                            height: "30px",
                            mb:1
                        }}
                        href={`/admin-paid-projects/edit?pid=${cell.row.original.id}`}
                    >
                        Edit Project
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{
                            height: "30px"
                        }}
                        startIcon={<AddReactionOutlined/>}
                        href={`/admin-paid-projects/assign-influencers?pid=${cell.row.original.id}`}
                    >Influencers
                    </Button>
                </div>
            )
        },
        {
            accessorKey: 'offer',
            header: "Offer",
            size: 30,
            Cell: (cell) => (
                <div>
                    {offersList && offersList.length > 0 && <img src={offersList.find(offerId => offerId.id === cell.row.original.offer).logo} alt={cell.row.original.offer.title} width={100}/>}
                </div>
            )
        },
        {
            accessorKey: 'title',
            header: "Title",
            size: 50
        },
        {
            accessorKey: 'isPublished',
            header: "Is Published",
            size: 50,
            Cell: (cell) => (
                <div>
                    {cell.row.original.isPublished ? "Yes" : "No"}
                </div>
            )
        },
        {
            accessorKey: 'type',
            header: "Project Type",
            size: 50,
            Cell: (cell) => (
                <div>
                    {cell.row.original.type === PROJECT_TYPES.cash && <div className={styles.projectTypeContainer}><img src={cashIcon} alt="icon"/>Cash Bonus</div>}
                    {cell.row.original.type === PROJECT_TYPES.product_gift && <div className={styles.projectTypeContainer}><img src={productGiftIcon} alt="icon"/>Product Gift</div>}
                    {cell.row.original.type === PROJECT_TYPES.gift_coupon && <div className={styles.projectTypeContainer}><img src={giftCouponIcon} alt="icon"/>Gift Coupon</div>}
                </div>
            )
        },
        {
            accessorKey: 'status',
            header: "Status",
            Cell: (cell) => (
                <div>
                    {cell.row.original.status === "Rejected" && <div>🔴  Rejected</div>}
                    {cell.row.original.status === "Pending" && <div>🟡  Pending</div>}
                    {cell.row.original.status === "Approved" && <div>🟢  Approved</div>}
                </div>
            )
        },
        {
            accessorKey: 'amount',
            header: "Sale Amount",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.type === PROJECT_TYPES.cash ? cell.row.original.amount : 0)}</div>
            )
        },
        {
            accessorKey: 'start_date',
            header: "Start Date",
            Cell: (cell) => (
                <div>
                    {moment(cell.row.original.startDate).format(dateFormatWithHours)}
                </div>
            )
        },
        {
            accessorKey: 'end_date',
            header: "End Date",
            Cell: (cell) => (
                <div>
                    {moment(cell.row.original.endDate).format(dateFormatWithHours)}
                </div>
            )
        },
        {
            accessorKey: 'links',
            header: "Project Links",
            size: 50,
            Cell: (cell) => (
                <div className={styles.tableLinks}>
                    {JSON.parse(cell.row.original.links).map((link, index) => (
                        <div key={index}>
                            <div>{link}</div>
                        </div>
                    ))}
                </div>
            )
        },
        {
            accessorKey: 'creator',
            header: "Creator",
            size: 50
        }

    ];

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            <Card title={t("paid-projects.main-title")}>
                <div>
                    {!!projectsData && projectsData.length > 0 &&
                        <GenericTable
                            outputColumns={projectsColumns}
                            tableData={projectsData}
                            rowCount={projectsData.length}
                            enablePagination={true}
                        />
                    }
                    {projectsData.length === 0 &&
                        <Alert severity="info">{t("common.no-results")}</Alert>
                    }
                </div>
            </Card>
        </div>
    );
};

export default PaidProjects;

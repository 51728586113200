import React from 'react';
import styles from "../BrandResults/BrandResults.module.scss";
import GenericTable from "../../../common/table/GenericTable";
import ProductList from "../ProductResults/ProductList";
import {formatTurkishLira} from "../../../common/utils";

const BrandResults = ({brands}) => {
    const brandColumns = {};
    brandColumns.visibility = {}
    brandColumns.data = [
        {
            accessorKey: 'brandName',
            header: "Brand Name",
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'totalAmount',
            header: "Total amount",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.totalAmount)}</div>
            )
        }
    ];


    const expandBrands = (cell) => (<ProductList products={cell.row.original.products}/>)

    return (
        <div className={styles.products}>
            {!!brands && <GenericTable
                outputColumns={brandColumns}
                tableData={brands}
                rowCount={brands.length}
                enablePagination={true}
                allRowsAreExpanded={brandColumns.length === 1}
                expand={expandBrands}
            />}
        </div>
    );
};

export default BrandResults;

import {createSlice} from '@reduxjs/toolkit';
import {setCookie} from '../../utils/cookies';
import {getSession, login, logout} from './authActions';
import {SESSION_TOKEN} from "../../api/Api";

const initialState = {user: null, loading: false};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //login
        builder.addCase(login.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(login.fulfilled, (state, action) => {
            const payload = {
                user:action.payload,
                isMasterPassword:action.payload.advertiser_id === "-1"
            }
            state.user = payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //get user session
        builder.addCase(getSession.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getSession.fulfilled, (state, action) => {
            const payload = {
                user:action.payload,
                isMasterPassword:action.payload.advertiser_id === "-1"
            }
            state.user = payload;
            state.loading = false
            state.error = ''
        })
        builder.addCase(getSession.rejected, (state) => {
            state.loading = false
        })

        //logout
        builder.addCase(logout.pending, (state) => {
            state.loading = true
        })
        builder.addCase(logout.fulfilled, (state) => {
            console.log("state: ", state);
            state.user = null
            state.loading = false
            state.error = ''
        })
        builder.addCase(logout.rejected, (state, action) => {
            state.user = null
            state.loading = false
            state.error = ''
            setCookie(SESSION_TOKEN, '', -1);
        })
    }
})

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer
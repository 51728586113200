import React from 'react';
import IconButton from "@mui/material/IconButton";
import {DeleteOutlineOutlined} from "@mui/icons-material";
import styles from "./LinksMultipleChoices.module.scss";
import {formatTwoDatesRange, getFormattedIncrementalDate} from "../../../../utils/common";
import {CalendarIcon} from "@mui/x-date-pickers";

const LinksMultipleChoices = ({startDate, links, removeLink, endDate}) => {



    return (
        <div className={styles.container}>
            <div className={styles.linksHolder}>
                {!!links && links.map((link, index) => (
                    <div key={`${index}-holder`} className={styles.linkItself}>
                        <div className={styles.iconHolder}>
                            <CalendarIcon sx={{width: 15, height: 15}}/>
                            <div key={`${index}-key`} className={styles.topHolder}>
                                <div
                                    className={styles.dateItself}>{links.length !== 1 ? getFormattedIncrementalDate(index, startDate) : formatTwoDatesRange(0, startDate, endDate)}</div>
                                <div
                                    className={styles.linksDayTitle}>{links.length !== 1 ? `Day ${index + 1}` : 'Whole Project'}</div>
                            </div>
                        </div>
                        <div className={styles.linkText} key={index}>{link}</div>
                        <IconButton
                            sx={{
                                opacity: links.length === 1 ? 0.5 : 1
                            }}
                            color="primary"
                            onClick={e => removeLink(link)}><DeleteOutlineOutlined/>
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LinksMultipleChoices;

import React, {useEffect} from 'react';
import styles from "./LinkGenerator.module.scss";
import {Alert, Button, TextField} from "@mui/material";
import {DeleteOutlineOutlined, LinkOutlined} from "@mui/icons-material";
import {t} from "i18next";
import {domainRegex} from "../../../../common/constants/constService";
import IconButton from "@mui/material/IconButton";
import {formatTwoDatesRange, getDaysDiffBetweenTwoDates, getFormattedIncrementalDate} from "../../../../utils/common";
import {CalendarIcon} from "@mui/x-date-pickers";

const LinkGenerator = ({isEdit, setExistLinks, existLinks, startDate, endDate}) => {
    const [links, setLinks] = React.useState([]);
    const [maxDayCount, setMaxDayCount] = React.useState(0);
    const getFormattedExistLinks = () => (typeof existLinks === 'string') ?
        JSON.parse(existLinks) :
        existLinks;

    useEffect(() => {
        if (startDate && endDate)
            setMaxDayCount(getDaysDiffBetweenTwoDates(startDate, endDate) + 1);
        if (!isEdit) {
            setLinks([{
                name: "Whole Project",
                value: '',
                isLinkValid: true
            }]);
        } else {
            if (existLinks.length === 1) {
                setLinks([{
                    name: "Whole Project",
                    value: existLinks[0],
                    isLinkValid: true
                }]);
            } else {
                setLinks(getFormattedExistLinks().map((link, index) => {
                    return {
                        name: `Day ${index + 1}`,
                        value: link,
                        isLinkValid: true
                    }
                }));
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setExistLinks([...links.map(link => link.value)]);
    }, [links]); // eslint-disable-line react-hooks/exhaustive-deps


    const handeLinkChange = (e, index) => {
        const {value} = e.target;
        const copyArr = [...links];
        const isLinkValid = checkValidations(e.target);
        copyArr[index].value = value;
        copyArr[index].isLinkValid = isLinkValid;
        setLinks(prevState => [...copyArr]);
    }

    const checkValidations = ({name, value}) => {
        switch (name) {
            case 'links':
                return domainRegex.test(value);
            default:
                break;
        }
    }

    const addNewLinkRow = (e, index) => {
        const copyArr = [...links];
        if (links.length === 1) {
            copyArr[0].name = 'Day 1';
        }
        copyArr.push({
            name: `Day ${index + 2}`,
            value: '',
            isLinkValid: true
        })
        setLinks(prevState => [...copyArr]);
    }
    const deleteRow = (index) => {
        if (links.length === 1) return;
        const copyArr = [...links];
        copyArr.splice(index, 1);
        const newLinksData = copyArr.map((link, i) => {
            return {
                name: (copyArr.length === 1) ? 'Whole Project' : `Day ${i + 1}`,
                value: link.value,
                isLinkValid: link.isLinkValid
            }
        });
        setLinks(prevState => [...newLinksData]);
    }


    return (
        <div className={styles.container}>
            <div className={styles.mainTitle}>{t("paid-project.project-links", {
                maxDayCount: maxDayCount.toString(),
                dates: formatTwoDatesRange(0, startDate, endDate)
            })}</div>
            {
                links.map((link, index) => {
                    return (
                        <div key={index} className={styles.row}>
                            <div key={index} className={styles.urlRow}>
                                <div className={styles.titleHolder}>
                                    <div className={styles.dayHolder}>
                                        <CalendarIcon sx={{
                                            width: '15px',
                                            height: '15px'
                                        }}/>
                                        <div className={styles.dayTitle}>{link.name}</div>
                                    </div>
                                    <div>{(links.length === 1) ? formatTwoDatesRange(index, startDate, endDate) : getFormattedIncrementalDate(index, startDate)}</div>
                                </div>
                                <div className={styles.textArea}>
                                    <TextField
                                        size="small"
                                        onChange={e => handeLinkChange(e, index)}
                                        sx={{width: '500px'}}
                                        value={link.value}
                                        name="links"
                                        error={!link.isLinkValid}
                                    ></TextField>
                                    {!link.isLinkValid &&
                                        <Alert size={'small'} severity="error">{t('common.link-is-not-valid')}</Alert>}
                                </div>
                                <IconButton
                                    disabled={links.length === 1}
                                    color="error"
                                    onClick={e => deleteRow(index)}>
                                    <DeleteOutlineOutlined/>
                                </IconButton>
                            </div>
                            <div className={styles.addButton}>
                                {(index === links.length - 1) &&
                                    <Button variant="contained"
                                            disabled={!link.isLinkValid || link.value === '' || links.length === maxDayCount}
                                            startIcon={<LinkOutlined/>}
                                            onClick={e => addNewLinkRow(e, index)}
                                            color="confirmButton"
                                            size="small">{t('link-generator.add-new-link')}</Button>
                                }
                            </div>

                        </div>
                    )
                })
            }
        </div>
    );
};

export default LinkGenerator;

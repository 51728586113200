import React from 'react';
import Card from "../../../../common/card/Card";
import styles from './AdjustClickUrlResult.module.scss';
import {Button} from "@mui/material";
import {ContentCopyOutlined} from "@mui/icons-material";
import Toast from "../../../../common/toast/Toast";
import useToast from "../../../../common/hooks/useToast";
import IphoneUI from "../../../../common/iphoneUI/IphoneUI";
import {t} from "i18next";
import QRCode from "react-qr-code";

const AdjustClickUrlResult = ({clickUrl}) => {
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    return (
        <div className={styles.container}>
            <Card title={t("integration-helper.adjust-final-click-url")}>
                <div className={styles.contentHolder}>
                <div className={styles.codePart}>
                    <div className={styles.copyButton}>
                    <Button size="small"
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                navigator.clipboard.writeText(clickUrl);
                                setToastContent(t("integration-helper.copy-clipboard-success-msg"));
                                toggleToast();
                            }}
                            startIcon={<ContentCopyOutlined/>}>Copy</Button>
                    </div>
                    <div>
                        {clickUrl}
                    </div>
                </div>
                    <IphoneUI>
                        {/*<div className={styles.qrCode} id="qr-code"/>*/}
                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                            <div className={styles.qrItself}>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={clickUrl}
                                viewBox={`0 0 256 256`}
                            />
                            </div>
                        </div>
                    </IphoneUI>
                </div>
            </Card>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default AdjustClickUrlResult;

import { useDispatch } from "react-redux";
import { logout } from "../../store/auth/authActions";

const useLogout = () => {
    const dispatch = useDispatch()

    return {
        logout: () => dispatch(logout())
    }
}

export default useLogout
import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Card from "../../../common/card/Card";
import {useSelector} from "react-redux";
import {getAdvertiserPendingAssets} from "../../../services/statusCenterService";
import GenericTable from "../../../common/table/GenericTable";
import dayjs from "dayjs";
import {NotificationsActiveOutlined} from "@mui/icons-material";
import BreadcrumbsBar from "../../../layouts/breadcrumb/BreadcrumbsBar";
import {Alert} from "@mui/material";

const StatusCenter = () => {
    const userData = useSelector((state) => state.auth.user ?? state.auth.user.user);
    const offersList = useSelector((state) => state.offersList.offers);
    const {t} = useTranslation();
    const [pendingAssets, setPendingAssets] = React.useState([]);

    const getPendingAssets = async () => {
        const response = await getAdvertiserPendingAssets();
        setPendingAssets(response.data);
    }
    useEffect(() => {
        getPendingAssets().then(e => {

        });
    }, [userData]);
    const brandColumns = {};
    brandColumns.visibility = {}
    brandColumns.data = [
        {
            accessorKey: 'type',
            header: "Type",
            Cell: (cell) => (
                <div>
                    {cell.row.original.type.toUpperCase()}
                </div>
            )
        },
        {
            accessorKey: 'title',
            header: "Title",
        },
        {
            accessorKey: 'offerId',
            header: "Offer Name",
            Cell: (cell) => (
                <div>
                    {cell.row.original.offerId.length > 10 && offersList.find(offer => offer.id === cell.row.original.offerId)?.name}
                    {cell.row.original.offerId.length < 4 && offersList.find(offer => offer.offerId === cell.row.original.offerId)?.name}
                </div>
            )
        },
        {
            accessorKey: 'value',
            header: "Text",
        },
        {
            accessorKey: 'startDate',
            header: "Start Date",
            Cell: (cell) => (
                <div>
                    {dayjs(cell.row.original.startDate, "YYYY-MM-DD+h:mm").format("YYYY-MM-DD h:mm")}
                </div>
            )
        },
        {
            accessorKey: 'endDate',
            header: "End Date",
            Cell: (cell) => (
                <div>
                    {dayjs(cell.row.original.endDate, "YYYY-MM-DD+h:mm").format("YYYY-MM-DD h:mm")}
                </div>
            )
        },
        {
            accessorKey: 'status',
            header: "Status",
            Cell: (cell) => (
                <div>
                    {cell.row.original.status === "Declined" && <div>🔴  Declined</div>}
                    {cell.row.original.status === "Pending" && <div>🟡  Pending</div>}
                    {cell.row.original.status === "Approved" && <div>🟢  Approved</div>}
                </div>
            )
        }
    ];
    return (
        <div>
            <BreadcrumbsBar/>
            <Card title={t("menu-item.status-center")}
                  customIcon={<NotificationsActiveOutlined/>}>
                {offersList.length > 0 && pendingAssets && pendingAssets.length > 0 &&
                    <GenericTable
                        outputColumns={brandColumns}
                        tableData={pendingAssets}
                        rowCount={pendingAssets.length}
                        enablePagination={true}
                    />
                }
                {pendingAssets.length === 0 &&   <Alert severity="info"
                                                        sx={{display: "flex", justifyContent: "center"}}>{t("common.no-results")}</Alert>}
            </Card>
        </div>
    );
};

export default StatusCenter;

import React, {useEffect, useState} from 'react';
import BreadcrumbsBar from "../../../layouts/breadcrumb/BreadcrumbsBar";
import Card from "../../../common/card/Card";
import {Alert, Autocomplete, Button, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {domainRegex, FIRST_REPORT_DATE} from "../../../common/constants/constService";
import {DATE_TYPES, startEndDateInitial} from "../../../common/utils";
import {useSelector} from "react-redux";
import styles from "./CreatePromoCode.module.scss";
import moment from "moment/moment";
import {createPromoCode} from "../../../services/createPromoCodeService";
import Toast from "../../../common/toast/Toast";
import useToast from "../../../common/hooks/useToast";
import {DROPDOWN_SELECT_ALL_VALUE} from "../../../common/constants/general";
import {DiscountOutlined} from "@mui/icons-material";
import {dateFormatWithHours} from "../../../utils/common";

const initialFormData = {
    title: "",
    link: "",
    value: "",
    startDate: "",
    endDate: "",
    status: "Pending",
    offer: null
}


const CreatePromoCode = () => {
    const [formData, setFormData] = React.useState(initialFormData);
    const {t} = useTranslation();
    const [startEndDateValue, setStartEndDateValue] = React.useState(startEndDateInitial);
    const offersList = useSelector((state) => state.offersList.offers);
    const userData = useSelector((state) => state.auth.user || state.auth.user.user);
    const [offers, setOffers] = React.useState([]);
    const [selectedOfferId, setSelectedOfferId] = useState("");
    const [isLinkValid, setIsLinkValid] = useState(true);
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    useEffect(() => {
        if (userData.user.offer_id_list === DROPDOWN_SELECT_ALL_VALUE) {
            setOffers(offersList)
        } else {
            const selectedOffers = offersList.filter((offer) => userData.user.offer_id_list.split(",").includes(String(offer.offerId)));
            setOffers(selectedOffers);
        }
    }, [offersList]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        checkValidations(e.target);
        setFormData({...formData, [name]: value});
    }

    const handleStartDateChange = (newValue, type) => {
        if (!newValue) return;
        if (type === DATE_TYPES.START_DATE) {
            setStartEndDateValue(prevState => ({
                ...prevState,
                startDate: newValue
            }));
        } else {
            setStartEndDateValue(prevState => ({
                ...prevState,
                endDate: newValue
            }));
        }
    };
    const getOfferValue = () => {
        const selected = selectedOfferId ? offers.find(offer => offer.offerId === selectedOfferId) : null;
        return (!!selected) ? selected.offerId + ' - ' + selected.name : "Select Offer";
    };

    const checkValidations = ({name, value}) => {
        switch (name) {
            case 'link':
                setIsLinkValid(value.length !== 0 ? domainRegex.test(value) : true);
                break;
            default:
                break;
        }
    }

    const handleOfferDropdownChange = async (e) => {
        const {textContent} = e.target;
        setSelectedOfferId(Number(textContent.split(" - ")[0]));
    }

    const updateHandler = async () => {

        formData.offer = offersList.find(offer => offer.offerId === Number(selectedOfferId)).id;
        formData.offerName = offersList.find(offer => offer.offerId === Number(selectedOfferId)).name;
        formData.startDate = moment(startEndDateValue.startDate.$d).utc(false).format(dateFormatWithHours);
        formData.endDate = moment(startEndDateValue.endDate.$d).utc(false).format(dateFormatWithHours);
        const response = await createPromoCode(formData);
        if(!!response.data.offer){
            setToastContent(t('advertiser-management.promo-code-created-successfully'));
            setFormData(initialFormData);
            setStartEndDateValue(startEndDateInitial);
            setSelectedOfferId(null);
        }else{
            setToastContent(t("common-server-err-msg"));
        }
        toggleToast();
    };

    const isButtonDisabled = () => {
        return !startEndDateValue.startDate ||
            !startEndDateValue.endDate ||
            !formData.title ||
            !formData.value ||
            !formData.link ||
            !selectedOfferId ||
            !isLinkValid;
    };

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            <Card title={t("menu-item.create-promo-code")}
                  customIcon={<DiscountOutlined/>}>
                <div className={styles.formContainer}>
                    <div className={styles.filters}>
                        {offers && offers.length > 0 &&
                            <Autocomplete
                                name="Offers"
                                freeSolo
                                required
                                value={getOfferValue()}
                                options={offers.map(offer => offer.offerId + " - " + offer.name)}
                                onChange={handleOfferDropdownChange}
                                sx={{
                                    width: 220,
                                    marginTop: 1
                                }}
                                renderInput={(params) => <TextField {...params}
                                                                    label={t('create-new-user.select-offer')}/>}/>
                        }
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DateTimePicker label={t("common.startDate")}
                                            minDate={dayjs(FIRST_REPORT_DATE)}
                                            defaultValue={dayjs(new Date())}
                                            onChange={(newValue) =>
                                                handleStartDateChange(newValue, DATE_TYPES.START_DATE)}/>
                                <DateTimePicker label={t("common.endDate")}
                                            minDate={dayjs(startEndDateValue.startDate)}
                                            onChange={(newValue) =>
                                                handleStartDateChange(newValue, DATE_TYPES.END_DATE)}/>
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                    <TextField
                        id="outlined-basic"
                        name="title"
                        label={t("common.title")}
                        value={formData.title}
                        variant="outlined"
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="value"
                        label={t("common.value")}
                        value={formData.value}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="link"
                        label={t("common.link")}
                        value={formData.link}
                        variant="outlined"
                        onChange={handleInputChange}
                    />
                    {!isLinkValid &&
                        <Alert variant="outlined" sx={{justifyContent: "center",mt:-1}}
                               severity="error"> {t('common.link-is-not-valid')}</Alert>}
                    <Button
                        sx={{
                            width: 'fit-content'
                        }}
                        variant='contained'
                        color="success"
                        disabled={isButtonDisabled()}
                        onClick={updateHandler}
                    >
                        {t("menu-item.create-promo-code")}
                    </Button>
                </div>
            </Card>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default CreatePromoCode;

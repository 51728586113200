import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import styles from './SignUp.module.scss'
import {
    Alert,
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {LOGIN} from '../../common/constants/routes'
import Card from "../../common/card/Card";
import {createNewUser} from "../../services/signUpHandler";
import useToast from "../../common/hooks/useToast";
import Toast from "../../common/toast/Toast";
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import {MenuProps} from "../../common/dropdownSetup/DropdownSetup";
import {emailRegex} from "../../common/constants/constService";


const SignUp = () => {
    const {t} = useTranslation();
    const errorMessage = useSelector(state => state.auth.error);
    const advertisers = useSelector(state => state.advertisersList.advertisers);
    const offers = useSelector(state => state.offersList.offers);
    const [user, setUser] = useState({})
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [selectedAdvertiserId, setSelectedAdvertiserId] = useState(null);
    const [selectedOfferIds, setSelectedOfferIds] = useState([]);

    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const signUpHandler = async () => {
        const response = await createNewUser(user);

        function resetForm() {
            setUser({});
            setSelectedAdvertiserId(null);
            setSelectedOfferIds([]);
        }

        if (response && response.data) {
            if (response.data.error) {
                setToastContent(response.data.error);
                toggleToast();
            } else {
                setToastContent("User successfully created!");
                toggleToast();
                resetForm();
            }
        }
    }

    const checkValidations = ({name, value}) => {
        switch (name) {
            case 'email':
                setIsEmailValid(value.length !== 0 ? emailRegex.test(value) : true);
                break;
            default:
                break;
        }
    }

    const changeHandler = (target) => {
        const {name, value} = target;
        checkValidations(target);
        setUser((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }


    const handleAdvertiserDropdownChange = (e) => {
        if (!e.target.textContent) return;
        const advId = Number(e.target.textContent.split(" - ")[0]);
        if (advId === -1) {
            setSelectedOfferIds(["-1"]);
            setUser((prevState) => ({
                ...prevState,
                advertiser_id: advId,
                offer_id_list: "-1"
            }));
        } else {
            setUser((prevState) => ({
                ...prevState,
                advertiser_id: advId
            }));
        }
        setSelectedAdvertiserId(advId);
        console.log(selectedOfferIds);
    };
    const getAdvertiserName = () => {
        const advertiser = advertisers.find(advertiser => advertiser.advertiser_id === selectedAdvertiserId);
        return (!!advertiser) ?
            advertiser.advertiser_id + ' - ' + advertiser.advertiser_name :
            "Select Advertiser";
    };
    const handleOffersDropdownChange = (e) => {
        const selectedIds = e.target.value;
        setSelectedOfferIds(selectedIds);
        setUser((prevState) => ({
            ...prevState,
            offer_id_list: selectedIds.join(",")
        }));
    };

    const getSelectedName = (selectedIds) => {
        let names = [];
        selectedIds.forEach(id => {
            const selectedEvent = offers.find(offer => offer.offerId === id)
            if (!!selectedEvent) names.push(selectedEvent.name);
        })
        return String(names);
    }

    const isCreateUserBtnDisabled = () => {
        return !user || !user.username || !user.email || !user.password || !user.advertiser_id || !user.offer_id_list || !isEmailValid;
    };

    return (
        <div className={styles.container} data-testid={LOGIN}>
            <BreadcrumbsBar/>
            <div className={styles.loginBox}>
                <Card title={t("menu-item.create-new-user")}>
                    <div className={styles.body}>
                        <TextField
                            className={styles.inputField}
                            id="name"
                            name="username"
                            label={t("common.username-label")}
                            variant="outlined"
                            onChange={(e) => changeHandler(e.target)}
                            required
                            autoFocus
                        />


                        <TextField
                            className={styles.inputField}
                            id="email"
                            name="email"
                            label={t("common.email")}
                            variant="outlined"
                            onChange={(e) => changeHandler(e.target)}
                            required
                            autoFocus
                        />
                        {!isEmailValid &&
                            <Alert sx={{mt: 1}} severity="error">{t('create-new-user.invalid-email-msg')}</Alert>}
                        <TextField
                            className={styles.inputField}
                            id="password"
                            name="password"
                            label={t("common.password-label")}
                            type="password"
                            variant="outlined"
                            onChange={(e) => changeHandler(e.target)}
                            required
                            autoComplete="current-password"
                        />
                        {!!advertisers && <Autocomplete
                            id="advertisers"
                            name="Advertisers"
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            required
                            value={getAdvertiserName()}
                            options={advertisers.map(advertiser => advertiser.advertiser_id + " - " + advertiser.advertiser_name)}
                            onChange={handleAdvertiserDropdownChange}
                            renderInput={(params) => <TextField {...params}
                                                                label={"Advertisers"}/>}
                        />}
                        <FormControl>
                            <InputLabel
                                id="demo-multiple-checkbox-label">{t('create-new-user.select-offers')}</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                disabled={user.advertiser_id === -1}
                                value={selectedOfferIds}
                                onChange={(e) => handleOffersDropdownChange(e)}
                                input={<OutlinedInput label={t('create-new-user.offers')}/>}
                                renderValue={(selected) => getSelectedName(selected)}
                                MenuProps={MenuProps}
                            >

                                {offers.map((offer) => (
                                    <MenuItem
                                        key={offer.offerId}
                                        value={offer.offerId}
                                    >
                                        <Checkbox
                                            checked={selectedOfferIds.indexOf(offer.offerId) > -1}/>
                                        <ListItemText primary={offer.offerId + " - " + offer.name}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errorMessage && <Alert className={styles.errorMessage}
                                                severity="error">{t("login.incorrect-credentials")}</Alert>}
                        <Button
                            variant='contained'
                            onClick={signUpHandler}
                            sx={{width: "fit-content"}}
                            disabled={isCreateUserBtnDisabled()}
                            color="confirmButton"
                        >
                            {t("menu-item.create-new-user")}
                        </Button>
                    </div>
                </Card>

            </div>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
            />
        </div>

    )
}

export default SignUp
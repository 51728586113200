import React from 'react';
import {Button, TextField} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import moment from "moment/moment";
import {dateFormatWithHours} from "../../../utils/common";
import styles from './AddNewTuneUser.module.scss';

const initialFormData = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    hasoffersid: '',
    creationDate: moment(new Date()).utc(false).format(dateFormatWithHours),
    company: '',
    referral_code: '',
    categories: [],
    type: 'Affiliate',
    registration_source: 'Influio BackOffice',
    website: '',
    hs_language: 'tr'
}
const AddNewTuneUser = ({addUserCb}) => {
    const [formData, setFormData] = React.useState(initialFormData);

    const addNewUser = () => {
        addUserCb(formData);
        setFormData(initialFormData);
    }
    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <TextField
                    id="hasoffersid"
                    value={formData.hasoffersid}
                    type={"number"}
                    sx={{width: '20%'}}
                    label="HasoffersId"
                    onChange={(e) => setFormData({...formData, hasoffersid: Number(e.target.value)})}
                    variant="outlined"/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']} sx={{mt: -1}}>
                        <DatePicker label="Creation Date"

                                    onChange={(newValue) => setFormData({
                                        ...formData,
                                        creationDate: moment(newValue.$d).utc(false).format(dateFormatWithHours)
                                    })}/>
                    </DemoContainer>
                </LocalizationProvider>

            </div>
            <div className={styles.block}>
                <TextField
                    id="firstname"
                    value={formData.firstname}
                    label="First Name"
                    sx={{width: '20%'}}
                    onChange={(e) => setFormData({...formData, firstname: e.target.value})}
                    variant="outlined"/>
                <TextField
                    id="lastname"
                    value={formData.lastname}
                    sx={{width: '20%'}}
                    label="Last Name"
                    onChange={(e) => setFormData({...formData, lastname: e.target.value})}
                    variant="outlined"/>
            </div>
            <div className={styles.block}>
                <TextField
                    id="email"
                    value={formData.email}
                    label="Email"
                    sx={{width: '20%'}}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    variant="outlined"/>
                <TextField
                    id="phone"
                    value={formData.phone}
                    label="Phone"
                    sx={{width: '20%'}}
                    onChange={(e) => setFormData({...formData, phone: e.target.value})}
                    variant="outlined"/>

            </div>
            <Button
                variant="contained"
                color="primary"
                disabled={!(formData.firstname && formData.lastname && formData.email && formData.phone && formData.hasoffersid)}
                onClick={addNewUser}>Add User</Button>
        </div>
    );
};

export default AddNewTuneUser;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllOffers} from "../../services/offers";

const initialState = {offers: []};

// First, create the thunk
export const fetchOffers = createAsyncThunk(
    'get/offers',
    async () => {
        const response = await getAllOffers();
        return response.data;
    }
)

export const offerSlice = createSlice({
    name: "offers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOffers.fulfilled, (state, action) => {
            const sortedOffers = action.payload.sort((a, b) => a.offerId > b.offerId ? 1 : -1);
            state.offers = sortedOffers;
        })
    }
})

export const offerReducer = offerSlice.reducer
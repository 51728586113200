import React from 'react';
import styles from "./ProductList.module.scss";
import {Avatar, Button} from "@mui/material";
import GenericTable from "../../../common/table/GenericTable";
import {LinkOutlined} from "@mui/icons-material";
import {formatTurkishLira} from "../../../common/utils";


const ProductList = ({products, isGrouped = false}) => {

    const productColumns = {};
    productColumns.visibility = {}
    productColumns.data = [
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'productInfo.client_product_id',
            header: "Product ID",
        },
        {
            accessorKey: 'productInfo.image',
            header: "Product Image",
            Cell: (cell) => (
                <Avatar sx={{width: 100, height: 100}} alt={cell.row.original.productInfo.image}
                        src={cell.row.original.productInfo.image}/>
            )
        },
        {
            accessorKey: 'productInfo.title',
            header: "Product Title",
        },
        {
            accessorKey: 'productInfo.category_id',
            header: "Category Id",
        },
        {
            accessorKey: 'productInfo.category_name',
            header: "Category Name",
        },
        {
            accessorKey: 'productInfo.price',
            header: "Price",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.productInfo.price)}</div>
            )
        },
        {
            accessorKey: 'productInfo.link',
            header: "Link",
            Cell: (cell) => (
                <Button
                    variant="contained"
                    sx={{mb: 1}}
                    startIcon={<LinkOutlined/>}
                    onClick={(e) => {
                        handleLinkClick(cell.row.original.productInfo.link);
                    }}>
                    NEW TAB
                </Button>
            )
        }
    ];


    const handleLinkClick = (link) => {
        if (link.includes("lcw")) {
            window.open(link, '_blank')
        } else {
            const modifiedLink = `http://www.karaca.com/${link}`;
            window.open(modifiedLink, '_blank')
        }
    };

    const productGroupedColumns = {};
    productGroupedColumns.visibility = {}
    productGroupedColumns.data = [
        {
            accessorKey: 'quantity',
            header: "Quantity",
        },
        {
            accessorKey: 'products.productInfo.product_id',
            header: "Product ID",
        },
        {
            accessorKey: 'products.productInfo.image',
            header: "Product Image",
            Cell: (cell) => (
                <Avatar sx={{width: 100, height: 100}} alt={cell.row.original.products.productInfo?.image ?? ""}
                        src={cell.row.original.products.productInfo?.image ?? ""}/>
            )
        },
        {
            accessorKey: 'products.productInfo.title',
            header: "Product Title",
        },
        {
            accessorKey: 'products.productInfo.category_id',
            header: "Category Id",
        },
        {
            accessorKey: 'products.productInfo.category_name',
            header: "Category Name",
        },
        {
            accessorKey: 'products.productInfo.price',
            header: "Price",
            Cell: (cell) => (
                <div>{formatTurkishLira.format(cell.row.original.products.productInfo.price)}</div>
            )
        },
        {
            accessorKey: 'products.productInfo.link',
            header: "Link",
            Cell: (cell) => (
                <Button
                    variant="contained"
                    sx={{mb: 1}}
                    startIcon={<LinkOutlined/>}
                    onClick={(e) => {
                        handleLinkClick(cell.row.original.products.productInfo.link)
                    }}>
                    NEW TAB
                </Button>
            )
        }
    ];



    return (
        <div className={styles.products}>
            {!!products && products.length > 0 &&
                <GenericTable
                    outputColumns={isGrouped ? productGroupedColumns : productColumns}
                    tableData={products}
                    rowCount={products.length}
                    enablePagination={true}
                />
            }
        </div>
    );
};

export default ProductList;

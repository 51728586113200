import React, {useEffect, useState} from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import {useTranslation} from "react-i18next";
import Card from "../../common/card/Card";
import {getAllAffiliates, getSelectedAffiliatePerformance} from "../../services/affiliatesService";
import {Alert, Autocomplete, Button, TextField} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {FIRST_REPORT_DATE} from "../../common/constants/constService";
import {DATE_TYPES, startEndDateInitial} from "../../common/utils";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import styles from "./AffiliatePerformances.module.scss";
import AffiliatePerformanceTable from "./AffilatePerformanceTable";
import moment from "moment/moment";
import Modal from "../../common/modal/Modal";
import useModal from "../../common/hooks/useModal";

const AffiliatePerformances = () => {
    const {t} = useTranslation();
    const [affiliates, setAffiliates] = useState([]);
    const [affiliatesPerformance, setAffiliatesPerformance] = useState([]);
    const [startEndDateValue, setStartEndDateValue] = React.useState(startEndDateInitial);
    const [selectedAffiliate, setSelectedAffiliate] = useState("");
    const [selectedAffiliateId, setSelectedAffiliateId] = useState(-1);
    const [isSearchIsDone, setIsSearchIsDone] = useState(false);
    const {isModalOpen, toggleModal, modalContent, setModalContent} = useModal();
    const getAllAffiliatesForDropdown = async () => {
        const response = await getAllAffiliates();
        console.log(response);
        if (!!response.data && response.data.affiliates.length > 0) {
            setAffiliates(response.data.affiliates);
        }
    }

    const handleStartDateChange = (newValue, type) => {
        if (!newValue) return;
        if (type === DATE_TYPES.START_DATE) {
            setStartEndDateValue(prevState => ({
                ...prevState,
                startDate: newValue
            }));
        } else {
            setStartEndDateValue(prevState => ({
                ...prevState,
                endDate: newValue
            }));
        }
    };

    useEffect(() => {
        getAllAffiliatesForDropdown();
    }, []);

    const handleAffiliateDropdownChange = async (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        const affId = Number(textContent.split("-")[0].trim());
        setSelectedAffiliateId(affId);
        const affName = textContent.split("-")[1].trim();
        setSelectedAffiliate(affName);
    }
    const isButtonDisabled = () => (!startEndDateValue.endDate || !startEndDateValue.startDate || !selectedAffiliate);

    const handleGetConversions = async (e) => {
        if (!startEndDateValue || !startEndDateValue.startDate || !startEndDateValue.endDate) return;
        const date1 = new Date(startEndDateValue.startDate.$d);
        const date2 = new Date(startEndDateValue.endDate.$d);
        const diffInDays = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
        if (diffInDays >= 30) {
            setModalContent({
                title: t('common.failed'),
                content: t('affiliates-max-date-range-exceed')
            })
            toggleModal();
            return;
        }
        const startDate = moment(startEndDateValue.startDate.$d).format('YYYY-MM-DD');
        let endDate = moment(startEndDateValue.endDate.$d).format('YYYY-MM-DD');
        if (startDate === endDate) {
            endDate = moment(startDate).add(+1, "days").format('YYYY-MM-DD');
        }
        const affResponse = await getSelectedAffiliatePerformance(selectedAffiliateId, startDate, endDate);
        if (!!affResponse.data) {
            setAffiliatesPerformance(affResponse.data.conversions);
        }
        setIsSearchIsDone(true);

    }
    return (
        <div>
            <BreadcrumbsBar/>
            <div>
                {affiliates.length > 0 && <Card title={t("menu-item.affiliates-performances")}>
                    <div className={styles.container}>
                        <Autocomplete
                            name="Affiliates"
                            freeSolo

                            required
                            value={affiliates.find(aff => aff.affiliate_name === selectedAffiliate).affiliate_name}
                            options={affiliates.map(aff => aff.affiliate_id + " - " + aff.affiliate_name)}
                            onChange={handleAffiliateDropdownChange}
                            sx={{width: 400, mt: 1.1}}
                            renderInput={(params) => <TextField {...params}
                                                                label={t('affiliates-performances.select-affiliate')}/>}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Start Date"
                                            minDate={dayjs(FIRST_REPORT_DATE)}
                                            defaultValue={dayjs(new Date())}
                                            onChange={(newValue) =>
                                                handleStartDateChange(newValue, DATE_TYPES.START_DATE)}/>
                                <DatePicker label="End Date"
                                            disableFuture
                                            minDate={dayjs(startEndDateValue.startDate)}
                                            onChange={(newValue) =>
                                                handleStartDateChange(newValue, DATE_TYPES.END_DATE)}/>
                            </DemoContainer>
                        </LocalizationProvider>
                        <Button
                            disabled={isButtonDisabled()}
                            variant="contained"
                            color="confirmButton"
                            sx={{
                                mt: 1
                            }}
                            onClick={handleGetConversions}>GO!</Button>
                    </div>
                </Card>}
                {!!affiliatesPerformance && affiliatesPerformance.length > 0 &&
                    <Card title={selectedAffiliate}>
                        <AffiliatePerformanceTable tableData={affiliatesPerformance}/>
                    </Card>
                }{!!isSearchIsDone && affiliatesPerformance.length === 0 &&
                <Alert severity="info"
                       sx={{display: "flex", justifyContent: "center"}}>{t("common.no-results")}</Alert>}
            </div>
            <Modal
                isOpen={isModalOpen}
                onCancelation={toggleModal}
                title={modalContent.title}
                content={modalContent.content}
            />
        </div>
    );
};

export default AffiliatePerformances;


// fastfinder
export const TYPE_USERS = 'USERS'
export const TYPE_CAMPAIGNS = 'CAMPAIGNS'
export const TYPE_WEBSITE_ID = 'WEBSITE_ID'
export const TYPE_WIDGET_ID = 'WIDGET_ID'
export const TYPE_DOMAIN_NAME = 'DOMAIN_NAME'
export const TYPE_PUBLISHER_ID = 'PUBLISHER_ID'

// fastfinder => edit-user
export const ALLOW_APPROVE_ADS = 'AllowApproveAds'

//edit-mail-contacts
export const NewUserRegistration = 'NewUserRegistration';
export const NewWidgetCreation = 'NewWidgetCreation';
export const AdvancedBlockingToggled = 'AdvancedBlockingToggled';

//common
export const DROPDOWN_SELECT_ALL_VALUE = "-1";
export const VALID_ENDPOINT_SUCCESS_MSG = 1
export const VALID_ENDPOINT_SUCCESS_MSG_STRING = "1"
export const CAMPAIGN_ON_GOING_DATE = "2100-10-10";


//new-white-label
export const IS_BROKER = 'is-broker';
export const IS_OVERRIDE_EXISTING_BROKER_FEE = 'is-override-existing-broker-fee';
export const IS_OVERRIDE_EXISTING_CAMPAIGN = 'is-override-existing-campaign';
export const IS_OVERRIDE_EXISTING_WEBSITE_REVENUE_SHARE = 'is-override-existing-website-revenue-share';
export const WILL_HAVE_PUBLISHERS = 'will-have-publishers';

//create-new-user
export const DEFAULT_ADMIN_NETWORK_ID = 35;
export const ALLOWED_IMAGES_EXTENSIONS = ".jpg, .jpeg, .png, .svg"

// campaign-posts
export const PENDING = 'PENDING'
export const DENIED = 'DENIED'
export const APPROVED = 'APPROVED'
export const DELETED = 'DELETED'
export const PAUSED = 'PAUSED'

//REQUEST TYPES
export const GET_TYPE = 'get'
export const POST_TYPE = 'post'
import {createAsyncThunk} from "@reduxjs/toolkit";
import {NONE} from "../../common/constants/errors";
import {getUserSession, loginUser, logoutUser} from "../../services/auth";

export const login = createAsyncThunk('/login', async (credentials, thunkAPI) => {

    try {
        const response = await loginUser(credentials);
        const user = response.data.user;
        return user 
    } catch (error) {
        throw new Error();
    }
})

export const getSession = createAsyncThunk('auth/get-session', async () => {
    const response = await getUserSession();
    const user = response.data.user
    return user 
})

export const logout = createAsyncThunk('auth/logout', async () => {
    try {
        const response = await logoutUser();
        if(response.data.errorCode === NONE) {
            return
        } else {
            throw new Error(response.data.errorCode)
        }
    } catch (error) {
        throw new Error("Failed to logout from server, ", error)
    }
})
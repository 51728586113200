export const AppCategories = {

        "deals_of_day": {
            en: "Deals of the Day",
            tr: "Günün Fırsatları",
        },
        "accessory_jewelry": {
            en: "Accessory & Jewelry",
            tr: "Aksesuar & Takı",
        },
        "baby_kids_clothing": {
            en: "Baby & Kids Clothing",
            tr: "Çocuk Giyim",
        },
        "beauty": {
            en: "Beauty",
            tr: "Güzellik",
        },
        "book_movie": {
            en: "Book & Movie",
            tr: "Kitap & Film",
        },
        "cars_accessories": {
            en: "Cars Accessories",
            tr: "Oto Aksesuar",
        },
        "coupons": {
            en: "Coupons",
            tr: "Kuponlar",
        },
        "computer": {
            en: "Computer",
            tr: "Bilgisayar",
        },
        "designer": {
            en: "Designer",
            tr: "Tasarımcı",
        },
        "electronics": {
            en: "Electronics",
            tr: "Elektronik",
        },
        "family_kids_toys": {
            en: "Family & Kids & Toys",
            tr: "Anne & Çocuk & Oyuncak",
        },
        "gaming": {
            en: "Gaming",
            tr: "Oyun Ve Oyun Konsolları",
        },
        "hobby": {
            en: "Hobby",
            tr: "Hobi",
        },
        "home_living": {
            en: "Home & Living",
            tr: "Ev Ve Yaşam",
        },
        "women_fashion": {
            en: "Women’s Fashion",
            tr: "Kadın Giyim",
        },
        "men_fashion": {
            en: "Men’s Fashion",
            tr: "Erkek Giyim",
        },
        "tools_home": {
            en: "Tools and Home Improvement",
            tr: "Yapı Market ve Ev Dekorasyonu",
        },
        "travel_entertainment": {
            en: "Travel & Entertainment",
            tr: "Bilet & Tatil & Eğlence",
        },
        "shoes_bags": {
            en: "Shoes & Bags",
            tr: "Ayakkabı & Çanta",
        },
        "sports_outdoor": {
            en: "Sports & Outdoor",
            tr: "Spor & Outdoor",
        },
        "supermarket_petshop": {
            en: "Supermarket & Petshop",
            tr: "Süpermarket & Petshop",
        },
        "underclothing_pyjamas": {
            en: "Underclothing & Pyjamas",
            tr: "Iç Giyim & Pijama",
        },
        "fashion": {
            en: "Fashion",
            tr: "Moda & Kıyafet",
        },

        "shopping": {
            en: "Shopping",
            tr: "Alışveriş & Pazaryeri",
        },
        "education": {
            en: "Education",
            tr: "Eğitim & Kurs",
        },
        "app_promotion": {
            en: "App Promotion",
            tr: "Uygulama İndirme",
        }
}


export const LCWAppCategories = {

    "deals_of_day": {
        en: "Deals of the Day",
        tr: "Günün İndirimleri",
    },
    "accessory_jewelry": {
        en: "Accessory & Jewelry",
        tr: "Aksesuar & Takı",
    },
    "baby_kids_clothing": {
        en: "Baby & Kids Clothing",
        tr: "Bebek Giyim",
    },
    "beauty": {
        en: "Beauty",
        tr: "Kişisel Bakım",
    },
    "coupons": {
        en: "Coupons",
        tr: "İndirim Kuponları",
    },
    "family_kids_toys": {
        en: "Family & Kids & Toys",
        tr: "Çocuk Ürünleri & Oyuncaklar",
    },
    "home_living": {
        en: "Home & Living",
        tr: "Ev Ve Yaşam",
    },
    "hobby": {
        en: "Hobby",
        tr: "Hobi",
    },
    "women_fashion": {
        en: "Women’s Fashion",
        tr: "Kadın Giyim",
    },
    "men_fashion": {
        en: "Men’s Fashion",
        tr: "Erkek Giyim",
    },
    "shoes_bags": {
        en: "Shoes & Bags",
        tr: "Ayakkabı & Çanta",
    },
    "sports_outdoor": {
        en: "Sports & Outdoor",
        tr: "Spor & Outdoor",
    },
    "underclothing_pyjamas": {
        en: "Underclothing & Pyjamas",
        tr: "Iç Giyim & Pijama",
    },
    "fashion": {
        en: "Fashion",
        tr: "Özel Koleksiyonlar",
    },
    "app_promotion": {
        en: "App Promotion",
        tr: "Uygulama İndirme",
    },
    "travel_entertainment": {
        en: "Travel & Entertainment",
        tr: "Seyahat & Eğlence",
    }
}


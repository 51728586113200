import React from 'react'
import ReactDOM from 'react-dom'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material'
import {useTranslation} from 'react-i18next'
import styles from './Modal.module.scss'
import Card from "../card/Card";
import {CloseRounded} from "@mui/icons-material";

export const MODAL_SIZE_X_SMALL = 'xs';
export const MODAL_SIZE_SMALL = 'sm';
export const MODAL_SIZE_MEDIUM = 'md';
export const MODAL_SIZE_LARGE = 'lg';
export const MODAL_SIZE_X_LARGE = 'xl';
export const MODAL_SIZE_FULL_WIDTH = false;

const Modal = (props) => {

    const {
        isOpen,
        onClose = () => {},
        title,
        content,
        onConfirmation,
        onCancelation,
        children,
        confirmationBtnText,
        shouldDisableConfirmationButton,
        modalSize = MODAL_SIZE_SMALL,
        buttonColor = "info",
        approveBtnStartIcon = null
    } = props

    const {t} = useTranslation();

    const handleConfirmation = () => {
        onConfirmation()
        onClose()
    }

    const handleCancelation = () => {
        onCancelation()
        onClose()
    }

    if (!isOpen) return null

    return ReactDOM.createPortal(
        <Dialog
            open={isOpen}
            onClose={handleCancelation}
            aria-labelledby="modal-title"
            fullWidth
            maxWidth={modalSize}
        >
            <Card 
                title={title} 
                customStyles={{
                    cardContainer: styles.cardContainer,
                    cardBody: styles.customCardBody
                }}
            >
                <div className={styles.container}>
                    {(content || children) &&
                        <div className={styles.children}>
                            {content && <DialogContent>
                                <DialogContentText>
                                    {content}
                                </DialogContentText>
                            </DialogContent>}
                            {children}
                        </div>}
                    <div className={styles.buttonWrapper}>
                        <DialogActions className={styles.buttonsContainer}>
                            {onConfirmation &&
                                <Button
                                    color={buttonColor}
                                    variant="contained"
                                    startIcon={approveBtnStartIcon ? approveBtnStartIcon : null}
                                    disabled={shouldDisableConfirmationButton}
                                    onClick={handleConfirmation}>{confirmationBtnText ? confirmationBtnText : t("modal.ok-button")}
                                </Button>}
                            {onCancelation && <Button
                                variant="outlined"
                                startIcon={<CloseRounded/>}
                                onClick={handleCancelation}>{t("modal.cancel-button")}
                            </Button>}
                        </DialogActions>
                    </div>
                </div>
            </Card>
        </Dialog>
    , document.getElementById('modal-root'))
}

export default Modal
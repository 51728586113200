import React from 'react';
import styles from "./ProductDevicePerformance.module.scss";
import {Android, Apple, Filter1Outlined, ShoppingCartOutlined} from "@mui/icons-material";

function formatMoney(n) {
    return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1');
}

const iconSize = 20;

const ProductDevicePerformance = ({results}) => {
    return (
        <div>
                <div className={styles.container}>
                    <div className={styles.blocks}>
                        <div className={styles.title}>Revenue</div>
                        <div className={styles.valueHolder}>
                            <ShoppingCartOutlined sx={{width: iconSize, height: iconSize}}/>
                            <div className={styles.value}>{`~ ${formatMoney(results.estimatedAmount)} ₺`}</div>
                        </div>
                        <div className={styles.valueHolder}>
                            <Filter1Outlined sx={{width: iconSize, height: iconSize}}/>
                            <div
                                className={styles.subValue}>{`First Sale ~ ${formatMoney(results.firstPurchaseAmount)} ₺`}</div>
                        </div>

                    </div>
                    <div className={styles.blocks}>
                        <div className={styles.title}>iOS Purchases</div>
                        <div className={styles.valueHolder}>
                            <Apple sx={{width: iconSize, height: iconSize}}/>
                            <div className={styles.value}>{`${results.iosPurchases} times`}</div>
                        </div>
                    </div>
                    <div className={styles.blocks}>
                        <div className={styles.title}>Android Purchases</div>
                        <div className={styles.valueHolder}>
                            <Android sx={{width: iconSize, height: iconSize}}/>
                            <div className={styles.value}>{`${results.androidPurchases} times`}</div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default ProductDevicePerformance;

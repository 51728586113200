import { clientGetWrapper, clientPostWrapper } from "../api/Api"
import {GET_SESSION, LOGIN_USER, LOGOUT} from "../common/constants/routes";


export const loginUser = (data) => {
    return clientPostWrapper(LOGIN_USER, data);
}

export const getUserSession = () => {
    return clientGetWrapper(GET_SESSION);
}

export const logoutUser = () => {
    return clientGetWrapper(LOGOUT);
}
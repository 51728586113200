import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, TextField} from "@mui/material";
import Card from "../../common/card/Card";
import styles from "./LCWUserRegistration.module.scss";
import {useTranslation} from "react-i18next";
import {LCWAppCategories} from "../../utils/hasOfferCategories";
import {emailRegex} from "../../common/constants/constService";
import {addNewLCWUser} from "../../services/usersService";
import {getKeyByValue} from "../../common/utils";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";

const initState = {
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    instagram: '',
    follower_count: 0,
    tiktok: '',
    twitter: '',
    categories: []
}


const LCWUserRegistration = () => {
    const {t} = useTranslation();
    const [formData, setFormData] = useState(initState);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [categoriesMap, setCategoriesMap] = useState({});
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const [showResult, setShowResult] = useState(false);
    useEffect(() => {
        const map = {}
        let categories = [];
        Object.keys(LCWAppCategories).forEach(key => {
            map[key] = true;
            const value = LCWAppCategories[key].tr;
            categories.push(value);
        });
        setCategoriesMap(map);

        setFormData((prevState) => ({
            ...prevState,
            categories: categories
        }))

    }, []);


    const isCategorySelected = (key) => !!categoriesMap[key];


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        checkValidations(e.target);
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleCategoryChange = (key) => {
        let currentState = categoriesMap[key];
        setCategoriesMap((prevState) => ({
            ...prevState,
            [key]: !currentState
        }));
        const value = LCWAppCategories[key].tr;
        if (!!currentState) {
            let copyArr = formData.categories.map(category => category.trim());
            const index = copyArr.indexOf(value);
            if (index > -1) {
                copyArr.splice(index, 1);
            }
            setFormData((prevState) => ({
                ...prevState,
                categories: copyArr
            }))
        } else {
            let addArr = [...formData.categories];
            addArr.push(value);
            setFormData((prevState) => ({
                ...prevState,
                categories: addArr
            }))
        }
    }

    const updateHandler = async (e) => {
        const payload = {
            email: formData.email,
            firstname: formData.firstname,
            lastname: formData.lastname,
            phone: formData.phone,
            instagram: formData.instagram,
            follower_count: formData.follower_count,
            tiktok: formData.tiktok,
            twitter: formData.twitter,
            categories: formData.categories.map(category => LCWAppCategories[getKeyByValue(LCWAppCategories, category)].en)
        }
        const response = await addNewLCWUser(payload);
        if (response.data.isUpdated) {
            setFormData(initState);
            setToastContent(t("lcw-registration.new-user-success-msg"));
            setShowResult(true);
        } else {
            setToastContent(t("lcw-registration.new-user-fail-msg"))
        }
        toggleToast();
    }

    const checkValidations = ({name, value}) => {
        switch (name) {
            case 'email':
                setIsEmailValid(value.length !== 0 ? emailRegex.test(value) : true);
                break;
            default:
                break;
        }
    }

    const isSubmitDisabled = () => (
        !formData.email ||
        !isEmailValid ||
        !formData.firstname ||
        !formData.lastname ||
        !formData.instagram ||
        (Number(formData.follower_count) < 500) ||
        formData.categories.length === 0)

    return (
        <div className={styles.container}>
            <Card title={t("common-tr.lcw-submit-msg")}>
                {!showResult && <div className={styles.formItself}>
                    <div>
                        <TextField
                            id="email"
                            name="email"
                            label={t("common.email")}
                            variant="outlined"
                            onChange={handleInputChange}
                            required
                            sx={{width: 330}}
                            autoFocus
                        />
                        {!isEmailValid && <Alert sx={{width: 330, mt: 1}}
                                                 severity="error">{t("create-new-user.invalid-email-msg-tr")}</Alert>}
                    </div>
                    <TextField
                        id="outlined-basic"
                        name="firstname"
                        label={t("common-tr.name")}
                        value={formData.firstname}
                        variant="outlined"
                        required
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="lastname"
                        label={t("common-tr.surname")}
                        value={formData.lastname}
                        variant="outlined"
                        required
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="phone"
                        label={t("common-tr.phone")}
                        value={formData.phone}
                        variant="outlined"
                        required
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <div>
                        <TextField
                            id="outlined-basic"
                            name="instagram"
                            label={t("common-tr.instagram-account")}
                            value={formData.instagram}
                            variant="outlined"
                            sx={{width: 330}}
                            required
                            onChange={handleInputChange}
                        />
                        <div className={styles.infoMsg}>{t("common-tr.instagram-account-msg")}</div>
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            name="follower_count"
                            label={t("common-tr.instagram-follower-count")}
                            value={formData.follower_count}
                            variant="outlined"
                            sx={{width: 330}}
                            type="number"
                            required
                            onChange={handleInputChange}
                        />
                        <div className={styles.infoMsg}>{t("common-tr.instagram-follower-count-msg")}</div>
                    </div>
                    <TextField
                        id="outlined-basic"
                        name="tiktok"
                        label={t("common-tr.tiktok-account")}
                        value={formData.tiktok}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <TextField
                        id="outlined-basic"
                        name="twitter"
                        label={t("common-tr.x-account")}
                        value={formData.twitter}
                        variant="outlined"
                        sx={{width: 330}}
                        onChange={handleInputChange}
                    />
                    <div className={styles.categories}>
                        <div className={styles.title}>{t("common-tr.categories")}</div>
                        <div className={styles.subTitle}>{t("common-tr.categories-msg")}</div>
                        {!!formData.categories && Object.keys(categoriesMap).length > 0 && Object.keys(LCWAppCategories).map(key => (
                            <div key={key} className={styles.checkBoxHolder}>
                                <Checkbox
                                    name={LCWAppCategories[key].tr}
                                    checked={isCategorySelected(key)}
                                    onChange={e => handleCategoryChange(key)}
                                />
                                <div>{LCWAppCategories[key].tr}</div>
                            </div>
                        ))}
                    </div>
                    <Button
                        variant='contained'
                        disabled={isSubmitDisabled()}
                        onClick={updateHandler}>
                        {t("common-tr.lcw-submit-msg")}
                    </Button>
                </div>}
                {showResult && <div>
                    <Alert
                        severity={"info"}
                        sx={{width: '100%'}}>
                        {t("lcw-registration.new-user-success-msg")}
                    </Alert>
                </div>}
            </Card>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default LCWUserRegistration;

import React, {useEffect, useState} from 'react';
import styles from "./EditInfluencer.module.scss";
import {Autocomplete, Button, TextField} from "@mui/material";
import {t} from "i18next";
import LinksMultipleChoices from "../AssignInfluencers/LinksMultipleChoices/LinksMultipleChoices";
import {useSelector} from "react-redux";
import {updatePaidProjectInfuencer} from "../../../services/paidProjectsService";
import Toast from "../../../common/toast/Toast";
import useToast from "../../../common/hooks/useToast";


export const INFLUENCER_PROJECT_STATUS = {
    IN_REVIEW: "In Review",
    APPROVED: "Approved",
    REJECTED: "Rejected"
}


const EditInfluencer = ({infData}) => {

    const [formData, setFormData] = useState(null);
    const projectsData = useSelector((state) => state.projectsList.projects || []);
    const selectedProject = projectsData.find(project => project.id === Number(infData.project_id));
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    useEffect(() => {
        setFormData({
            status: infData.status,
            note: infData.note,
            amount: infData.amount,
            links: typeof (infData.links) === 'string' ? JSON.parse(infData.links) : infData.links,
            created_at: infData.created_at,
            name: infData.name
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleStatusDropdownChange = async (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        const status = textContent;
        setFormData({...formData, status: status});
    }
    const handleLinksDropdownChange = async (e) => {
        const {textContent} = e.target;
        if (!textContent) return;
        const links = textContent;
        const copyArr = [...formData.links]
        if (!formData.links.includes(links)) {
            copyArr.push(links);
        }
        setFormData({...formData, links: copyArr});

    }

    const updateInfluenerProjectData = async () => {

        const payload = {
            affId: infData.affiliate_id,
            projectId: infData.project_id,
            status: formData.status,
            links: JSON.stringify(formData.links),
        }
        const response = await updatePaidProjectInfuencer(payload);
        if (!!response.errorCode && !response.data.result) {
            setToastContent(t("common.error_msg"));
        } else {
            setToastContent(t("paid-project.update-influencer-success-msg"));
        }
        toggleToast();
    }


    const removeLink = (link) => {
        if (formData.links.length === 1) return;
        const copyArr = [...formData.links];
        const index = copyArr.indexOf(link);
        if (index > -1) {
            copyArr.splice(index, 1);
        }
        setFormData({...formData, links: copyArr});
    }

    const getFormattedLinks= () => (typeof selectedProject.links === 'string') ?
        JSON.parse(selectedProject.links) :
        selectedProject.links;
    return (
        <div>
            {selectedProject && <div className={styles.container}>
                {!!formData && !!formData.status &&
                    <div className={styles.formHolder}>
                        <div className={styles.dropdowns}>
                            <Autocomplete
                                name="links"
                                freeSolo
                                options={getFormattedLinks()}
                                onChange={handleLinksDropdownChange}
                                sx={{width: 260}}
                                renderInput={(params) => <TextField {...params}
                                                                    label={t('paid-project.select-links')}/>}
                            />
                            <Autocomplete
                                name="status"
                                freeSolo
                                required
                                sx={{width: 250}}
                                value={formData.status}
                                options={(Object.values(INFLUENCER_PROJECT_STATUS)).map(status => status)}
                                onChange={handleStatusDropdownChange}
                                renderInput={(params) => <TextField {...params}
                                                                    label={t('common-en.status')}/>}/>
                        </div>
                        <Button
                            variant="contained"
                            onClick={updateInfluenerProjectData}
                            sx={{width: 'fit-content'}}>{t('common.update')}
                        </Button>
                    </div>}
                <div className={styles.linksHolder}>
                    {!!formData && !!formData.links &&
                        <LinksMultipleChoices startDate={selectedProject.startDate} links={formData.links}
                                              endDate={selectedProject.endDate}
                                              removeLink={removeLink}/>}
                </div>
                <Toast
                    isOpen={isToastOpen}
                    onClose={toggleToast}
                    content={toastContent}
                    severity="info"
                />
            </div>}
        </div>
    );
};

export default EditInfluencer;

import { useState } from 'react';

const useModal = (initialContent) => {

    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ modalContent, setModalContent ] = useState(initialContent ? initialContent : {
        title: '',
        content: ''
    })

    const toggleModal = () => {
        setIsModalOpen(prevState => !prevState)
    }

    return {
        isModalOpen,
        toggleModal,
        modalContent,
        setModalContent
    }
}

export default useModal;
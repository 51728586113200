import React, {useEffect, useState} from 'react';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import Card from "../../common/card/Card";
import {useTranslation} from "react-i18next";
import GenericTable from "../../common/table/GenericTable";
import {addNewUser, getAllHasOfferUsers, updateUserGeneralSettings} from "../../services/usersService";
import UserBankDetails from "./UserBankDetail/UserBankDetails";
import {Button, TextField} from "@mui/material";
import {DownloadOutlined, LaunchOutlined, UpdateOutlined} from "@mui/icons-material";
import {DOWNLOAD_HAS_OFFERS_USERS_CSV} from "../../common/constants/routes";
import styles from "./UsersAdmin.module.scss";
import {getInstagramLink} from "../../common/utils";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";
import AddNewTuneUser from "./AddNewTuneUser/AddNewTuneUser";

const UsersAdmin = () => {
    const {t} = useTranslation();
    const [tableData, setTableData] = useState([]);
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const usersTableColumns = {};
    usersTableColumns.visibility = {}
    usersTableColumns.data = [
        /*  {
              accessorKey: 'user_id',
              header: "User Id",
              size:50
          },*/
        {
            accessorKey: 'firstname',
            header: "Username",
            Cell: (cell) => (
                <div>{cell.row.original.firstname} {cell.row.original.lastname}</div>
            )
        },
        {
            accessorKey: 'hasoffersid',
            header: "HasOffer Id",
            size: 50
        },
        {
            accessorKey: 'email',
            header: t("common.email"),
            Cell: (cell) => (
                <TextField defaultValue={cell.row.original.email} onChange={(e => {
                    cell.row.original.email = e.target.value;
                })} name="email" size="small" variant="outlined" fullWidth/>
            )
        },
        {
            accessorKey: 'phone',
            header: "Phone",
            size: 100,
            Cell: (cell) => (
                <TextField defaultValue={cell.row.original.phone} onChange={(e => {
                    cell.row.original.phone = e.target.value;
                })} name="phone" size="small" variant="outlined" fullWidth/>
            )
        },
        {
            accessorKey: 'website',
            header: "Social Account",
            size: 200,
            Cell: (cell) => (
                <div>
                    <div className={styles.socialHolder}>
                        <TextField defaultValue={cell.row.original.website} onChange={(e => {
                            cell.row.original.website = e.target.value;
                        })} name="website" size="small" variant="outlined" fullWidth/>
                        <Button
                            variant="contained"
                            sx={{mT: 1, width: "fit-content"}}
                            startIcon={<LaunchOutlined/>}
                            size={"small"}
                            onClick={(e) => {
                                let username = getInstagramLink(cell.row.original.website);
                                username = (username[0] === "/") ?
                                    username.substring(1) :
                                    username;
                                window.open(`https://www.instagram.com/${username}`, '_blank');
                            }}>{t("common.open")}
                        </Button>
                    </div>
                </div>
            )
        },
        {
            accessorKey: 'Actions',
            header: "Actions",
            Cell: (cell) => (
                <div>
                    <Button variant="contained"
                            color="info"
                            startIcon={<UpdateOutlined/>}
                            size={"small"}
                            onClick={e => updateUserPersonalDetails(cell.row.original)}>{t("common.update")}
                    </Button>
                </div>
            )
        }
    ];

    const updateUserPersonalDetails = async (rowData) => {
        const {
            email,
            phone,
            website,
            user_id
        } = rowData;
        const response = await updateUserGeneralSettings({email, phone, website, userId: user_id});
        (!!response.data) ?
            setToastContent(t("user-admin.update-success")) :
            setToastContent(t("user-admin.update-failed"));

        toggleToast();
    }

    const getAllUsers = async () => {
        const response = await getAllHasOfferUsers();
        if (!!response.data) {
            const users = response.data.users;
            const sortedUsers = users.sort((a, b) => a.user_id < b.user_id ? 1 : -1)
            setTableData(sortedUsers)
        }
    }

    const expandUser = ({row}) => (
        <div>
            {!!row.original &&
                <UserBankDetails
                    bankDetail={row.original}></UserBankDetails>
            }
        </div>
    )


    useEffect(() => {
        getAllUsers();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const handleDownloadCSV = async (e) => {
        window.open((process.env.REACT_APP_DASHBOARD_API ?? "http://localhost:4000") + DOWNLOAD_HAS_OFFERS_USERS_CSV, "_blank");
    };

    const callAddUserCallback = async (formData) => {
        const response = await addNewUser(formData);
        (!!response.data) ?
            setToastContent(t("user-admin.create-user-success-msg")) :
            setToastContent(t("user-admin.create-user-failed-msg"));
        toggleToast();
    }
    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            {tableData.length > 0 && <Card title={t("user-admin.title-users")}>
                <div>
                    <AddNewTuneUser addUserCb={callAddUserCallback}/>
                </div>
                <div className={styles.buttonHolder}>
                    <Button onClick={handleDownloadCSV}
                            startIcon={<DownloadOutlined/>}
                            color="info"
                            variant="contained">{t("common-en.export")}</Button>
                </div>
                <GenericTable
                    outputColumns={usersTableColumns}
                    tableData={tableData}
                    allRowsAreExpanded={tableData.length === 1}
                    rowCount={tableData.length}
                    enablePagination={true}
                    expand={expandUser}
                />
            </Card>}
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default UsersAdmin;

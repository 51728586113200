import React from 'react'
import {Breadcrumbs} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {NavLink, useLocation} from 'react-router-dom';
import styles from './BreadcrumbsBar.module.scss'
import {HomeOutlined} from "@mui/icons-material";
import {PURCHASE_REPORTS, ROOT_URL} from "../../common/constants/routes";
import {capitalizeFirstChar} from "../../common/utils";

export const breadcrumbElements = (text, splitter) => {
    const parts = text.split(splitter);
    return parts.map((path, index) => {
        return {
            value: capitalizeFirstChar(path),
            isLink: !(index === parts.length - 1),
            path: path
        }
    })
}

export const getBreadcrumbsList = (combinedParts,
                                   cumulativePath)=> {
    return combinedParts
        .filter(part => part.value && part.value.length !== 0)
        .map(part => {
            cumulativePath = cumulativePath + `/${part.path}`
            const allParts = part.value.split("-");
            const finalLabel = allParts.reduce((acc, eachPart) => {
                return `${capitalizeFirstChar(acc)} ${capitalizeFirstChar(eachPart)}`
            })
            return {
                label: finalLabel,
                path: cumulativePath,
                isLink: part.isLink
            }
        });
}

const BreadcrumbsBar = ({
                            useUrl = true, extraProps = []
                        }) => {
    const location = useLocation();
    let combinedParts = useUrl ? breadcrumbElements(location.pathname, ROOT_URL) : [];
    extraProps.forEach((extra) => {
        combinedParts.push({
            value: extra.value,
            isLink: extra.isLink,
            path: extra.path
        })
    })
    let cumulativePath = ''
    const breadcrumbsArray = getBreadcrumbsList(combinedParts,cumulativePath);
    const breadcrumbs = breadcrumbsArray.map((item) => {
        return <NavLink
            data-testid={`breadcrumb${item.path}`}
            key={item.path}
            to={item.path}
            style={item.isLink ? null : {textDecoration: 'none', fontWeight: 500, pointerEvents:"none"}}
            sx={item.isLink ? {color: "text.primary", display: 'flex', alignItems: 'center'} : null}>
            {capitalizeFirstChar(item.label)}
        </NavLink>
    })
    return (<div className={styles.container}>
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small"/>}
            aria-label="breadcrumb"
        > <NavLink
            underline="hover"
            sx={{display: 'flex', alignItems: 'center'}}
            color="inherit"
            to={PURCHASE_REPORTS}>
            <HomeOutlined sx={{fontSize: 25, marginTop: 0.5}}/>
        </NavLink>{breadcrumbs}</Breadcrumbs>
    </div>)
}

export default BreadcrumbsBar
import React, {useState} from 'react';
import {Alert, Button, TextField} from "@mui/material";
import styles from "./AppUserLogin.module.scss";
import {deleteAppUser, loginInfluioUserApp} from "../../services/usersService";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";
import {emailRegex} from "../../common/constants/constService";
import {t} from "i18next";
import Modal from "../../common/modal/Modal";
import useModal from "../../common/hooks/useModal";

const logo = require('../../images/influio-logo.png');
const initialForm = {
    email: "",
    password: "",
}
const initialUserData = {
    user: null,
    tuneUser: null
}

const AppUserLogin = () => {
    const [form, setForm] = useState(initialForm);
    const [userData, setUserData] = useState(initialUserData)
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const {isModalOpen, toggleModal, modalContent, setModalContent} = useModal();
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const handleChange = (e) => {
        checkValidations(e.target);
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const deleteUser = async () => {
        const payload = {
            affId: 0,
            email: form.email,
            password: form.password
        }
        const response = await deleteAppUser(payload);
        if (!!response.data && !response.data.errorCode) {
            setToastContent("User deleted successfully")
            setForm(initialForm);
            setTimeout(() => {
                setIsLoggedIn(false);
            }, 2000);
        } else if (response.data.errorCode === "USER_NOT_FOUND") {
            setToastContent("User not found")
        } else {
            setToastContent("User could not be deleted")

        }
        toggleToast()
        toggleModal()
    }
    const handleDelete = () => {
        console.log(form);
        setModalContent({
            title: "Are you sure?",
            content: "Are you sure you want to delete this user?"
        })
        toggleModal()
    }

    const loginUser = async () => {
        const payload = {
            affId: 0,
            email: form.email,
            password: form.password
        }
        const response = await loginInfluioUserApp(payload);
        if (!!response.data && !!response.data.user && !!response.data.tuneUser) {
            setIsLoggedIn(true);
            setUserData({
                user: response.data.user.user,
                tuneUser: response.data.tuneUser
            })
        } else if (response.data.errorCode === "USER_NOT_FOUND" || response.data.error === "USER_NOT_FOUND") {
            setToastContent("User not found")
        } else {
            setToastContent("User could not be deleted")

        }
        toggleToast()

    }
    const checkValidations = ({name, value}) => {
        switch (name) {
            case 'email':
                setIsEmailValid(value.length !== 0 ? emailRegex.test(value) : true);
                break;
            default:
                break;
        }
    }

    return (
        <div className={styles.container}>
            <a href="https://play.google.com/store/apps/details?id=com.influio.app&hl=tr&pli=1"
               target="_blank" rel="noreferrer"><img src={logo} alt="influio logo" className={styles.logo}/></a>
            <div className={styles.appDetails}>
                <div><a href="https://play.google.com/store/apps/details?id=com.influio.app&hl=tr&pli=1"
                        target="_blank" rel="noreferrer">App Link</a></div>
                <div>Developer name: <b>Influio</b></div>
            </div>
            {!isLoggedIn &&
                <div className={styles.card}>


                    <h1>Login Influio User Area</h1>

                    <div className={styles.formElements}>
                        <TextField label="email" name="email" variant="outlined" onChange={handleChange}/>
                        {!isEmailValid &&
                            <Alert sx={{mt: 1}} severity="error">{t('create-new-user.invalid-email-msg')}</Alert>}
                        <TextField label="password" name="password" type="password" variant="outlined"
                                   onChange={handleChange}/>
                        <Button disabled={!form.email || !form.password || !isEmailValid} variant="contained"
                                color="success"
                                onClick={loginUser}>Login User Settings</Button>
                    </div>

                </div>
            }{isLoggedIn && userData.tuneUser && userData.user && <div>
            <div>
                <div className={styles.card}>
                    <h1>Welcome {userData.user.firstname} {userData.user.lastname}</h1>
                    <div>
                        <div>Email: {userData.user.email}</div>
                        <div>Social: {userData.user.website}</div>
                        <Button disabled={!form.email || !form.password || !isEmailValid} variant="contained"
                                sx={{
                                    mt: 2,
                                    width:'250px',
                                    background:'black'
                                }}
                                color="error"
                                onClick={handleDelete}>Delete User</Button>
                    </div>
                </div>
            </div>
        </div>}
            <Modal
                isOpen={isModalOpen}
                onCancelation={toggleModal}
                onConfirmation={deleteUser}
                title={modalContent.title}
                content={modalContent.content}
            />
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default AppUserLogin;

export const emailRegex = /^[a-zA-Z.]+[a-zA-Z0-9._%+-]*@[a-zA-Z0-9]+[a-zA-Z0-9.-]*\.[a-zA-Z]{2,4}$/;
export const userNameRegex = /^[A-Za-z0-9_.]{5,20}$/;
export const domainRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,63}\b([^}{]*)?$/;
export const domainWithoutHttpRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
export const base64Regex = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
export const faviconUrlMatchRegex = /\/\/(?:(?!\/\/).)*favicon\.ico/;
export const matchNumericStringRegex = /^-?\d+\.?\d*$/
export const matchNumericStringWithCommasRegex = /^-?\d+(,\s*\d+)*(\.\d+)?$/
export const whiteSpaceRegex = /\s/g

export const FIRST_REPORT_DATE = "2023-07-01";
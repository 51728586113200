export const UI_ROW_CLASS = 'uiRowClass';
export const UI_ROW_TITLE = 'uiRowTitle';
export const UI_ALERT_BODY_CENTERED = 'uiAlertBodyCentered';

//colors
export const SUCCESS_COLOR = "#16a085";
//export const PRIMARY_COLOR = "#3498db";
export const PRIMARY_COLOR = "#FF511E";

export const CONFIRM_BUTTON_COLOR = "#44bd32";
export const DANGER_COLOR = "#e74c3c";
export const WHITE_LISTED_COLOR = "#2ecc71";
export const BLACK_LISTED_COLOR = "#2c3e50";
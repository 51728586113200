import React, {useEffect} from 'react';
import styles from "./EditProject.module.scss";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import CreateEditPaidProject from "../../AdvertiserManagement/CreateEditPaidProject/CreateEditPaidProject";

const EditProject = () => {
    const [searchParams] = useSearchParams();
    const projectsData = useSelector((state) => state.projectsList.projects || []);
    const projectId = searchParams.get("pid");
    const selectedProject = projectsData.find(project => project.id === Number(projectId));
    useEffect(() => {
        if (!projectId) {
            return;
        }
        if (!selectedProject) {
            return;
        }
    }, [projectId, selectedProject]);
    return (
        <div className={styles.container}>

            {!!selectedProject && <CreateEditPaidProject projectData={selectedProject}/>}
        </div>
    );
};

export default EditProject;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllPaidProjects} from "../../services/paidProjectsService";

const initialState = {projects: []};

// First, create the thunk
export const fetchProjects = createAsyncThunk(
    'get/projects',
    async () => {
        const response = await getAllPaidProjects();
        return response.data;
    }
)

export const paidProjectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            state.projects = action.payload.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);;
        })
    }
})

export const projectReducer = paidProjectsSlice.reducer

 export const setCookie = (name, value, expiration) => {
    var date = new Date();
    date.setDate(date.getDate() + expiration);
    document.cookie = `${name}=${value};expires=${date};path=/`;
}


export const getCookie = (name) => {
    var splittedCookie = decodeURIComponent(document.cookie).split(';');
    
    for(let i in splittedCookie) {
        if(splittedCookie[i].split('=')[0].trim() === name.trim()) {
            return splittedCookie[i].split('=')[1]
        }
    }
    return null;
}
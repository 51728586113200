import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import {login} from '../../store/auth/authActions'
import styles from './Login.module.scss'
import {Alert, Button, TextField} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {HOME, LOGIN, ROOT_URL} from '../../common/constants/routes'
import Card from "../../common/card/Card";
import {fetchOffers} from "../../store/offerSlice/offerSlice";
import {fetchAdvertisers} from "../../store/advertisersSlice/advertisersSlice";
import {networkLogoURL} from "../../layouts/navbar/NavbarLayout";
import {fetchCategories} from "../../store/categorySlice/categorySlice";
import {fetchProjects} from "../../store/paidProjectsSlice/paidProjectsSlice";

const Login = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch()
    const errorMessage = useSelector(state => state.auth.error)
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const {state} = useLocation();

    const loginHandler = async () => {
        dispatch(login({email, password}))
    }

    useEffect(() => {
        if (auth.user) {
            const from = state?.from;
            dispatch(fetchOffers());
            dispatch(fetchProjects());
            dispatch(fetchAdvertisers());
            dispatch(fetchCategories());
            from ?
                from === ROOT_URL ?
                    navigate(HOME) :
                    navigate(from) :
                navigate(HOME)
        }
    }, [auth.user, navigate]);// eslint-disable-line react-hooks/exhaustive-deps


    return (

        <div className={styles.container} data-testid={LOGIN}>
            <div className={styles.logo}><img src={networkLogoURL} alt="logo"/></div>
            <div className={styles.loginBox}>
                <Card title={t("common.login")}>
                    <div className={styles.body}>
                        <TextField
                            className={styles.inputField}
                            id="username"
                            label={t("common.email")}
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            autoFocus
                        />
                        <TextField
                            className={styles.inputField}
                            id="password"
                            label={t("common.password")}
                            type="password"
                            variant="outlined"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            name="password"
                            autoComplete="current-password"
                        />
                        {errorMessage && <Alert className={styles.errorMessage}
                                                severity="error">{t("login.incorrect-credentials")}</Alert>}
                        <Button
                            variant='contained'
                            disabled={!password || !email}
                            onClick={loginHandler}
                        >
                            {t("common.login")}
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Login
import { useState } from 'react';

const useToast = (initialContent) => {

    const [ isToastOpen, setIsToastOpen ] = useState(false)
    const [ toastContent, setToastContent ] = useState(initialContent ? initialContent : "")

    const toggleToast = () => {
        setIsToastOpen(prevState => !prevState)
    }

    return {
        isToastOpen,
        toggleToast,
        toastContent,
        setToastContent
    }
}

export default useToast;
import moment from "moment/moment";

export const getDaysDiffBetweenTwoDates = (startDate,endDate) => {
    if(!startDate || !endDate) return 0;
    return moment(endDate).diff(moment(startDate), 'days');
}

export const dateFormatWithHours = 'YYYY-MM-DD HH:mm:ss';
export const getFormattedIncrementalDate = (index,date) => {
    const diff = index;
    const formattedDate = new Date(date);
    formattedDate.setDate(formattedDate.getDate() + diff);
    return formattedDate.toDateString();
}

export const formatTwoDatesRange = (index, startDate, endDate) => {
    const diff = index;
    const formattedDate = new Date(startDate);
    formattedDate.setDate(formattedDate.getDate() + diff);
    const formattedEndDate = new Date(endDate);
    formattedEndDate.setDate(formattedEndDate.getDate() + diff);
    return `${formattedDate.toDateString()} to ${formattedEndDate.toDateString()}`;
}

export const formatNumberWithNumberFormatter = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
}

export const formatPercentage = (number) => {
    return `${(number*100).toFixed(2)}%`;
}

export const formatPercentageWithNumberFormatter = (number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        roundingMode: 'floor'
    }).format(number);
}
import {clientPostWrapper} from "../api/Api";
import {INSERT_NEW_CAMPAIGN, UPLOAD_CAMPAIGN_IMAGE} from "../common/constants/routes";


export const createCampaign = (payload) => {
    return clientPostWrapper(INSERT_NEW_CAMPAIGN, payload)
}

export const uploadCampaignImage = (formData) => {
    console.log(formData);
    return clientPostWrapper(UPLOAD_CAMPAIGN_IMAGE, formData,true)
}

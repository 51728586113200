import {clientGetWrapper, clientPostWrapper} from "../api/Api";
import {
    DELETE_POTENTIAL_INFLUENCER,
    GET_ALL_POTENTIAL_INFLUENCERS,
    GET_ALL_POTENTIAL_INFLUENCERS_DATA_WITH_CHATS,
    INSERT_POTENTIAL_INFLUENCERS, START_CRAWLING_CHAT_IDS
} from "../common/constants/routes";


export const getAllPotentialInfluencers = () => {
    return clientGetWrapper(GET_ALL_POTENTIAL_INFLUENCERS);
}

export const getAllInfluencersWithChats = () => {
    return clientPostWrapper(GET_ALL_POTENTIAL_INFLUENCERS_DATA_WITH_CHATS);

}

export const sendPotentialInfluencersExcelFileForInsert = (formData) => {
    return clientPostWrapper(INSERT_POTENTIAL_INFLUENCERS, formData,true);
}

export const deletePotentialInfluencer = (payload) => {
    return clientPostWrapper(DELETE_POTENTIAL_INFLUENCER, payload)
}

export const startCrawlChatIdsAsJob = () => {
    return clientPostWrapper(START_CRAWLING_CHAT_IDS);
}
import React from 'react';
import ReactDOM from 'react-dom'
import styles from './Spinner.module.scss';
import {HashLoader} from "react-spinners";
import {networkLogoURL} from "../../layouts/navbar/NavbarLayout";

const Spinner = () => {
    return ReactDOM.createPortal(
        <div className={styles.container}>
            <div className={styles.spinner}>
                <img src={networkLogoURL} alt="logo"/>
                <HashLoader
                    color='#FF511E'
                    size={150}/>
            </div>
        </div>
        , document.getElementById('spinner'))
};

export default Spinner;
import React, {useEffect, useState} from 'react';
import BreadcrumbsBar from "../../../layouts/breadcrumb/BreadcrumbsBar";
import styles from "./CreateEditPaidProject.module.scss";
import Card from "../../../common/card/Card";
import {useTranslation} from "react-i18next";
import {Autocomplete, Button, TextField} from "@mui/material";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import {DATE_TYPES} from "../../../common/utils";
import {useSelector} from "react-redux";
import {DROPDOWN_SELECT_ALL_VALUE} from "../../../common/constants/general";
import moment from "moment";
import LinkGenerator from "../CreatePromoCode/LinkGenerator/LinkGenerator";
import {
    changePaidProjectDisplayStatus,
    createPaidProject,
    updatePaidProject
} from "../../../services/paidProjectsService";
import Toast from "../../../common/toast/Toast";
import useToast from "../../../common/hooks/useToast";
import {AddReactionOutlined, CreateOutlined, DeleteOutline, PaidOutlined, UpdateOutlined} from "@mui/icons-material";
import {domainRegex} from "../../../common/constants/constService";
import IOSSwitch from "../../../common/iosSwitch/IOSSwitch";
import {dateFormatWithHours} from "../../../utils/common";
import {useNavigate} from "react-router-dom";
import {ADMIN_PAID_PROJECTS} from "../../../common/constants/routes";

export const PROJECT_TYPES = {
    cash: 'cash',
    product_gift: 'product_gift',
    gift_coupon: 'gift_coupon'
};

export const PROJECT_STATUS = {
    Approved: "Approved",
    Pending: "Pending",
    Rejected: "Rejected",
    Cancelled: "Cancelled"
}

const initialFormData = {
    id: "",
    title: "",
    type: "",
    startDate: dayjs(new Date()),
    endDate: null,
    offer: null,
    links: [],
    terms: "",
    status: "",
    amount: 0,
    isPublished: false
}
const CreateEditPaidProject = ({projectData}) => {
    const {t} = useTranslation();
    const [formData, setFormData] = React.useState(projectData ? projectData : initialFormData);
    const [showLinkGenerator, setShowLinkGenerator] = React.useState(true);
    const isEdit = !!projectData;
    const offersList = useSelector((state) => state.offersList.offers);

    const userData = useSelector((state) => state.auth.user || state.auth.user.user);
    const [selectedOfferId, setSelectedOfferId] = useState("");
    const [offers, setOffers] = React.useState([]);
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    const navigate = useNavigate();
    useEffect(() => {
        if (userData.user.offer_id_list === DROPDOWN_SELECT_ALL_VALUE) {
            setOffers(offersList)
        } else {
            const selectedOffers = offersList.filter((offer) => userData.user.offer_id_list.split(",").includes(String(offer.offerId)));
            setOffers(selectedOffers);
        }
        if (!!isEdit && projectData.links) {
            setFormData(prevState => ({
                ...prevState,
                startDate: moment(projectData.startDate).utc(false).format(dateFormatWithHours),
                endDate: moment(projectData.endDate).utc(false).format(dateFormatWithHours),
                amount: projectData.type !== PROJECT_TYPES.cash ? 0 : projectData.amount,
            }));
            setSelectedOfferId(projectData.offer);
        }
    }, [offersList]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        sliceLinks();
    }, [formData.startDate, formData.endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    const sliceLinks = () => {
        const parsedArr = (typeof (formData.links) === 'string') ? JSON.parse(formData.links) : formData.links;
        const copyArr = [...parsedArr];
        const maxDayCount = getMaxDays() + 1;
        const newCopy = copyArr.slice(0, maxDayCount);
        setFormData(prevState => ({
            ...prevState,
            links: newCopy
        }));
    }

    const handlePublishedStateSwitchChange = (e) => {
        setFormData({...formData, isPublished: e.target.checked});
    }
    const handlePaidProjectTypeDropdownChange = (e) => {
        const {textContent} = e.target;
        if (textContent !== PROJECT_TYPES.cash) {
            setFormData({
                ...formData,
                type: textContent,
                amount: 0
            });
        } else {
            setFormData({...formData, type: textContent});
        }
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    const handleStartDateChange = (newValue, type) => {
        if (!newValue) return;
        setShowLinkGenerator(false);
        if (type === DATE_TYPES.START_DATE) {
            setFormData(prevState => ({
                ...prevState,
                startDate: moment(newValue.$d).utc(false).format(dateFormatWithHours)
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                endDate: moment(newValue.$d).utc(false).format(dateFormatWithHours)
            }));
        }
        setTimeout(() => {
            setShowLinkGenerator(true);
        }, 100);
    };

    const handleOfferDropdownChange = async (e) => {
        const {textContent} = e.target;
        const realOfferId = Number(textContent.split(" - ")[0]);
        const selectedOffer = offers.find(offer => offer.offerId === realOfferId);
        setSelectedOfferId(offersList.find(offer => offer.offerId === selectedOffer.offerId).id);
    }

    const getOfferValue = () => {
        const selected = selectedOfferId ? offers.find(offer => offer.id === selectedOfferId) : null;
        return (!!selected) ? selected.offerId + ' - ' + selected.name : "Select Offer";
    };


    const updateFormData = () => {
        (!!isEdit) ?
            formData.id = projectData.id :
            delete formData.id;
        formData.offerName = offersList.find(offer => offer.id === selectedOfferId).name;
        formData.offer = offersList.find(offer => offer.id === selectedOfferId).id;
        formData.links = formData.links.filter(link => link !== '');
    }

    const createNewPaidProject = async () => {
        updateFormData();
        const response = await createPaidProject(formData);
        if (!!response.data) {
            setToastContent(t('link-generator.create-paid-project-success-msg'));
            setFormData(initialFormData);
            setSelectedOfferId(null);
        } else {
            setToastContent(t("common-server-err-msg"));
        }
        toggleToast();
    };

    const updateProject = async () => {
        updateFormData();
        const response = await updatePaidProject(formData);
        if (!!response.data) {
            setToastContent(t('link-generator.update-paid-project-success-msg'));
        } else {
            setToastContent(t("common-server-err-msg"));
        }
        toggleToast();
    };

    const isButtonDisabled = () => {
        return !formData.startDate ||
            !formData.endDate ||
            !formData.title ||
            !formData.terms ||
            !formData.links ||
            !selectedOfferId ||
            formData.links.filter(link => link !== '').length === 0 ||
            formData.links.find(link => domainRegex.test(link) === false)
    };

    const linksUpdate = (links) => {
        setFormData({...formData, links: links});
    }
    const getMaxDays = () => {
        if (!formData || !formData.startDate || !formData.endDate) return 0;
        const startDate = moment(formData.startDate);
        const endDate = moment(formData.endDate);
        return endDate.diff(startDate, 'days');
    }

    const handleStatusDropdownChange = (e) => {
        const {textContent} = e.target;
        setFormData({...formData, status: textContent});
    }

    const getMinEndDate = () => {
        if (!formData || !formData.startDate) return dayjs(new Date());
        return dayjs(formData.startDate);
    }

    const getMaxStartDate = () => {
        if (!!formData.endDate) {
            return dayjs(formData.endDate).subtract(1, 'day');
        }
        return null;
    }

    const deletePaidProject = async () => {
        const response = await changePaidProjectDisplayStatus({
            'projectId': projectData.id
        });
        if (!!response.data) {
            setToastContent(t('delete-paid-project-success-msg'));
            setTimeout(() => {
                navigate(ADMIN_PAID_PROJECTS);
            }, 2000);
        } else {
            setToastContent(t("common-server-err-msg"));
        }

        toggleToast();
    }

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            <div>
                <Card title={isEdit ? t("menu-item.edit-paid-project") : t('menu-item.create-paid-project')}
                      customIcon={<PaidOutlined/>}>
                    <div className={styles.generalContainer}>

                        <div className={styles.formContainer}>
                            {isEdit &&
                                <div className={styles.topElements}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        sx={{
                                            'width': 'fit-content'
                                        }}
                                        startIcon={<AddReactionOutlined/>}
                                        href={`/admin-paid-projects/assign-influencers?pid=${projectData.id}`}
                                    >Influencers
                                    </Button>
                                    <div className={`${styles.formElementBig} ${styles.switcher}`}>
                                        <IOSSwitch
                                            checked={!!formData.isPublished}
                                            onChange={handlePublishedStateSwitchChange}/>
                                        <div>{formData.isPublished ? "PUBLISHED" : "UNPUBLISHED"}</div>
                                    </div>
                                </div>}
                            <div className={styles.formElementBig}>
                                <TextField name="title"
                                           required
                                           sx={{width: '100%'}}
                                           label={t('common.title')}
                                           value={formData.title}
                                           onChange={handleInputChange}></TextField>
                            </div>
                            <div className={`${styles.doubleElement} ${styles.statusContainer}`}>
                                <Autocomplete
                                    name="offers"
                                    freeSolo
                                    required
                                    sx={{width: 250}}
                                    value={getOfferValue()}
                                    options={offers.map(offer => offer.offerId + " - " + offer.name)}
                                    onChange={handleOfferDropdownChange}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={t('create-new-user.select-offer')}/>}/>
                                {isEdit &&
                                    <Autocomplete
                                        name="status"
                                        freeSolo
                                        required
                                        sx={{width: 250}}
                                        value={formData.status}
                                        options={(Object.values(PROJECT_STATUS)).map(status => status)}
                                        onChange={handleStatusDropdownChange}
                                        renderInput={(params) => <TextField {...params}
                                                                            label={t('common-en.status')}/>}/>
                                }

                            </div>
                            <div className={styles.doubleElement}>
                                <Autocomplete
                                    name="type"
                                    freeSolo
                                    required
                                    sx={{width: 250}}
                                    value={formData.type}
                                    options={Object.values(PROJECT_TYPES)}
                                    onChange={handlePaidProjectTypeDropdownChange}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={t('advertiser-management.paid-project-type')}/>}/>
                                <div>

                                    <TextField name="amount"
                                               required
                                               type={"number"}
                                               sx={{
                                                   opacity: formData.type === PROJECT_TYPES.cash ? 1 : 0,
                                                   width: 250
                                               }}
                                               label={t('common.amount')}
                                               value={formData.amount}
                                               onChange={handleInputChange}></TextField>
                                </div>
                            </div>
                            <div className={styles.formElementSmall}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DateTimePicker label={t("common.startDate")}
                                                        minDate={dayjs(new Date())}
                                                        maxDateTime={getMaxStartDate()}
                                                        sx={{width: 252}}
                                                        disablePast={true}
                                                        defaultValue={dayjs(formData.startDate)}
                                                        onChange={(newValue) =>
                                                            handleStartDateChange(newValue, DATE_TYPES.START_DATE)}/>
                                        <DateTimePicker label={t("common.endDate")}
                                                        minDate={getMinEndDate()}
                                                        defaultValue={isEdit ? dayjs(formData.endDate) : null}
                                                        disablePast={true}
                                                        sx={{width: 252, marginLeft: 20}}
                                                        onChange={(newValue) =>
                                                            handleStartDateChange(newValue, DATE_TYPES.END_DATE)}/>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className={styles.formElementSmall}>
                                <TextField name="terms"
                                           required
                                           multiline={true}
                                           rows={4}
                                           label={t('common.terms-condition')}
                                           value={formData.terms}
                                           sx={{width: 520, mt: 1}}
                                           onChange={handleInputChange}></TextField>
                            </div>
                        </div>
                        <div>
                            {showLinkGenerator && formData.startDate && formData.endDate && ((!!isEdit && !!formData.links) || true) &&
                                <LinkGenerator showLinkGenerator={showLinkGenerator}
                                               startDate={formData.startDate}
                                               endDate={formData.endDate}
                                               setExistLinks={linksUpdate}
                                               isEdit={isEdit}
                                               existLinks={formData.links}/>}
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            sx={{
                                width: 'fit-content',
                            }}
                            variant='contained'
                            color={isEdit ? "info" : "confirmButton"}
                            startIcon={isEdit ? <UpdateOutlined/> : <CreateOutlined/>}
                            disabled={isButtonDisabled()}
                            onClick={isEdit ? updateProject : createNewPaidProject}
                        >
                            {isEdit ? t('menu-item.edit-paid-project') : t("menu-item.create-paid-project")}
                        </Button>
                        {isEdit && <Button variant="contained"
                                           color="error"
                                           startIcon={<DeleteOutline/>}
                                           sx={{mL: 1}}
                                           onClick={e => deletePaidProject()}>{t("common.delete")}
                        </Button>}
                    </div>
                </Card>
                <Toast
                    isOpen={isToastOpen}
                    onClose={toggleToast}
                    content={toastContent}
                    severity="info"
                />
            </div>
        </div>
    );
};

export default CreateEditPaidProject;

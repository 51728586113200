import {clientGetWrapper} from "../api/Api";
import {GET_AFFILIATE_PERFORMANCE, GET_ALL_AFFILIATES} from "../common/constants/routes";


export const getAllAffiliates = () => {
    return clientGetWrapper(GET_ALL_AFFILIATES);
}

export const getSelectedAffiliatePerformance = (affId,startDate,endDate) => {
    return clientGetWrapper(`${GET_AFFILIATE_PERFORMANCE}?affId=${affId}&st=${startDate}&ed=${endDate}`);
}


import React, {useEffect, useState} from 'react';
import {
    AccountBalanceOutlined,
    AccountCircleOutlined,
    CategoryOutlined,
    PaymentOutlined,
    PlaceOutlined
} from "@mui/icons-material";
import styles from "./UserBankDetails.module.scss";
import {Alert, Button, Checkbox, Chip, FormControlLabel, FormGroup, TextField} from "@mui/material";
import {t} from "i18next";
import {updateHasOfferUserAdditionalData} from "../../../services/usersService";
import Toast from "../../../common/toast/Toast";
import useToast from "../../../common/hooks/useToast";

const initialAddData = {
    user_id: 0,
    follower_count: "",
    is_document_arrived: false,
    user_note: ""
}

const UserBankDetails = ({bankDetail}) => {
    const [addData, setAddData] = useState(initialAddData);
    const {isToastOpen, toggleToast, toastContent, setToastContent} = useToast();
    useEffect(() => {
        const addPropsData = {
            user_id: bankDetail.hasoffersid,
            follower_count: bankDetail.follower_count,
            is_document_arrived: bankDetail.is_document_arrived,
            user_note: bankDetail.user_note
        };
        setAddData(addPropsData);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const handleSearchInputChange = (e) => {
        const {name, value} = e.target;
        setAddData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    };

    const updateUserAdditionalData = async (e) => {
        const response = await updateHasOfferUserAdditionalData(addData);
        if (!!response.data && !!response.data.isUpdated) {
            setToastContent(t("user-admin.user_details_updated_success_msg"));
            toggleToast();
        }
    };

    return (
        <div>
            <div className={styles.container}>
                <div>
                    <div className={styles.eachBlock}>
                        <div className={styles.titleBlock}>
                            <AccountCircleOutlined/>
                            <div>User Details</div>
                        </div>
                        <div className={styles.contentBodyForm}>
                            <div>
                                <TextField
                                    id="outlined-basic"
                                    name="follower_count"
                                    label={t("user-admin.follower_count")}
                                    value={addData.follower_count}
                                    variant="outlined"
                                    sx={{width: 330}}
                                    onChange={handleSearchInputChange}
                                />
                                <div>
                                    <TextField
                                        id="outlined-basic"
                                        name="user_note"
                                        label={t("user-admin.user_note")}
                                        value={addData.user_note}
                                        rows={5}
                                        multiline
                                        variant="outlined"
                                        sx={{width: 330}}
                                        onChange={handleSearchInputChange}
                                    />
                                </div>
                                <div>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={!!addData.is_document_arrived} onChange={(e) => {
                                                setAddData((prevState) => ({
                                                    ...prevState,
                                                    is_document_arrived: !prevState.is_document_arrived
                                                }))
                                            }
                                            }/>} label={t("user-admin.document-check-box-msg")}/>
                                    </FormGroup>
                                </div>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="success"
                                    onClick={updateUserAdditionalData}>{t("common.update")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.secondPart}>
                    <div className={styles.fields}>
                        {(bankDetail.bank_name || bankDetail.iban || bankDetail.national_id || bankDetail.company_title) &&
                            <div className={styles.eachBlock}>
                                <div className={styles.titleBlock}>
                                    <PaymentOutlined/>
                                    <div>{t("user-admin.bank-details")}</div>
                                </div>
                                <div className={styles.contentBody}>
                                    <div><b>{t("common-en.company-title")}:</b> {bankDetail.company_title}</div>
                                    <div><b>{t("user-admin.citizenship-no")}</b> {bankDetail.national_id}</div>
                                    <div><b>{t("common-tr.bank")}:</b> {bankDetail.bank_name}</div>
                                    <div><b>{t("common-tr.iban")}:</b> {bankDetail.iban}</div>
                                </div>
                            </div>}
                        {(bankDetail.address || bankDetail.city || bankDetail.country) &&
                            <div className={styles.eachBlock}>
                                <div className={styles.titleBlock}>
                                    <PlaceOutlined/>
                                    <div>{t("user-admin.address")}</div>
                                </div>
                                <div className={styles.contentBody}>
                                    <div>{bankDetail.address}</div>
                                    <div>{bankDetail.city}</div>
                                    <div>{bankDetail.country}</div>
                                </div>
                            </div>}
                        {bankDetail.is_tax_payer &&
                            <div className={styles.eachBlock}>
                                <div className={styles.titleBlock}>
                                    <AccountBalanceOutlined/>
                                    <div>{t("user-admin.tax-info")}</div>
                                </div>
                                <div className={styles.contentBody}>
                                    <div>
                                        <FormControlLabel control={
                                            <Checkbox disabled

                                                      checked={!!bankDetail.is_tax_payer}/>}
                                                          label={t("user-admin.is-tax-payer")}
                                        />
                                    </div>
                                    {!!bankDetail.tax_office &&
                                        <div><b>{t("user-admin.tax-office")}:</b> {bankDetail.tax_office}</div>}
                                    {!!bankDetail.tax_number &&
                                        <div><b>{t("user-admin.tax-office")}:</b> {bankDetail.tax_number}</div>}
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        {(!bankDetail.national_id && !bankDetail.categories && !bankDetail.company_title && !bankDetail.bank_name && !bankDetail.iban
                                && !bankDetail.address && !bankDetail.city && !bankDetail.country && (bankDetail.is_tax_payer === null)
                                && !bankDetail.tax_office && !bankDetail.tax_number) &&
                            <Alert variant="outlined" sx={{mt: 1}} severity="info"> {t('user-admin.no-bank-detail-msg')}</Alert>}
                    </div>
                    {!!bankDetail.categories &&
                        <div className={styles.tagsBlock}>
                            <div className={styles.titleBlock}>
                                <CategoryOutlined/>
                                <div>{t("common-en.categories")}</div>
                            </div>
                            <div className={styles.tagsHolder}>
                                {!!bankDetail.categories && JSON.parse(bankDetail.categories).map(category => (
                                    <div key={category}><Chip color="primary" sx={{fontWeight: 700}}
                                                              label={category}></Chip></div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default UserBankDetails;

import {configureStore} from '@reduxjs/toolkit';
import {authReducer} from './auth/authSlice';
import {offerReducer} from "./offerSlice/offerSlice";
import {advertiserReducer} from "./advertisersSlice/advertisersSlice";
import {categoryReducer} from "./categorySlice/categorySlice";
import {projectReducer} from "./paidProjectsSlice/paidProjectsSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        offersList: offerReducer,
        advertisersList: advertiserReducer,
        categoriesList: categoryReducer,
        projectsList: projectReducer
    },
});

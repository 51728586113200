import React from 'react';
import styles from './AiRadar.module.scss';
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import Toast from "../../common/toast/Toast";
import useToast from "../../common/hooks/useToast";
import PotentialInfluencers from "./PotentialInfluencers/PotentialInfluencers";



const AiRadar = () => {

    const {toastContent, isToastOpen, toggleToast} = useToast();
    /*const [messages, setMessages] = React.useState([]);*/

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            {/*<PotentialInfluencers/>*/}
        {/*    <Card title="Instagram AI bot">
                <div>
                    <div>
                        <h3>Instagram AI bot</h3>
                        <p>AI bot that can reach out to potential influencers and convert them to your brand
                            ambassadors</p>
                    </div>
                    <div>
                        <h3>How to use</h3>
                        <p>Upload an excel file that contains the influencer's name, social media account, follower
                            count, and engagement count</p>
                    </div>
                    <div>
                        <h3>How it works</h3>
                        <p>AI bot will analyze the data and send dm messages to the potential influencers and for the
                            answer it will use chatGPT</p>
                    </div>
                    <div>
                        <h3>Results</h3>
                        <p>AI bot will increase the number of brand ambassadors and increase the brand's reach</p>
                    </div>
                </div>
                <Button variant="contained" onClick={connectInstagramBot}>Login</Button>
                <Button variant="contained" color="error" onClick={syncWithInbox}>Sync with Inbox</Button>
                <Button variant="contained" color="error" onClick={sendPeriodicMessages} sx={{ml:1}}>Start Sending Msg</Button>

            </Card>*/}
            {/*{igUser && igUser.username && <Card title={`Connected Instagram Account: ${igUser.username}`}>
                <div className={styles.avatar}>
                    <Avatar sx={{width: 100, height: 100}} alt={igUser.username} src={igUser.profilePic}></Avatar>
                    <div className={styles.avatarDesc}>
                        <h2>{igUser.username}</h2>
                        <p>{igUser.fullName}</p>
                    </div>
                </div>
                <div>
                    <div className={styles.sendMsgTitle}>Send a new message</div>
                    <div className={styles.sendMsg}>
                        <TextField label="Receiver Username"
                                   name="recipientUsername"
                                   sx={{
                                       mt: 1
                                   }}
                                   onChange={handleTextChange}
                                   value={newIgMsg.recipientUsername}
                                   variant="outlined" fullWidth/>
                        <TextField label="Message"
                                   name="msg"
                                   onChange={handleTextChange}
                                   sx={{
                                       mt: 1
                                   }}
                                   value={newIgMsg.msg}
                                   variant="outlined" fullWidth/>
                        <Button variant="contained" color="confirmButton"
                                disabled={newIgMsg.msg <= 0 || newIgMsg.recipientUsername.length <= 0}
                                onClick={sendIgMessageToUser}>Send
                            Message</Button>
                    </div>
                </div>
            </Card>}*/}
            <PotentialInfluencers />
            <Toast
                isOpen={isToastOpen}
                onClose={toggleToast}
                content={toastContent}
                severity="info"
            />
        </div>
    );
};

export default AiRadar;

import {clientPostWrapper} from "../api/Api";
import {
    DELETE_USER_PAYMENT,
    GET_ALL_PAYMENTS,
    GET_IMAGE_URL,
    SEND_FIRST_EXCEL_NEW_RECORDS, SEND_SECOND_EXCEL_UPDATE_RECORDS,
    UPDATE_USER_PAYMENT
} from "../common/constants/routes";


export const getAllPayments = () => {
    return clientPostWrapper(GET_ALL_PAYMENTS);
}


export const updateUserPaymentData = (payload) => {
    return clientPostWrapper(UPDATE_USER_PAYMENT, payload);
}
export const deleteUserPaymentRequest = (payload) => {
    return clientPostWrapper(DELETE_USER_PAYMENT, payload);
}

export const getInvoiceSignalUrl = (key) => {
    return clientPostWrapper(GET_IMAGE_URL, {url: key});
}


export const sendExcelFileForInsert = (formData) => {
    return clientPostWrapper(SEND_FIRST_EXCEL_NEW_RECORDS, formData,true);
}

export const sendExcelFileForUpdate = (formData) => {
    return clientPostWrapper(SEND_SECOND_EXCEL_UPDATE_RECORDS, formData,true);
}



import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllAdvertisers} from "../../services/advertisers";

const initialState = {advertisers: []};

// First, create the thunk
export const fetchAdvertisers = createAsyncThunk(
    'get/advertiser',
    async () => {
        const response = await getAllAdvertisers();
        return response.data
    }
)

export const advertisersSlice = createSlice({
    name: "offers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAdvertisers.fulfilled, (state, action) => {
            let sortedAdvertisers = action.payload
                .sort((a, b) => a.advertiser_id > b.advertiser_id ? 1 : -1)
                .filter(adv => adv.status === "active");
            state.advertisers = sortedAdvertisers;
        })
    }
})

export const advertiserReducer = advertisersSlice.reducer
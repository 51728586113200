import React from 'react';
import styles from "./AboutInfluio.module.scss";
import part1 from "../../images/about-influio/1.webp";
import part2 from "../../images/about-influio/2.webp";
import part3 from "../../images/about-influio/3.webp";
import part4 from "../../images/about-influio/4.webp";
import part5 from "../../images/about-influio/5.webp";
import part6 from "../../images/about-influio/6.webp";
import part7 from "../../images/about-influio/7.webp";
import part8 from "../../images/about-influio/8.webp";
import part9 from "../../images/about-influio/9.webp";
import part10 from "../../images/about-influio/10.webp";
import {Instagram, MailOutlined} from "@mui/icons-material";

const AboutInfluio = () => {
    return (
        <div className={styles.container}>
            <img src={part1} alt={"part1"}/>
            <img src={part2} alt={"part2"}/>
            <img src={part3} alt={"part3"}/>
            <img src={part4} alt={"part4"}/>
            <img src={part5} alt={"part5"}/>
            <img src={part6} alt={"part6"}/>
            <img src={part7} alt={"part7"}/>
            <img src={part8} alt={"part8"}/>
            <img src={part9} alt={"part9"}/>
            <img src={part10} alt={"part10"}/>
            <div className={styles.icons}>

                <a href="https://www.instagram.com/influio/">
                    <Instagram sx={{width:30,height:30}}/>
                </a>
                <a href="mailto:support@influio.com">
                    <MailOutlined sx={{width:30,height:30}}/>
                </a>
            </div>
        </div>
    );
};

export default AboutInfluio;

import React, {useEffect} from 'react';
import Card from "../../common/card/Card";
import styles from './InsertInfluencers.module.scss';
import {Alert, Button, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {CheckOutlined, FileUploadOutlined, UploadFileOutlined} from "@mui/icons-material";
import {
    deletePotentialInfluencer,
    getAllPotentialInfluencers,
    sendPotentialInfluencersExcelFileForInsert,
    startCrawlChatIdsAsJob,
} from "../../services/radarAiService";
import useToast from "../../common/hooks/useToast";
import Toast from "../../common/toast/Toast";
import {t} from "i18next";
import BreadcrumbsBar from "../../layouts/breadcrumb/BreadcrumbsBar";
import GenericTable from "../../common/table/GenericTable";
import Modal from "../../common/modal/Modal";
import useModal from "../../common/hooks/useModal";
import dayjs from "dayjs";


const InsertInfluencers = () => {

    const [formData, setFormData] = React.useState(null);
    const {toastContent, isToastOpen, toggleToast, setToastContent} = useToast();
    const [potentialInfluencers, setPotentialInfluencers] = React.useState([]);
    const [influencerIdToDelete, setInfluencerIdToDelete] = React.useState(null);
    const {isModalOpen, toggleModal, modalContent, setModalContent} = useModal();
    const influencerColumns = {
        data: [
            {
                accessorKey: 'social',
                header: "Instagram Account"
            },
            {
                accessorKey: 'follower_count',
                header: "Followers"
            },
            {
                accessorKey: 'engagements',
                header: "Engagements"
            },
            {
                accessorKey: 'engagement_rate',
                header: "Engagement Rate",
                Cell: (cell) => (
                    <div>{cell.row.original.engagement_rate}%</div>
                )
            },
            {
                accessorKey: 'area_of_interest',
                header: "Area of Interest"
            },
            {
                accessorKey: 'created_at',
                header: "Uploaded At",
                Cell: (cell) => (
                    <div>
                        {dayjs(cell.row.original.created_at, "YYYY-MM-DD+h:mm").format("YYYY-MM-DD h:mm")}
                    </div>
                )
            },
            {
                accessorKey: 'messageId',
                header: "Conversation Started?",
                Cell: (cell) => (
                    <div>{cell.row.original.messageId ? <Alert severity="success">Conversion started</Alert> : <Alert severity="error">Conversation did not started</Alert>}</div>
                )
            },
            {
                accessorKey: 'actions',
                header: "Actions",
                Cell: (cell) => (
                    <div className={styles.actionButtons}>
                        <Button variant="contained" color="warning"
                                disabled={cell.row.original.direct_id === null}
                                onClick={(e) => {
                                    //https://www.instagram.com/direct/t/102136444518007


                                    //copy clipboard
                                    navigator.clipboard.writeText(`Selamlar,\nBiz Türkiye’nin ilk influencer affiliate marketing uygulamasıyız. Paylaşımlarınızı büyük bir beğeniyle takip ediyoruz! 🥰\n${cell.row.original.area_of_interest} alanında içerikler ürettiğinizi gördük ve bu konuda sizinle çalışmak isteriz. Sizinle, anlaşmalı markalarımız arasında dijital bir köprü kuruyoruz. Trendyol ve Hepsiburada’da olduğu gibi, linklerinizi sosyal medya hesaplarınızda paylaştığınızda, takipçilerinizin bu linkler üzerinden yaptığı her satıştan gelir elde ediyorsunuz.\nÖrnek markalarımızdan bazıları: Watsons, LCW, Karaca, Amazon, Flo, Boyner.\nAyrıca markalarımızla birlikte yürüttüğümüz bütçeli özel projelerimiz de mevcut.\nSizin gibi binlerce influencer üyemiz, her gün linkleri üzerinden yüksek kazançlar elde ediyor. Dilerseniz uygulamamızı indirip hemen denemeye başlayabilirsiniz :)\nBir sorunuz olursa, memnuniyetle yardımcı olmak isteriz. Aşağıdaki linkten uygulamamızı indirip, kayıt olabilirsiniz.\nhttps://influio.onelink.me/K1oT/t4w0i1ss`);
                                    window.open(`https://www.instagram.com/direct/t/${cell.row.original.direct_id}`, "_blank");
                                }}>Send Message</Button>
                        <Button variant="contained" color="confirmButton"
                                onClick={(e) => {
                                    //https://www.instagram.com/direct/t/102136444518007
                                    navigator.clipboard.writeText(`Selamlar,\nBiz Türkiye’nin ilk influencer affiliate marketing uygulamasıyız. Paylaşımlarınızı büyük bir beğeniyle takip ediyoruz! 🥰\n${cell.row.original.area_of_interest} alanında içerikler ürettiğinizi gördük ve bu konuda sizinle çalışmak isteriz. Sizinle, anlaşmalı markalarımız arasında dijital bir köprü kuruyoruz. Trendyol ve Hepsiburada’da olduğu gibi, linklerinizi sosyal medya hesaplarınızda paylaştığınızda, takipçilerinizin bu linkler üzerinden yaptığı her satıştan gelir elde ediyorsunuz.\nÖrnek markalarımızdan bazıları: Watsons, LCW, Karaca, Amazon, Flo, Boyner.\nAyrıca markalarımızla birlikte yürüttüğümüz bütçeli özel projelerimiz de mevcut.\nSizin gibi binlerce influencer üyemiz, her gün linkleri üzerinden yüksek kazançlar elde ediyor. Dilerseniz uygulamamızı indirip hemen denemeye başlayabilirsiniz :)\nBir sorunuz olursa, memnuniyetle yardımcı olmak isteriz. Aşağıdaki linkten uygulamamızı indirip, kayıt olabilirsiniz.\nhttps://influio.onelink.me/K1oT/t4w0i1ss`);
                                    window.open(`https://www.instagram.com/${cell.row.original.social}`, "_blank");
                                }}>View Profile</Button>

                        <Button variant="contained" color="error" onClick={async (e) => {
                            //open confirmation modal
                            setInfluencerIdToDelete(cell.row.original.id);
                            setModalContent({
                                title: "Delete Influencer",
                                content: "Are you sure you want to delete this influencer?",
                            });
                            toggleModal();
                        }}>Delete</Button>
                    </div>
                )
            },
        ]
    };

    const deleteInfluencer = async () => {
        const id = influencerIdToDelete;
        const response = await deletePotentialInfluencer({
            id
        });
        if (response && response.data) {
            setToastContent("Influencer deleted");
            await getPotentialInfluencersFromDb();
            toggleModal();
        } else {
            setToastContent("Data update failed");
        }
        toggleToast();
    }

/*
    const getActiveOfferNames = () => {
        const filtered = offersList.filter(offer => offer.isPrivate === false);
        console.log(filtered);
        const randomFiveFiltered = filtered.sort(() => 0.5 - Math.random()).slice(0, 5);
        return randomFiveFiltered.map(offer => offer.name).join(", ");
    }
*/

    const getPotentialInfluencersFromDb = async () => {
        const response = await getAllPotentialInfluencers();
        if (response && response.data) {
            setPotentialInfluencers(response.data);
        }
    }

    useEffect(() => {
        getPotentialInfluencersFromDb();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const sendUploadNewInfluencersRequest = async () => {
        if (!formData) {
            setToastContent("Please select a file first");
            toggleToast();
            return;
        }
        const response = await sendPotentialInfluencersExcelFileForInsert(formData);
        if (!!response.data && !!response.data.isAdded) {
            setToastContent(t("ai-radar.uploaded_influencers_toast_msg", {
                updatedCount: response.data.updatedCount,
                existCount: response.data.totalUpdateCount - response.data.updatedCount
            }));
            getPotentialInfluencersFromDb();
        } else {
            setToastContent("Data update failed");
        }
        setFormData(null);
        toggleToast();
        //getPotentialInfluencers();
    }

    const excelFileChanged = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        setFormData(formData);
    }

    const startCrawlChatIds = async () => {
        const response = await startCrawlChatIdsAsJob();
        if (!!response.data) {
            setToastContent("Crawling started");
        } else {
            setToastContent("Data update failed");
        }
        toggleToast();
    }

    return (
        <div className={styles.container}>
            <BreadcrumbsBar/>
            {<Card title={t("ai-radar.upload_new_potential_influencers-msg")}>
                <div className={styles.uploadExcelPart}>
                    <TextField
                        variant="outlined"
                        type="text"
                        disabled={true}
                        value={"Choose excel file(xlsx)"}
                        contentEditable={false}
                        InputProps={{
                            endAdornment: (
                                <IconButton component="label">
                                    <FileUploadOutlined/>
                                    <input
                                        styles={{display: "none"}}
                                        type="file"
                                        accept=".xlsx"
                                        hidden
                                        onChange={excelFileChanged}
                                        name="[licenseFile]"
                                    />
                                </IconButton>
                            ),
                        }}
                    />
                    {formData && formData.get("file") && <Alert icon={<CheckOutlined fontSize="inherit"/>}
                                                                severity="success">{formData.get("file").name}</Alert>}
                    <Button
                        variant="contained"
                        color="success"
                        onClick={sendUploadNewInfluencersRequest}
                        disabled={!formData}
                        startIcon={<UploadFileOutlined/>}>Upload</Button>
                </div>
            </Card>}
            <Toast isOpen={isToastOpen} onClose={toggleToast} content={toastContent} severity="info"/>
            {
                potentialInfluencers && potentialInfluencers.length > 0 &&
                <Card title={t("ai-radar.uploaded_influencers")}>
                    <Button
                        sx={{mb: 2}}
                        variant="contained"
                        color="info"
                        onClick={startCrawlChatIds}>Start Crawl Chat IDs</Button>
                    <GenericTable
                        outputColumns={influencerColumns}
                        tableData={potentialInfluencers}

                    />
                    <Modal
                        isOpen={isModalOpen}
                        onConfirmation={deleteInfluencer}
                        onCancelation={toggleModal}
                        title={modalContent.title}
                        content={modalContent.content}
                    />
                </Card>
            }
        </div>
    );
};

export default InsertInfluencers;
